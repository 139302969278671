import { Preferences } from './preferences';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class PreferencesSerializer {
  fromJson(json: any): Preferences {
    json = camelcaseKeysDeep(json);

    const preferences = new Preferences();

    if (!json) {
      return preferences;
    }

    preferences.id = json.id;
    preferences.organization = json.organization;
    preferences.profile = json.profile;
    preferences.name = json.name;
    preferences.type = json.type;
    preferences.blob = json.blob;

    return preferences;
  }

  toJson(preferences: Preferences): any {
    const json: { [key: string]: any } = {
      id: preferences.id,
      organization: preferences.organization,
      profile: preferences.profile,
      name: preferences.name,
      type: preferences.type,
      blob: preferences.blob
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
