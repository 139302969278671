import { Attachment } from './attachment';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class AttachmentSerializer {
  fromJson(json: any): Attachment {
    json = camelcaseKeysDeep(json);
    const attachment = new Attachment();
    if (!json) {
      return attachment;
    }

    attachment.id = json.id;
    attachment.name = json.name;
    attachment.fileFormat = json.fileFormat;
    attachment.fileExtension = json.fileExtension;
    attachment.fileType = json.fileType;
    attachment.file = json.file;
    attachment.tickets = json.tickets;

    return attachment;
  }

  toJson(attachment: Attachment): any {
    const json: { [key: string]: any } = {
      id: attachment.id,
      name: attachment.name,
      fileFormat: attachment.fileFormat,
      fileExtension: attachment.fileExtension,
      fileType: attachment.fileType,
      file: attachment.file,
      tickets: attachment.tickets
    };

    for (const property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
