<div class="sidebar">
  <div
    class="sidebar__sidenav sidebar-container "
    [ngClass]="{
      'sidebar__sidenav--opened': drawerVisible && visible,
      'sidebar__sidenav--closed': !drawerVisible && visible,
      'sidebar__sidenav--hidden': !visible
    }" (mouseenter)="toggleSidebar()" (mouseleave)="toggleSidebar()"
    *ngIf="visible"
   >
    <div class="sidebar__sidenav__options">
      <div
        *ngFor="let option of menuOptions; let i = index"
        [ngClass]="{ 'sidebar__sidenav__options__option--active': option.active }"
        class="sidebar__sidenav__options__option"
      >
        <div class="sidebar__sidenav__options__option__label" (click)="onOptionClick(i)">
          <mat-icon>{{ option.icon }}</mat-icon>
          <div *ngIf="visible && drawerVisible" class="sidebar__sidenav__options__option__label__display">
            {{ option.label }}
          </div>
          <div *ngIf="visible && drawerVisible && option.children && option.toggled" class="sidebar__sidenav__options__option__label__display--toggled"></div>
          <div *ngIf="visible && drawerVisible && option.children && !option.toggled" class="sidebar__sidenav__options__option__label__display--untoggled"></div>
        </div>
        <div *ngIf="option.children && option.toggled && drawerVisible" class="sidebar__sidenav__options__option__children">
          <div
            *ngFor="let child of option.children; let j = index"
            class="sidebar__sidenav__options__option__children__child"
            [ngClass]="{ 'sidebar__sidenav__options__option__children__child--selected': child.active }"
            (click)="onOptionClick(i, j)"
          >
            {{ child.label }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="drawerVisible" class="sidebar__sidenav__bottom">
      <div class="sidebar__sidenav__bottom__version">
        build {{ version }}
      </div>
    </div>
  </div>
  <div
    class="sidebar__content"
    [ngClass]="{
      'sidebar__content--opened': drawerVisible && visible,
      'sidebar__content--hidden': !visible && !drawerVisible,
      'sidebar__content--closed': visible && !drawerVisible
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
