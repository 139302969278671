import { Injectable } from '@angular/core';
import { ResourceService } from '../../shared/resource.service';
import { environment } from '../../../environments/environment';
import { TruckReferenceSerializer } from './truck-reference.serializer';
import { TruckReference } from './truck-reference';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TruckReferenceService extends ResourceService<TruckReference> {
  baseUrl = environment.serverUrl + 'reference/trucks/';
  allSelected = false;

  constructor(http: HttpClient) {
    super(http, 'reference/trucks/', new TruckReferenceSerializer());
  }
}

