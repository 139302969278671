import { Ticket } from './../../tickets/ticket';
import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { first } from 'rxjs/operators';

import { TicketService } from '../../tickets/ticket.service';
import { AttachmentService } from '../../attachments/attachment.service';
import { Attachment } from '../../attachments/attachment';
import { RowNode } from 'ag-grid-community';

// constants
import { TRIPTICKETIMAGETYPE } from '../../app.constants';

@Component({
  selector: 'image-indicator-cell',
  templateUrl: './image-indicator-cell.component.html',
  styleUrls: ['./image-indicator-cell.component.scss'],
  providers: [TicketService, AttachmentService],
})
export class ImageIndicatorCellComponent implements AgRendererComponent {
  value!: string;
  rowId!: string;
  ticket!: Ticket;
  readonly = false;
  private params!: any;

  constructor(
    private ticketService: TicketService,
    private attachmentService: AttachmentService
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.rowId = params.data && params.data.id;
    this.ticket = params.data as Ticket;
    this.readonly = params.readOnly;
    this.value = this.getValue();
  }

  refresh(): boolean {
    return false;
  }

  getValue(): string {
    const image = this.ticketService.getImage(this.ticket, { x: 75, y: 75 });

    if (!image) {
      return '';
    }

    return image;
  }

  isPopup?(): boolean {
    return false;
  }

  isCancelBeforeStart?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  fileChange(e: any) {
    const uploadFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadFile);

    reader.onload = () => {
      this.attachmentService
        .save({
          name: uploadFile.name,
          fileFormat: 'image',
          fileExtension: uploadFile.name.split('.').pop(),
          fileType: TRIPTICKETIMAGETYPE,
          file: reader.result,
          tickets: [this.rowId],
        })
        .pipe(first())
        .subscribe((attachment: Attachment) => {
          this.value = String(attachment.file);
          this.ticket.attachments = [...this.ticket.attachments, attachment];
          this.updateRow(this.rowId, attachment);
        });
    };
  }

  updateRow(id: string, attachment: Attachment) {
    const row = this.params.api.getRowNode(id) as RowNode;
    this.params.api.flashCells({
      rowNodes: [row],
      columns: ['ticketImage'],
    });
    row.setData({
      ...row.data,
      ...{ image: attachment.file },
      attachments: [attachment],
    });
  }
}
