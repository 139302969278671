<div class="ruckit-autocomplete-cell">
  <form tabindex="0" (keydown)="onKeyDown($event)">
    <mat-form-field class="ruckit-autocomplete-cell__input">
      <input
        type="text"
        [formControl]="autocompleteControl"
        [matAutocomplete]="autocomplete"
        matInput
        #autocompleteInput
      >
      <mat-autocomplete #autocomplete autoActiveFirstOption="false">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
