import { Resource } from '../../shared/resource';
import { Charge } from '../../charges/charge';

export type ReportProfile = { id: string; firstName: string; lastName: string };

export type ReportAddress = {
  street: string
  address2: string
  city: string
  state: string
  zipcode: string
};

export type ReportContactInfo = {
  name: string
  phoneNumber: string
  address: string
  email: string
};

export type ReportFields = {
  logo: string
  sender: ReportContactInfo
  customer: ReportContactInfo
  notes: string
  netTerms: string
  customInfo: string
};

export class Report extends Resource {
  createdAt!: string;
  createdBy!: ReportProfile;
  lastModified!: string;
  lastModifiedBy!: ReportProfile;
  ownerOrganization!: string;
  type!: string;
  charges!: Charge[];
  reportDate!: string;
  dueDate!: string;
  reportNumber!: string;
  logo!: string;
  subtotal!: string;
  total!: string;
  sent!: boolean;
  isLocked!: boolean;
  surchargeAmount!: string;
  taxRate!: string;
  sender!: ReportContactInfo;
  customer!: ReportContactInfo;
  notes!: string;
  pdfUrl!: string;
  netTerms!: string;
  customInfo!: string;
  poNumber!: string;
  ticketManagerCustomer!: string;
  lastQuickbooksSync!: string;
  blob: any;
}

