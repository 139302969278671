<div class="container">
  <grid-notification-bar></grid-notification-bar>
  <div class="grid-display">
    <div class="title">Tickets</div>
    <div class="ticket-grid-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <action-menu title="Actions" [options]="actionOptions"></action-menu>
      <button class="btn btn-default" (click)="addRow()" *ngIf="!hasReadOnlyTicketAccess">Add New Row</button>
      <button *ngIf="!hasReadOnlyTicketAccess" class="btn" [ngClass]="{
        'btn-primary': numberOfTicketsSelected() === 0,
        'btn-primary-alt': numberOfTicketsSelected() > 0
      }" (click)="openBatchUploadDialog()" translate>
        <span *ngIf="numberOfTicketsSelected() === 0">Create</span>
        <span *ngIf="numberOfTicketsSelected() > 0">Edit</span> Ticket<span
          *ngIf="numberOfTicketsSelected() > 1">s</span>
      </button>
      <button *ngIf="allowScaleSyncUploader" class="btn btn-default" (click)="openScaleSyncUploadDialog()" translate>
        Scale Sync Ticket
      </button>
    </div>
    <div class="ticket-grid-container">
      <ag-grid-angular #ticketGrid class="ag-theme-material ticket-grid" [animateRows]="true" [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef" [enableRangeSelection]="true" [suppressCopyRowsToClipboard]="true"
        [pagination]="true" [paginationPageSize]="25" [rowModelType]="rowModelType" [serverSideStoreType]="serverSideStoreType"
        [cacheBlockSize]="cacheBlockSize" [maxBlocksInCache]="maxBlocksInCache"
        [frameworkComponents]="frameworkComponents" [sideBar]="sideBar" [getRowHeight]="getRowHeight"
        [noRowsOverlayComponent]="noRowsOverlayComponent" [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
        (isExternalFilterPresent)="isExternalFilterPresent()" (doesExternalFilterPass)="doesExternalFilterPass()"
        (gridReady)="onGridReady($event)" (firstDataRendered)="onFirstDataRendered()"
        (filterChanged)="onFilterChanges()" rowSelection="multiple" suppressBrowserResizeObserver="true">
      </ag-grid-angular>
      <div *ngIf="numberOfTicketsSelected() > 0" class="selected-indicator">
        {{ numberOfTicketsSelected() }} Ticket<span *ngIf="numberOfTicketsSelected() > 1">s</span> Selected
      </div>
    </div>
  </div>
</div>