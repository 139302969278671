import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: any): any {
    const phoneNumber = parsePhoneNumberFromString(value, 'US');

    if (!phoneNumber || !phoneNumber.isValid() || !phoneNumber.isPossible()) {
      return value;
    }

    return phoneNumber.format('NATIONAL');
  }

}
