<div class="container">
  <div class="action-bar">
    <div class="right-actions">
      <a href="/" *ngIf="static">
        <img src="assets/img/footer-logo.png" alt="Ruckit Logo" class="toolbar-logo" />
      </a>
      <button *ngIf="!static" class="btn btn-default" (click)="save()" translate>Save</button>
    </div>
    <div class="left-actions">
      <button class="btn btn-primary-alt" (click)="print()" translate><i class="icon-invoices"></i> Print</button>
      <button class="btn btn-primary" (click)="send()" translate><i class="icon-upload"></i> Send</button>
    </div>
  </div>

  <div id="report-view" class="report-preview">
    <notification *ngFor="let error of errors">{{ error | translate }}</notification>
    <div class="report-container">
      <div class="report">
        <header>
          <div class="left-segment">
            <h1 *ngIf="reportType === 'expense'" translate>settlement #<input placeholder="0000" [(ngModel)]="report.reportNumber"/></h1>
            <h1 *ngIf="reportType === 'invoice'" translate>invoice #<input placeholder="0000" [(ngModel)]="report.reportNumber"/></h1>
            <div class="report-dates">
              <div class="date-field">
                <label translate>Date</label>
                <mat-form-field>
                  <input
                    name="due-date"
                    aria-label="{ 'Date' | translate }"
                    matInput
                    [matDatepicker]="duedatepicker"
                    [(ngModel)]="report.dueDate"
                  >
                  <mat-datepicker-toggle matSuffix [for]="duedatepicker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-datepicker #duedatepicker></mat-datepicker>
                <input *ngIf="reportType === 'invoice'" class="net-terms-field" placeholder="{{ 'Net Terms' | translate }}" [(ngModel)]="report.netTerms"/>
              </div>
            </div>
          </div>
          <div class="right-segment">
            <div class="logo">
              <img *ngIf="report.logo" [src]="report.logo"/>
            </div>
          </div>
        </header>

        <header class="subheader">
          <div class="left-segment">
            <div class="bill-from" *ngIf="report.sender">
              <label *ngIf="reportType === 'expense'" translate>Pay From</label>
              <label *ngIf="reportType === 'invoice'" translate>Bill From</label>
              <input placeholder="{{ 'Sender Name' | translate }}" [(ngModel)]="report.sender.name">
              <input class="phone" placeholder="{{ 'Sender Phone' | translate }}" [(ngModel)]="report.sender.phoneNumber"/>
              <textarea placeholder="{{ 'Sender Address' | translate }}" [(ngModel)]="report.sender.address"></textarea>
            </div>

            <div class="bill-to" *ngIf="report.customer">
              <label *ngIf="reportType === 'expense'" translate>Pay To</label>
              <label *ngIf="reportType === 'invoice'" translate>Bill To</label>
              <customer-reference-selector
                *ngIf="reportType === 'invoice'"
                placeholder="Customer"
                [value]="report.customer.name"
                (referenceSelected)="onCustomerReferenceSelected($event)"
                (onBlur)="onCustomerReferenceBlur()"
                [(visible)]="isCustomerFieldVisible"
              ></customer-reference-selector>
              <driver-reference-selector
                *ngIf="reportType === 'expense'"
                [value]="report.customer.name"
                (referenceSelected)="onDriverReferenceSelected($event)"
                (onBlur)="onDriverReferenceBlur()"
                [(visible)]="isDriverFieldVisible"
              ></driver-reference-selector>
              <input class="phone" placeholder="{{ 'Customer Phone' | translate }}" [(ngModel)]="report.customer.phoneNumber"/>
              <textarea placeholder="{{ 'Customer Address' | translate }}" [(ngModel)]="report.customer.address"></textarea>
            </div>
          </div>

          <div class="right-segment">
            <div class="total-amount">
              <label translate>Total Amount</label>
              <h1>{{ getReportTotal() }}</h1>
              <div class="sub-detail-row total-tons">
                <label translate>Total Tons</label>
                <div class="value">{{ getReportTotalTons() }}</div>
              </div>
            </div>
            <textarea class="custom-info-field" placeholder="{{ 'Custom Info' | translate }}" [(ngModel)]="report.customInfo"></textarea>
          </div>
        </header>

        <div class="content">
          <div class="table-actions">
            <div class="switch-container">
              <label class="switch">
                <input type="checkbox" id="billable-toggle" (click)="toggleBillable()" [(ngModel)]="unbillableVisible">
                <span class="slider"></span>
              </label>
              <div class="label" translate>Show Unbillable Line Items</div>
            </div>
            <action-menu *ngIf="!static" title="Actions" [options]="actionOptions"></action-menu>
          </div>
          <div class="section-divider" *ngIf="report.charges">
            <div class="divider-line"></div>
          </div>
          <div class="checkbox-container" *ngIf="!static">
            <div class="checkbox" *ngFor="let checkbox of checkboxes" [ngStyle]="{ 'height': checkbox.height + 'px' }">
              <mat-checkbox *ngIf="checkbox.enabled" [(ngModel)]="checkbox.selected" (click)="checkbox.onClick()"></mat-checkbox>
            </div>
          </div>
          <ag-grid-angular
            *ngIf="report && report.id"
            style="width: 100%"
            #chargeGrid
            class="ag-theme-material charges-grid"
            [rowData]="chargeList"
            [treeData]="true"
            [getDataPath]="getChargeDataPath"
            [columnDefs]="columnDefs"
            rowSelection="multiple"
            suppressAggFuncInHeader="true"
            groupSuppressAutoColumn="true"
            colResizeDefault="shift"
            (gridReady)="onGridReady($event)"
          ></ag-grid-angular>
        </div>

        <footer>
          <div class="remittance-info">
            <h3 translate>Remittance Info</h3>
            <textarea maxlength="250" placeholder="{{ 'Remittance Info' | translate }}" [(ngModel)]="report.notes"></textarea>
          </div>

          <div class="summary">
            <div class="detail-row subtotal">
              <label translate>Subtotal</label>
              <div class="value">{{ getReportSubtotal() }}</div>
            </div>
            <div class="detail-row taxes" *ngIf="reportType === 'invoice'">
              <label translate>Tax Rate</label>
              <div class="value"><span class="currency-symbol">%</span><input type="number" [(ngModel)]="report.taxRate"></div>
            </div>
            <div class="detail-row tax-total" *ngIf="reportType === 'invoice'">
              <label translate>Total Taxes</label>
              <div class="value">{{ getReportTaxTotal() }}</div>
            </div>
            <div class="detail-row surcharges">
              <label translate>Miscellaneous</label>
              <div class="value"><span class="currency-symbol">$</span><input type="number" [(ngModel)]="report.surchargeAmount"></div>
            </div>
            <div class="detail-row total">
              <label translate>Total Amount</label>
              <div class="value">{{ getReportTotal() }}</div>
            </div>
          </div>
        </footer>
        <div class="switch-container">
          <label class="switch">
            <input type="checkbox" id="print-images" checked [(ngModel)]="imagesVisible">
            <span class="slider"></span>
          </label>
          <div class="label" translate>Show and Print Images</div>
        </div>
        <div id="images" class="content tickets" *ngIf="imagesVisible">
          <table class="images">
            <tbody class="items">
              <tr *ngFor="let reportImageGroup of reportImages | filterUnique: 'index'">
                <div *ngIf="reportImageGroup && reportImageGroup.images">
                  <td class="index">{{ reportImageGroup.index }}</td>
                  <td>
                    <div *ngFor="let image of reportImageGroup.images" class="report-image">
                      <div class="header">
                        <a [href]="image.url" target="_blank" translate>Ticket #{{ image.number }}</a>
                        <div class="date">{{ image.date }}</div>
                      </div>
                      <a *ngIf="image.url; else no_image_text" [href]="image.url" target="_blank">
                        <img [src]="image.resized">
                      </a>
                    </div>
                  </td>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #no_image_text>
  <span class="no-image-text" translate>No Image</span>
</ng-template>