<div class="ruckit-job-reference-create">
  <form [formGroup]="referenceForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="modal-title ruckit-job-reference-create__title">
      <h1 *ngIf="mode === 'create'" translate>Create New Job Reference</h1>
      <h1 *ngIf="mode === 'edit'" translate>Edit Job Reference</h1>
      <div class="close-action" (click)="dialogRef.close()">
        <i class="icomoon icon-close"></i>
      </div>
    </div>
    <div mat-dialog-content class="ruckit-job-reference-create__content">
      <mat-form-field>
        <input matInput placeholder="Destination" formControlName="destination">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Haul Rate" formControlName="haulRate">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Invoice Rate" formControlName="invoiceRate">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Job Code" formControlName="jobCode">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Job Name" formControlName="jobName">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Material" formControlName="material">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Origin" formControlName="origin">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Order Number" formControlName="orderNumber">
      </mat-form-field>
    </div>
    <div class="ruckit-job-reference-create__submit">
      <button type="submit" class="btn btn-primary" [disabled]="!referenceForm.valid" (submit)="onSubmit()">
        Submit
      </button>
    </div>
  </form>
</div>
