import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from './shared/index';
import { DataService } from './shared/data.service';
import '../style/app.scss';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'my-app', // <my-app></my-app>
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  url = 'https://goruckit.com';
  currentUser!: string;
  sidebarOpen = false;
  data: any;
  subscription: any;
  loggedIn = false;
  displaySidebar = true;

  hiddenRoutes = ['/uploader'];

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public router: Router,
    translate: TranslateService
  ) {
    translate.addLangs(['en', 'es', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|es/) ? browserLang : 'en');

    this.matIconRegistry.addSvgIcon(
      'icn-ticket',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/list-alt-24-px.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'icn-link',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/link-24-px.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'icn-invoices',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/monetization-on-24-px.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'icn-truck',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/local-shipping-24-px.svg')
    );
  }

  ngOnInit() {
    this.subscription = this.dataService.data$.subscribe(data => {
      Promise.resolve(null).then(() => {
        this.data = data;
        if (this.data && this.data.authenticated === undefined) {
          this.loggedIn = this.authenticationService.isLoggedIn();
        } else {
          this.loggedIn = this.data.authenticated;
        }
      });
    });
  }

  onLogout(): void {
    this.loggedIn = false;
    this.displaySidebar = false;
    this.authenticationService.logout();
  }

  onHamburgerClicked() {
    this.sidebarOpen = !this.sidebarOpen;
  }
}
