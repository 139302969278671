<div class="ruckit-arrange-items">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Arrange Linked Tickets</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div class="list-container" mat-dialog-content>
    <p class="instructions" translate>Arrange tickets in the order you want to specify with the first ticket being the billable ticket</p>
    
    <div class="item-list" dragula="item-list" [(dragulaModel)]="list">
      <ng-container *ngFor="let item of list; first as isFirst">
        <div *ngIf="item" class="item">
          <p *ngIf="isFirst" class="billable-label" translate>Billable Ticket</p>
          <span class="handle"><mat-icon class="handle">reorder</mat-icon></span>
          <div class="item-image"><img [src]="item.image"/></div>
          <div class="item-info">
            <p translate>Ticket Date:<br/>{{ item.ticketDate }}</p>
          </div>
          <div class="item-info">
            <p translate>Ticket Number:<br/>{{ item.ticketNumber }}</p>
          </div>
          <mat-icon *ngIf="!isFirst" class="unlink" (click)="unlink(item)">remove_circle</mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-actions" mat-dialog-actions>
    <button translate type="submit" class="btn btn-primary" (click)="save()">
      Save
    </button>
  </div>
</div>
