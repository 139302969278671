import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest as observableCombineLatest, forkJoin, Subscription } from 'rxjs';
import {  map, mergeMap } from 'rxjs/operators';

// libraries
import * as shortid from 'shortid';

// models
import { Ticket } from '../tickets/ticket';
import { Preferences } from '../shared/preferences/preferences';

// services
import { NotificationsService, Notification, NotificationType } from '../shared/notification/notifications.service';
import { AuthenticationService } from '../shared/authentication.service';
import { UploadService } from '../shared/upload.service';
import { TicketService } from '../tickets/ticket.service';
import { PreferencesService } from '../shared/preferences/preferences.service';
import { AttachmentService } from '../attachments/attachment.service';

// constants
import { TRIPTICKETIMAGETYPE } from '../app.constants';


@Component({
  selector: 'scale-sync-upload',
  templateUrl: './scale-sync-upload.component.html',
  styleUrls: ['./scale-sync-upload.component.scss'],
  providers: [
    NotificationsService,
    UploadService,
    TicketService,
    PreferencesService,
  ],
})
export class ScaleSyncUploadComponent implements OnInit, OnDestroy {

  external = false;
  contextId = '';
  dateSelected = false;

  user = this.authenticationService.user();
  callback: any;
  loader = {
    active: false,
    type: 'uploading'
  };
  uploadIndex = 0;
  preferences!: Preferences;

  policyData: any = {};
  uploadFiles: any[] = [];
  uploadTotal = 0;
  savedTickets: Ticket[] = [];
  selectedRecordIndex: any = null;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private attachmentService: AttachmentService,
    public uploadService: UploadService,
    public ticketService: TicketService,
    private preferencesService: PreferencesService
  ) { }

  ticketNumberVisible(): boolean {
    return (this.savedTickets.length > 0 && this.selectedRecordIndex !== null) || this.savedTickets.length === 0;
  }

  ngOnInit() {
    this.registerOrgUpdatedSubscription();
    // here we will set up listeners for any url params and set up
    // the callback_url, features, type model, and other config functions necessary
    const combinedParams = observableCombineLatest(
      [this.route.url, this.route.params, this.route.queryParams]);
    combinedParams.pipe(
      map(results => ({url: results[0], params: results[1], qparams: results[2]}))
    ).subscribe(_result => {
    });

    this.selectedRecordIndex = this.savedTickets.length === 1 ? 0 : null;
  }

  registerOrgUpdatedSubscription() {
    this.subscriptions[0] = this.authenticationService.ticketReadOnlyAccessUpdated$.subscribe(() => {
      if (this.authenticationService.getFeature('hasReadonlyTicketAccess')) {
        this.router.navigate(['']);
      }
    });
  }

  getPreferences() {
    this.preferencesService.list().pipe(
      map(prefs => {
        const pref = prefs.find(p => p.name === 'uploader');
        return pref ? pref : new Preferences();
      }),
    ).subscribe(preferences => {
      this.preferences = preferences;
    });
  }

  savePreferences() {
    const preferencesObj = {
      ...this.preferences,
      type: 'scale-sync-uploader',
      name: 'scale-sync-uploader',
      blob: { }
    };
    this.preferencesService.save(preferencesObj).subscribe();
  }

  saveImage(updateData: any) {
    this.ticketService.save({ id: updateData.id, image: updateData.imageUrl })
      .subscribe(() => { }, (err: any) => {
        const notification: Notification = {
          context: { err },
          id: shortid.generate(),
          message: 'There was an error updating that ticket image.',
          originator: 'uploader',
          type: NotificationType.Danger,
        };
        this.notificationsService.addNotification(notification);
        throw err;
      });
  }

  async fileChange(e: any) {
    if (e) {
      this.loader = {
        active: true,
        type: 'uploading',
      };
      const files = await Promise.all(
        Array.from(e.target.files).map(
          async (file: any) => await this.readUploadedFileAsText(file)
        )
      );
      this.saveImages(files);
    }
  }

  readUploadedFileAsText = (inputFile: any) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(this.getFile(inputFile.name, temporaryFileReader.result));
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  saveImages(files: any) {
    this.ticketService
      .saveScaleSyncWithoutAttachments({})
      .pipe(
        mergeMap((ticket: any) =>
          forkJoin(
            files.map((f: any) =>
              this.attachmentService.save({ ...f, tickets: [ticket.id] })
            )
          )
        )
      )
      .subscribe(
        () => {
          this.loader.active = false;
          this.router.navigate(['tickets']);
        },
        (err) => {
          this.loader.active = false;
          const notification: Notification = {
            context: { err },
            id: shortid.generate(),
            message:
              'There was an error authentication with our upload server.',
            originator: 'uploader',
            type: NotificationType.Danger,
          };
          this.notificationsService.addNotification(notification);
          throw err;
        }
      );
  }

  getFile(name: string, file: any) {
    const fileExtension = name.split('.').pop();
    return {
      name: `${this.uploadService.generateUUID()}.${fileExtension}`,
      fileFormat: 'image',
      fileExtension,
      fileType: TRIPTICKETIMAGETYPE,
      file,
    };
  }

  close() {
    this.router.navigate(['tickets']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub && sub.unsubscribe());
  }
}
