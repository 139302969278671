import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'readonly-cell',
  templateUrl: './readonly-cell.component.html',
  styleUrls: ['./readonly-cell.component.scss'],
})
export class ReadOnlyCellComponent implements AgRendererComponent {
  params?: ICellRendererParams;
  value?: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = this.params.value;
  }

  getValue() {
    throw new Error('Method not implemented.');
  }

  refresh(): boolean {
    return false;
  }
}
