<div class="alert-panel" *ngIf="!updateNoticeHidden && updateAvailable">
  <div class="panel-content update-available">
    <div>{{ alert }}</div>
    <div class="actions">
      <a (click)="reload()">REFRESH</a>
      <a (click)="updateNoticeHidden = true">
        <i class="icon-close large"></i>
      </a>
    </div>
  </div>
</div>

<!-- /dashboard
/daily-board
/job-detail
/trucks
/drivers
/locations
/team
/connections
/trips
/punchcards -->
