import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { requestHeaders, externalRequestHeaders } from '../shared/api.service';
import { HttpClient } from '@angular/common/http';

export type S3PolicyFields = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AWSAccessKeyId: string
  key: string
  policy: string
  signature: string
};

export type S3PolicyData = {
  fields: S3PolicyFields
  url: string
};

@Injectable()
export class UploadService {
  baseUrl = environment.serverUrl;
  ptpRuckitKey = environment.ptpRuckitKey;
  public count: any;
  progress$: any;
  progress: any;

  constructor(private http: HttpClient) { }

  getS3Policy(externalUpload = false): Observable<S3PolicyData> {
    const url = this.baseUrl + 'tickets/s3/';

    return externalUpload ?
    this.http.get(url, { headers: externalRequestHeaders(this.ptpRuckitKey) }).pipe(
      map((res: any) => res),
      catchError(this.handleError)
    ) :
    this.http.get(url, { headers: requestHeaders() }).pipe(
      map((res: any) => res),
      catchError(this.handleError)
    );
  }

  uploadToS3(policy: S3PolicyData, file: File) {
    const body = new FormData();
    body.append('Content-Type', 'image/jpeg');
    body.append('key', policy.fields.key);
    body.append('AWSAccessKeyId', policy.fields.AWSAccessKeyId);
    body.append('acl', 'public-read');
    body.append('Policy', policy.fields.policy);
    body.append('Signature', policy.fields.signature);
    body.append('file', file);

    return this.http.post(policy.url, body);
  }

  generateUUID(): string {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  convertDataUriToFile(data: any, fileName: string): File {
    const arr = data.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  private handleError(error: any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body: any = error || '';
      const err = body['error'] || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
