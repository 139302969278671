<div class="styleguide">
  <h1 (click)="openDialog()">Ruckit Styleguide</h1>

  <mat-card>
    <h1>Ruckit 2018 Material Theming</h1>
    <button mat-raised-button color="primary">
      Primary
    </button>
    <button mat-raised-button color="accent">
      Accent
    </button>
    <button mat-raised-button color="warn">
      Warning
    </button>
  </mat-card>
  <br />

  <h2>text inputs</h2>
  <label>Label</label>
  <input placeholder="placholder" id="example1">
  <div class="indicator" for="example1"></div>

  <h3>search</h3>
  <search [(searchTerm)]="search"></search>


  <h2>buttons</h2>
  <button class="btn btn-primary">primary</button><br /><br />
  <button class="btn btn-primary-alt">primary alt</button><br /><br />
  <button class="btn btn-warning">warning</button><br /><br />
  <button class="btn btn-cancel">cancel</button><br /><br />
  <button class="btn btn-delete">delete</button><br /><br />

  <button class="btn btn-inverted-delete">inverted delete</button><br /><br />

  <button class="btn btn-light">light</button><br /><br />

  <button class="btn btn-primary-inverted">primary inverted</button>
  <button class="btn btn-default">default</button><br /><br />

  <div>
    <button class="btn btn-primary loading" disabled>primary</button>
    <button class="btn btn-primary-inverted loading" disabled>primary inverted</button>
    <button class="btn btn-primary-alt loading" disabled>primary alt</button>
    <button class="btn btn-warning loading" disabled>warning</button>
    <button class="btn btn-cancel loading" disabled>cancel</button>
    <button class="btn btn-delete loading" disabled>delete</button>
    <button class="btn btn-inverted-delete loading" disabled>inverted delete</button>
    <button class="btn btn-default loading" disabled>default</button>
  </div>

  <h2>Dropdown &amp; Selectors</h2>

  <dropdown title="dropdown" [options]="dropdownOptions" (onSelect)="setSelectedOption()"></dropdown>

  <dropdown title="disabled dropdown" disabled="true"></dropdown>

  <dropdown title="searchable" [options]="searchableDropdownOptions" [config]="searchableDropdownConfig" (onSearch)="dropdownSearch($event)"
    (nextPage)="dropdownNextPage()"></dropdown>
  <br />

  <dropdown title="Actionbar dropdown" [config]="{style:'action'}" [options]="actionDropdownOptions" (onSelect)="setSelectedOption()"></dropdown>

  <dropdown title="..." [config]="{style:'action'}" [options]="actionDropdownOptions" (onSelect)="setSelectedOption()"></dropdown>

  <dropdown title="dropdown with groups" [config]="groupedDropdownConfig" [options]="groupedOptions" (onSelect)="setSelectedOption()"></dropdown>

  <div class="user-dropdown">
    <dropdown title="User dropdown" [config]="{user: user}" [options]="actionDropdownOptions" (onSelect)="setSelectedOption()"
      loadingOptions="loadingOptions"></dropdown>
  </div>


  <label>
    <input type="checkbox" attr.data-label="{{ 'checkbox' | translate }}" />
  </label>
  <label>
    <input type="checkbox" attr.data-label="{{ 'checkbox disabled' | translate }}" disabled />
  </label>
  <label>
    <input type="checkbox" attr.data-label="{{ 'checkbox disabled checked' | translate }}" disabled checked />
  </label>

  <label>
    <input type="radio" attr.data-label="{{ 'radio button' | translate }}" name="example" />
  </label>
  <label>
    <input type="radio" attr.data-label="{{ 'other button' | translate }}" name="example" />
  </label>
  <label>
    <input type="radio" attr.data-label="{{ 'button disabled' | translate }}" name="example" disabled />
  </label>
  <label>
    <input type="radio" attr.data-label="{{ 'selected button disabled' | translate }}" name="example2" disabled checked />
  </label>

  <h3>toggle</h3>
  <label attr.data-label="{{ 'checkbox' | translate }}" class='toggle-label'>
    <input type="checkbox" class="toggle-input" checked>
    <span class="slider round"></span>
  </label>

  <label attr.data-label="{{ 'checkbox' | translate }}" class='toggle-label' disabled>
    <input type="checkbox" class="toggle-input" disabled>
    <span class="slider round"></span>
  </label>
  <label attr.data-label="{{ 'checkbox' | translate }}" class='toggle-label' disabled checked>
    <input type="checkbox" class="toggle-input" disabled checked>
    <span class="slider round"></span>
  </label>

  <h3>Segmented Controls</h3>
  <ul class="segmented-control">
    <li class="item">
      <input class="input" type="radio" value="1" name="option" id="option-1" checked>
      <label class="label" for="option-1">Thing 1</label>
    </li>
    <li class="item">
      <input class="input" type="radio" value="2" name="option" id="option-2">
      <label class="label" for="option-2">Thing 2</label>
    </li>
  </ul>

  <h3>labels</h3>

  <div class="label-success">label-success</div><br /><br />

  <div class="label-info">label-info</div>
  <div class="label-working">label-working</div>
  <div class="label-accepted">label-accepted</div>
  <div class="label-paid">label-paid</div><br /><br />

  <div class="label-danger">label-danger</div>
  <div class="label-rejected">label-rejected</div>
  <div class="label-declined">label-declined</div><br /><br />

  <div class="label-secondary">label-secondary</div><br /><br />

  <div class="label-disabled">label-disabled</div><br /><br />

  <div class="label-warn">label-warn</div>
  <div class="label-waiting">label-waiting</div>
  <div class="label-pending">label-pending</div><br /><br />

  <div class="label-expired">label-expired</div>
  <div class="label-cancelled">label-cancelled</div>
  <div class="label-upcoming">label-upcoming</div><br /><br />

  <div class="yet-another-label-color">yet-another-label-color</div><br /><br />

  <div class="label-edited">label-edited</div><br /><br />

  <div class="label-void">label-void</div><br /><br />

  <div class="label-retake">label-retake</div><br /><br />

  <div class="label-finalized">label-finalized</div><br /><br />

  <h3>notifications</h3>
  <notification>This is the content of a default "danger" notification</notification>
  <notification level="warn">This is the content of a level="warn" notification</notification>
  <notification level="success">This is the content of a level="success" notification</notification>
  <notification level="info">This is the content of a level="info" notification</notification>

  <h3>tabs</h3>
  <mat-tab-group>
    <mat-tab label="Tab 1">
      contents 1
    </mat-tab>
    <mat-tab label="Tab 2">
      contents 2
    </mat-tab>
    <mat-tab label="Tab 3">
      contents 3
    </mat-tab>
  </mat-tab-group>


  <h3>steps</h3>


  <h2>Typography</h2>
  <h1>heading 1</h1>
  <h2>heading 2</h2>
  <h2>heading 3</h2>

  <p>paragraph</p>
  <p class="smaller">.smaller</p>
  <p class="additional">.additional</p>

  <p class="description">.description</p>

  <div class="translate-keys">
    <!-- This is hidden from view but includes menu text that needs to be translated. -->
    <span translate>MENU</span>
    <span translate>Projects</span>
    <span translate>Jobs</span>
    <span translate>Trips</span>
    <span translate>Dispatch</span>
    <span translate>Fleet</span>
    <span translate>Trucks</span>
    <span translate>Drivers</span>
    <span translate>Billing</span>
    <span translate>Invoices</span>
    <span translate>Expenses</span>
    <span translate>Paystubs</span>
    <span translate>Connections</span>
    <span translate>ORGANIZATION</span>
    <span translate>Team</span>
    <span translate>Settings</span>
  </div>
</div>
