<div class="sync-modal">
  <div mat-dialog-title class="modal-title sync-modal__title">
    <h1 translate>Sync Tickets to TicketPro</h1>
  </div>
  <div mat-dialog-content class="sync-modal__content">
    <div class="sync-modal__content__sync-options">
      <label id="sync-options-label">What do you want to sync?</label>
      <mat-radio-group
        aria-labelledby="sync-options-label"
        class="sync-modal__content__sync-options--group"
        [(ngModel)]="syncOption"
      >
        <mat-radio-button [value]="syncOptions.All"
          >Sync All Tickets</mat-radio-button
        >
        <mat-radio-button [value]="syncOptions.Selected"
          >Sync Selected Tickets</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>
  <div class="sync-modal__submit">
    <button type="submit" (click)="onSubmit()" class="btn btn-primary">
      Sync to TicketPro
    </button>
  </div>
</div>
