import { Component, ViewChild } from '@angular/core';
import { JobReferenceService } from '../job-reference.service';
import { AutocompleteCellComponent } from '../../../shared/grid/autocomplete-cell.component';
import { DatepickerCellComponent } from '../../../shared/grid/datepicker-cell.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ValueSetterParams, GridReadyEvent, ColumnApi, GridApi, CellContextMenuEvent, Column, ColDef } from 'ag-grid-community';
import { JobReference } from '../job-reference';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BulkActionDialogComponent } from '../../../shared/grid/bulk-action-dialog.component';
import { zip, Observable, Subject } from 'rxjs';
import { SelectAllHeaderComponent } from '../../../shared/grid/select-all-header.component';
import { TicketService } from '../../../tickets/ticket.service';
import { JobReferenceCreateModalComponent } from '../job-reference-create-modal/job-reference-create-modal.component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Preferences } from '../../../shared/preferences/preferences';
import shortid = require('shortid');
import { NotificationType, Notification, NotificationsService } from '../../../shared/notification/notifications.service';
import { PreferencesService } from '../../../shared/preferences/preferences.service';
import { debounce } from 'lodash';
import { AuthenticationService, NoResultsComponent } from '../../../shared';
import { ActionMenuOption } from '../../../../app/shared';

/* eslint-disable @typescript-eslint/naming-convention */
enum ActionType {
  ResetFilters = 'Reset Filters'
}

@Component({
  selector: 'job-reference-grid',
  templateUrl: './job-reference-grid.component.html',
  styleUrls: ['../../../../style/grid.scss', './job-reference-grid.component.scss'],
  providers: [
    JobReferenceService,
    TicketService,
    PreferencesService,
    NotificationsService,
  ],
})
export class JobReferenceGridComponent {
  @ViewChild('jobsGrid', {static: true}) jobsGrid!: AgGridAngular;
  columnApi!: ColumnApi;
  gridApi!: GridApi;

  enabledFeatures: string[] = this.authenticationService.enabledFeatures() || [];
  hasReadOnlyTicketAccess = this.enabledFeatures.includes('hasReadonlyTicketAccess');

  frameworkComponents = {
    autocompleteCell: AutocompleteCellComponent,
    datepickerCell: DatepickerCellComponent,
    selectAllHeader: SelectAllHeaderComponent,
    customNoRowsOverlay: NoResultsComponent,
  };

  noRowsOverlayComponent = 'customNoRowsOverlay';

  noRowsOverlayComponentParams = {
    type: 'job references',
    onClick: () => this.onAddJobClick(),
    readOnly: this.hasReadOnlyTicketAccess
  };

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel'
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ]
  };

  defaultColDef: ColDef = {
    sortable: true,
    resizable: true
  };

  columnDefs = [
    {
      headerName: 'Select All',
      field: 'select',
      headerComponent: 'selectAllHeader',
      pinned: 'left',
      width: 70,
      editable: false,
      checkboxSelection: true,
      headerComponentParams: {
        checkboxSelection: true,
        service: this.jobReferenceService,
        selected: this.jobReferenceService.allSelected
      }
    },
    {
      headerName: 'Job Code',
      field: 'jobCode',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.jobReferenceService.getValuesForFieldQuery('jobCode').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.jobReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Job Name',
      field: 'jobName',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.jobReferenceService.getValuesForFieldQuery('jobName').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },

      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.jobReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Material',
      field: 'material',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.jobReferenceService.getValuesForFieldQuery('material').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.jobReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Origin',
      field: 'origin',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.jobReferenceService.getValuesForFieldQuery('origin').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.jobReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Destination',
      field: 'destination',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.jobReferenceService.getValuesForFieldQuery('destination').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.jobReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Invoice Rate',
      field: 'invoiceRate',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.jobReferenceService.getValuesForFieldQuery('invoiceRate').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.jobReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Haul Rate',
      field: 'haulRate',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.jobReferenceService.getValuesForFieldQuery('haulRate').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.jobReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Order Number',
      field: 'orderNumber',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.jobReferenceService.getValuesForFieldQuery('orderNumber').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.jobReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
  ];

  rowModelType = 'serverSide';
  serverSideStoreType = 'partial';
  cacheBlockSize = 100;
  maxBlocksInCache = 10;

  loading = false;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();

  preferences!: Preferences;
  user = this.authenticationService.user();

  jobFiltersString = localStorage.getItem('jobFilters') as string;
  jobFilters = this.jobFiltersString && this.jobFiltersString.length && this.jobFiltersString[0] === '{' ?
                  JSON.parse(this.jobFiltersString) : {};
  
  actionOptions: ActionMenuOption[] = [];

  constructor(
    private jobReferenceService: JobReferenceService,
    private bulkDeleteDialog: MatDialog,
    private preferencesService: PreferencesService,
    private notificationsService: NotificationsService,
    private jobReferenceCreateModal: MatDialog,
    private authenticationService: AuthenticationService,
    public snackBar: MatSnackBar,
  ) {
    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.search = search;
      this.jobReferenceService.search = this.search;
      this.refreshTable();
    });
  }

  getContextMenuItems(params: any) {
    const menuItems: any[] = [];
    if (!this.hasReadOnlyTicketAccess) {
      menuItems.push({
        name: 'Delete',
        action: () => this.onDeleteJobReference(params)
      });
    }
    menuItems.push({
      name: 'Export',
      subMenu: [
        {
          name: 'Export Selected',
          action: () => this.onExportSelected(params),
        },
        {
          name: 'Export All',
          action: () => this.onExportAll(),
        },
      ]
    });
    return menuItems;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 5000 });
  }

  onDeleteJobReference(params: CellContextMenuEvent) {
    if (!params.api) {
      return;
    }

    let selected = params.api.getSelectedRows();
    if (!selected || !selected.length) {
      selected = [params.node.data];
      params.node.setSelected(true);
    }
    const dialog = this.bulkDeleteDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected,
        type: 'Job Reference',
      },
    });

    dialog.componentInstance.callback = () => {
      const jobs = zip(
        ...selected.map((j: JobReference) => this.deleteJobReference(j))
      );

      jobs.subscribe(() => {
        this.refreshTable(true);
      });
    };
  }

  deleteJobReference(job: JobReference): Observable<any> {
    return this.jobReferenceService.remove(job);
  }

  refreshTable(clear = false): void {
    if (clear) {
      this.gridApi.deselectAll();
      this.gridApi.clearRangeSelection();
    }
    this.gridApi.refreshCells();
    this.gridApi.purgeServerSideCache();
  }

  onCellValueChanged(e: ValueSetterParams): void {
    const job: JobReference = { ...e.data };

    delete job.classes;
    delete job.createdAt;
    delete job.loading;
    delete job.selected;

    this.jobReferenceService.save(job).subscribe();
  }

  onGridReady(e: GridReadyEvent): void {
    if (!e.columnApi || !e.api) {
      return;
    }

    this.columnApi = e.columnApi;
    this.gridApi = e.api;

    this.applyPreferences();

    this.jobsGrid.gridOptions.getContextMenuItems = (params) => this.getContextMenuItems(params);

    e.api.setServerSideDatasource(this.jobReferenceService);

    this.setActionsMenu();
  }

  setActionsMenu() {
    this.actionOptions = this.actionOptions.concat([
      {
        name: ActionType.ResetFilters,
        onClick: () => this.gridApi.setFilterModel(null),
        disabled: () => false
      }
    ]);
  }

  onFilterChanges() {
    this.jobFilters = this.gridApi.getFilterModel();
    const filterKeys = Object.keys(this.jobFilters);
    filterKeys.forEach(key => {
      const obj = this.jobFilters[key];
      if(!obj.values || !obj.values.length) {
        delete this.jobFilters[key];
      }
    });
    localStorage.setItem('jobFilters', JSON.stringify(this.jobFilters));
  }

  onFirstDataRendered(): void {
    this.autoSizeAll();
  }

  autoSizeAll(): void {
    const allColumnIds: any[] = [];

    (this.columnApi.getAllColumns() as Column[]).forEach(column => {
      allColumnIds.push(column.getColId());
    });

    this.columnApi.autoSizeColumns(allColumnIds);
  }

  onAddJobClick(): void {
    const dialog = this.jobReferenceCreateModal.open(JobReferenceCreateModalComponent, {
      width: '500px',
    });

    dialog.componentInstance.callback = (response$: Observable<JobReference>) => {
      response$.subscribe(() => this.refreshTable());
    };
  }

  changeSearch(term?: string): void {
    this.searchChanged.next(term);
  }

  onExportSelected(params: any) {
    let selected: string[];

    selected = params.api ?
               params.api.getSelectedRows().map((s: any) => s.id) :
               params.map((s: any) => s.id);
    if ((!selected || !selected.length) && params.node && params.node.data) {
      selected = [params.node.data.id];
      params.node.setSelected(true);
    }

    this.jobReferenceService.exportGrid('jobs', selected).subscribe(() => {}, error => {
      const notification: Notification = {
        context: {
          error,
        },
        id: shortid.generate(),
        message: 'An error occured exporting your data.',
        originator: 'tickets',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
    });
  }

  onExportAll() {
    this.jobReferenceService.exportGrid('jobs').subscribe(() => {}, error => {
      const notification: Notification = {
        context: {
          error,
        },
        id: shortid.generate(),
        message: 'An error occured exporting your data.',
        originator: 'tickets',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
    });
  }

  applyPreferences() {
    // Only update or create a default preference until UI for selection is in place
    this.preferencesService.list().pipe(
      map(prefs => {
        const pref = prefs.find(p => p.name === 'jobreference-default');

        return pref ? pref : new Preferences();
      }),
    ).subscribe(preferences => {
      this.preferences = preferences;

      if (preferences.type === 'jobreference' && preferences.blob) {
        this.columnApi.setColumnState(preferences.blob.columnState);
        this.gridApi.setSortModel(preferences.blob.sortState);
      }

      const eventList = [
        'gridColumnsChanged',
        'columnPinned',
        'columnVisible',
        'columnResized',
        'columnMoved'
      ];

      eventList.forEach(event => {
        this.gridApi.addEventListener(event, debounce(() => this.savePreferences(), 300));
      });
    }, error => {
      const notification: Notification = {
        context: {
          error,
        },
        id: shortid.generate(),
        message: 'An error occured saving your preferences.',
        originator: 'jobreferences',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
    });
  }

  savePreferences() {
    const columnData = this.columnApi.getColumnState();
    const preferencesObj = {
      ...this.preferences,
      profile: this.user.id,
      type: 'jobreference',
      name: 'jobreference-default',
      blob: {
        columnState: columnData,
      }
    };

    this.preferencesService.save(preferencesObj).subscribe(() => {}, error => {
      const notification: Notification = {
        context: {
          error,
        },
        id: shortid.generate(),
        message: 'An error occured saving your preferences.',
        originator: 'jobreferences',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
    });
  }

  getRowHeight() {
    return 48;
  }
}
