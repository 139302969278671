import { Resource } from '../../shared/resource';

export class TruckReference extends Resource {
  value?: string;
  organization?: string;
  createdAt?: string;
  licensePlate?: string;
  truckCode?: string;
  truckNumber?: string;
  truckType?: string;
}
