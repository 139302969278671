import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { ApiService } from '../../shared/api.service';
import { OrganizationService } from '../../organizations/organization.service';
import { parseErrors } from '../../shared/api.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'ruckit-settings-organization',
  templateUrl: './settings-organization.component.html',
  styleUrls: ['./settings-organization.component.scss'],
  providers: [OrganizationService, ApiService]
})
export class SettingsOrganizationComponent implements OnInit {
  @ViewChild('editOrganization', { static: false }) editOrganization: any;
  @Input() organization: any;
  originalOrg: any;
  loading = true;
  imageChanged = false;
  stateOptions: any[] = [];
  errors: any[] = [];
  logoImage: { [key: string]: any } = {
    dataUri: null,
    file: null
  };

  constructor(
    private router: Router,
    private apiService: ApiService,
    private organizationService: OrganizationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.stateOptions = this.apiService.getStates().map((state) => ({ name: state.abbreviation }));
    this.loading = false;
    this.originalOrg = cloneDeep(this.organization);
  }

  saveOrganization() {
    this.loading = true;
    const _organization = cloneDeep(this.organization);

    if (this.logoImage && this.logoImage.file) {
      Object.keys(_organization).forEach((key) => (_organization[key] == null) && delete _organization[key]);
      this.organizationService.saveWithImage(_organization, this.logoImage.file).subscribe((organization: any) => {
        this.organization = organization;
        this.router.navigate(['/settings', 'organization']);
        this.editOrganization.form.markAsPristine();
        this.editOrganization.form.markAsUntouched();
        this.loading = false;
      }, (err: any) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    } else { // patch
      const valuesToPatch: any = {};
      Object.keys(_organization).forEach(key => {
         if(_organization[key] !== this.originalOrg[key] && typeof _organization[key] !== 'object'){
           valuesToPatch[key] = _organization[key];
         }
      });
      this.organizationService.patchData(_organization.id, valuesToPatch).subscribe(() => {
        this.router.navigate(['/settings', 'organization']);
        this.editOrganization.form.markAsPristine();
        this.editOrganization.form.markAsUntouched();
        this.loading = false;
      }, (err: any) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  selectState(state: any, area = 'organization') {
    if (area === 'organization') {
      this.organization.state = state;
    } else {
      this.organization.billingState = state;
    }
  }

  fileChange(e: any) {
    const logoImage = this.logoImage;

    const reader = new FileReader();
    reader.onload = (loadEvent: any) => {
      logoImage.dataUri = loadEvent.target.result;
    };

    const file = e.target.files[0];
    logoImage.file = file;
    reader.readAsDataURL(file);
    this.imageChanged = true;
  }
}
