<form #processRecord="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 [ngPlural]="selected" translate>
      <ng-template ngPluralCase="=1">{{ action | titlecase }} {{ type | titlecase }}?</ng-template>
      <ng-template ngPluralCase="other">{{ action | titlecase }} {{ type | titlecase }}s?</ng-template>
    </h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <h2 [ngPlural]="selected">
        <ng-template ngPluralCase="=1" translate>{{ type | titlecase }} Selected</ng-template>
        <ng-template ngPluralCase="other" translate>{{ selected }} {{ type | titlecase }}s Selected</ng-template>
      </h2>
      <h3 [ngPlural]="selected">
        <ng-template ngPluralCase="=1" translate>Are you sure you want to {{ action }} this {{ type }}? This action cannot be undone.</ng-template>
        <ng-template ngPluralCase="other" translate>Are you sure you want to {{ action }} these {{ type }}s? This action cannot be undone.</ng-template>
        <ng-container *ngIf="action === 'process'">
          The following fields may be overwritten:
          <ul>
            <li translate>Ticket Date</li>
            <li translate>Ticket Number</li>
            <li translate>Quantity</li>
          </ul>
        </ng-container>
        
      </h3>
    </div>
    <notification *ngFor="let error of errors">{{ error }}</notification>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary-alt" (click)="processRecords(selected)" translate>
        {{ action | titlecase }} {{ type | titlecase }}
    </button>
  </div>
</form>
