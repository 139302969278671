import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest as observableCombineLatest, Observable, combineLatest } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { debounce, isEmpty } from 'lodash';
import * as moment from 'moment';
import { Decimal } from 'decimal.js';

import { AgGridAngular } from 'ag-grid-angular';
import { ValueSetterParams, GridReadyEvent, ColumnApi, GridApi, RowNode, Column } from 'ag-grid-community';
import { CellContextMenuEvent } from 'ag-grid-community/dist/lib/events';
import { MultiValueCellComponent } from '../../shared/grid/multi-value-cell.component';

import { SendReportDialogComponent } from '../send-report-dialog/send-report-dialog.component';
import { OrderData, ArrangeModalComponent } from '../../shared/arrange-modal/arrange-modal.component';

import { AuthenticationService } from '../../shared/authentication.service';
import { PreferencesService } from '../../shared/preferences/preferences.service';
import { Preferences } from '../../shared/preferences/preferences';

import { Ticket } from '../../tickets/ticket';
import { TicketService } from '../../tickets/ticket.service';
import { ReportAddress, ReportFields, Report } from '../report/report';
import { ChargeService } from '../../charges/charge.service';
import { Charge } from '../../charges/charge';
import { parseErrors, ActionMenuOption } from '../../shared';
import { CustomerReference } from './../../references/customer-reference/customer-reference';
import { DriverReference } from '../../references/driver-reference/driver-reference';
import { ReportService } from '../report/report.service';

type ReportImageGroup = {
  index: number
  images: ReportImage[]
};

type ReportImage = {
  number: string
  date: string
  resized: string | null
  url: string
};

type ReportCheckbox = {
  enabled: boolean
  height: number
  selected: boolean
  onClick: () => void
};

/* eslint-disable @typescript-eslint/naming-convention */
enum ActionType {
  AutosizeColumns = 'Autosize Columns',
  ArrangeTickets = 'Arrange Tickets',
  LinkTickets = 'Link Tickets',
  UnlinkTickets = 'Unlink Tickets'
}

@Component({
  selector: 'report-builder',
  templateUrl: './report-builder.component.html',
  styleUrls: ['./report-builder.component.scss'],
  providers: [ PreferencesService, ChargeService ]
})
export class ReportBuilderComponent implements OnInit {
  @ViewChild('chargeGrid', { static: false }) chargeGrid!: AgGridAngular;
  hasReadOnlyTicketAccess = this.authenticationService.getFeature('hasReadonlyTicketAccess');
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  errors: any[] = [];

  loading = false;
  static = false;
  staticToken!: string;
  chargeList: Charge[] = [];
  checkboxes: ReportCheckbox[] = [];
  actionOptions: ActionMenuOption[] = [];

  columnDefs = [
    {
      colId: 'chargeIndex',
      valueGetter: 'node.level === 0 ? node.childIndex + 1 : ""',
      cellStyle: {
        'font-size': '15px',
        'font-weight': '800',
        'white-space': 'normal !important'
      },
      editable: false,
      pinned: 'left',
      suppressMovable: true,
      lockPinned: true,
      suppressMenu: true,
      suppressNavigable: true,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Ticket Date',
      field: 'blob.ticket.ticketDate',
      editable: false,
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Ticket #',
      field: 'blob.ticket.ticketNumber',
      autoHeight: true,
      editable: false,
      suppressMenu: true,
      // cellRendererFramework: MultiValueCellComponent,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Driver',
      field: 'blob.ticket.driverName',
      cellStyle: { 'white-space': 'normal !important' },
      autoHeight: true,
      editable: !this.hasReadOnlyTicketAccess,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Truck',
      field: 'blob.ticket.truckNumber',
      cellStyle: { 'white-space': 'normal !important' },
      autoHeight: true,
      editable: !this.hasReadOnlyTicketAccess,
      hide: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Job',
      field: 'blob.ticket.jobName',
      cellStyle: { 'white-space': 'normal !important' },
      autoHeight: true,
      editable: !this.hasReadOnlyTicketAccess,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Origin',
      field: 'blob.ticket.origin',
      cellStyle: { 'white-space': 'normal !important' },
      autoHeight: true,
      editable: !this.hasReadOnlyTicketAccess,
      hide: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Destination',
      field: 'blob.ticket.destination',
      cellStyle: { 'white-space': 'normal !important' },
      autoHeight: true,
      editable: !this.hasReadOnlyTicketAccess,
      hide: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Customer',
      field: 'blob.ticket.customerName',
      cellStyle: { 'white-space': 'normal !important' },
      autoHeight: true,
      editable: !this.hasReadOnlyTicketAccess,
      hide: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Material',
      field: 'blob.ticket.material',
      cellStyle: { 'white-space': 'normal !important' },
      autoHeight: true,
      editable: !this.hasReadOnlyTicketAccess,
      hide: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Carrier Name',
      field: 'blob.ticket.carrierName',
      cellStyle: { 'white-space': 'normal !important' },
      autoHeight: true,
      editable: !this.hasReadOnlyTicketAccess,
      hide: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'QTY',
      field: 'quantity',
      valueGetter: 'Number(data.quantity)',
      editable: !this.hasReadOnlyTicketAccess,
      suppressMenu: true,
      onCellValueChanged: (e: any) => this.onCellValueChange(e),
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Type',
      field: 'blob.ticket.quantityType',
      editable: !this.hasReadOnlyTicketAccess,
      hide: true,
      suppressMenu: true,
      onCellValueChanged: (e: any) => this.onCellValueChange(e),
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Rate',
      field: 'rate',
      valueFormatter: currencyFormatter,
      editable: !this.hasReadOnlyTicketAccess,
      onCellValueChanged: (e: any) => this.onCellValueChange(e),
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Total',
      field: 'total',
      valueGetter: 'Number(data.total)',
      valueFormatter: currencyFormatter,
      cellStyle: {'text-align': 'right'},
      cellClass: 'cell-fixed-width',
      width: 90,
      editable: false,
      pinned: 'right',
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      resizable: true,
      sortable: true,
    },
  ];

  user = this.authenticationService.user();
  preferences: Preferences =  {
    blob: {
      reportData: {} as ReportFields,
      columnData: []
    }
  } as Preferences;

  report: Report = {} as Report;
  reportType?: 'expense' | 'invoice';
  reportImages: ReportImageGroup[] = [];
  imagesVisible = true;
  unbillableVisible = false;

  selectedDriverReference!: DriverReference;
  isDriverFieldVisible = false;

  selectedCustomerReference!: CustomerReference;
  isCustomerFieldVisible = false;

  surcharges = 0;

  expandedColumns: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public preferencesService: PreferencesService,
    private authenticationService: AuthenticationService,
    public ticketService: TicketService,
    public chargeService: ChargeService,
    public reportService: ReportService,
    private sendReportDialog: MatDialog,
    private arrangeModal: MatDialog
  ) { }

  numberOfTicketsSelected(): number {
    return this.gridApi && this.gridApi.getSelectedRows().length;
  }

  enableLinking(): boolean {
    const selectedCharges: Charge[] = this.gridApi && this.gridApi.getSelectedRows();
    return selectedCharges.length > 1 && selectedCharges.every((charge) => charge.attachments.length === 0);
  }

  enableEditing(): boolean {
    const selectedCharges: Charge[] = this.gridApi && this.gridApi.getSelectedRows();
    return selectedCharges.length === 1 && selectedCharges[0].attachments.length > 0;
  }

  ngOnInit() {
    const combinedParams = observableCombineLatest(
      [this.route.url, this.route.params, this.route.queryParams]);

    combinedParams.pipe(
      map(results => ({url: results[0], params: results[1], qparams: results[2]}))
    ).subscribe(result => {
      this.loading = true;

      if (result.url && result.url[0]) {
        if (result.url[0].path === 'settlements') {
          this.reportType = 'expense';
        } else {
          this.reportType = 'invoice';
        }
      }

      if (result.url && result.url[2].path === 'static') {
        this.static = true;
        this.staticToken = result.qparams['token'];
        this.getStaticReport(result.params['id'], result.qparams['token']);
      } else {
        this.getReport(result.params['id']);
      }
    });
  }

  getReport(id: string) {
    this.reportService.get(id).subscribe(report => this.setReportData(report));
  }

  getStaticReport(id: string, token: string) {
    this.reportService.getStatic(id, token)
      .subscribe(report => this.setReportData(report),
        () => {
          this.errors = ['Invalid token! Please make sure your URL is correct and try again.'];
        });
  }

  setReportData(report: Report) {
    report.reportDate = report.reportDate ? report.reportDate : new Date().toISOString();
    report.charges.map(charge => {
      if (charge.blob && charge.blob.ticket) {
        charge.quantity = Number(charge.quantity) === 0 ? charge.blob.ticket.quantity : charge.quantity;
        charge.rate = Number(charge.rate) === 0 ? charge.blob.ticket.haulRate : charge.rate;
        if (charge.blob && charge.blob.ticket && charge.blob.ticket.ticketDate) {
          try {
            charge.blob.ticket.ticketDate = moment(charge.blob.ticket.ticketDate).format('MM/DD/YYYY');
          } catch(err) {
            // Ignore
          }
        }
        this.getChargeTotal(charge);
      }
      return charge;
    });
    this.report = report;
    this.chargeList = this.getReportCharges(report);

    this.preferences = {
      ...this.preferences,
      name: this.reportType,
      type: this.reportType,
    };

    if (!this.report.dueDate) {
      this.report.dueDate = moment().format();
    }

    window.status = 'ready';
  }

  getReportCharges(report: Report): Charge[] {
    const chargeList: Charge[] = [];
    report.charges.forEach((charge: Charge) => {
      charge.blob.dataPath = [charge.id];
      chargeList.push(charge);
      if (charge.attachments) {
        charge.attachments.forEach((attachment: Charge) => {
          attachment.blob.dataPath = [charge.id, attachment.id];
          chargeList.push(attachment);
        });
      }
    });
    return chargeList;
  }

  getChargeDataPath(charge: Charge) {
    return charge.blob.dataPath;
  }

  getReportTaxTotal() {
    const charges = this.report && this.report.charges ? this.report.charges : [];
    const taxRate = this.report && this.report.taxRate ? this.report.taxRate : 0;

    const subtotal = charges.reduce((accum: Decimal, charge) => accum.plus(charge.total ? charge.total.toString() : 0), new Decimal(0));

    const taxTotal = subtotal.dividedBy(100).times(taxRate);

    return currencyFormatter({ value: taxTotal });
  }

  print() {
    if (!this.static) {
      this.save(false);
    }
    this.gridApi.deselectAll();
    this.gridApi.setDomLayout('print');
    window.print();
    this.gridApi.setDomLayout('autoHeight');
  }

  save(navigate = true) {
    // remove all null values in the save ticket object
    this.reportService.removeNulls(this.report);

    if (this.report.reportDate && typeof this.report.reportDate !== 'string') {
      this.report.reportDate = new Date(this.report.reportDate).toISOString();
    }
    if (this.report.dueDate && typeof this.report.dueDate !== 'string') {
      this.report.dueDate = new Date(this.report.dueDate).toISOString();
    }
    this.reportService.save(this.report).subscribe(() => {
      this.saveReportPreferences();
    }, err => {
      this.errors = this.errors.concat(parseErrors(err));
      throw err;
    }, () => {
      if (navigate) {
        if (this.reportType === 'invoice') {
          this.router.navigate(['invoices']);
        } else {
          this.router.navigate(['settlements']);
        }
      }
    });
  }

  send() {
    if (!this.static) {
      this.save(false);
    }
    const dialog = this.sendReportDialog.open(SendReportDialogComponent, {
      width: '500px',
      data: {
        id: this.report.id,
        type: this.reportType,
        number: this.report.reportNumber,
        email: this.report.customer && this.report.customer.email ? this.report.customer.email : ''
      },
    });

    dialog.componentInstance.callback = () => {
      const lockReportObj = { id: this.report.id, isLocked: true };
      this.reportService.save(lockReportObj).subscribe();
    };
  }

  getAddressString(data: any): string {
    const address: ReportAddress = data as ReportAddress;
    return (address.street ? address.street + '\n' : '') +
           (address.address2 ? address.address2 + '\n' : '') +
           (address.city ? address.city + ', ' : '') +
           (address.state ? address.state + ' ' : '') +
           (address.zipcode ? address.zipcode : '');
  }

  getReportSubtotal(): string {
    if (this.report && this.report.charges) {
      const charges = this.report.charges.
                      reduce((accum: Decimal, charge) => accum.plus(charge.total ? charge.total.toString() : 0), new Decimal(0));

      this.report.subtotal = charges.toFixed(2);

      return currencyFormatter({value: new Decimal(this.report.subtotal)});
    } else {
      return '';
    }
  }

  getReportTotal(): string {
    if (this.report && this.report.charges) {
      const charges = this.report.charges.
                      reduce((accum: Decimal, charge) => accum.plus(charge.total ? charge.total.toString() : 0), new Decimal(0));

      this.report.subtotal = charges.toFixed(2);
      const taxAmount = this.report.taxRate &&
                        new Decimal(this.report.taxRate).gt(0) ?
                        new Decimal(this.report.taxRate).dividedBy(100).times(this.report.subtotal) : 0;
      this.report.total = new Decimal(this.report.subtotal).plus(taxAmount).plus(this.report.surchargeAmount).toFixed(2);
      return currencyFormatter({value: new Decimal(this.report.total)});
    } else {
      return '';
    }
  }

  getReportTotalTons(): string {
    if (this.report && this.report.charges) {
      const totalTons = this.report.charges.
                        reduce((accum: Decimal, charge) => accum.plus(charge.totalTons ? charge.totalTons.toString() : 0), new Decimal(0));

      this.report.totalTons = totalTons.toFixed(2);
      this.report.totalTons = new Decimal(this.report.totalTons).toFixed(2);
      return new Decimal(this.report.totalTons).toString();
    } else {
      return '';
    }
  }

  getChargeTotal(charge: Charge) {
    const newTotal: string = charge.rate && charge.quantity ? Decimal.mul(charge.rate.replace('$', ''), charge.quantity).toString() : '';
    const totalTons: string = charge.quantity ? charge.quantity.toString() : '';
    charge.total = newTotal;
    charge.totalTons = totalTons;
  }

  getReportImages() {
    this.reportImages = [];
    this.gridApi.forEachNode(row => this.imageGroupSetup(row));
    this.reportImages.sort((a, b) => a.index - b.index);
  }

  toggleImages() {
    this.imagesVisible = !this.imagesVisible;
  }

  toggleBillable() {
    this.unbillableVisible = !this.unbillableVisible;
    this.gridApi.forEachNode(row => row.expanded = this.unbillableVisible);
    this.gridApi.onGroupExpandedOrCollapsed();
  }

  imageGroupSetup(row: RowNode) {
    const imageGroup: ReportImageGroup = {
      index: row.childIndex + 1,
      images: []
    };

    if (row.allChildrenCount) {
      imageGroup.index = row.childIndex + 1;
      row.allLeafChildren.forEach((child: RowNode) => {
        imageGroup.images.push({
          number: child.data.blob.ticket.ticketNumber,
          date: child.data.blob.ticket.ticketDate,
          resized: this.ticketService.getImage(child.data.blob.ticket, { x: 600, y: 480 }),
          url: child.data.blob.ticket.image
        });
      });
      this.reportImages.push(imageGroup);
    } else if (row.level === 0 && row.data && row.data.blob && row.data.blob.ticket) {
      imageGroup.images.push({
        number: row.data.blob.ticket.ticketNumber,
        date: row.data.blob.ticket.ticketDate,
        resized: this.ticketService.getImage(row.data.blob.ticket, { x: 600, y: 480 }),
        url: row.data.blob.ticket.image
      });
      this.reportImages.push(imageGroup);
    }
  }

  saveReportPreferences() {
    if (!this.static) {
      this.preferences.profile = this.user.id;
      this.preferences.blob.columnData = this.columnApi.getColumnState();
      this.preferences.blob.unbillableVisible = this.unbillableVisible;
      this.preferencesService.save(this.preferences).subscribe();
    }
  }

  applyReportPreferences() {
    this.preferencesService.list().pipe(
      map(prefs => {
        const pref = prefs.find(p => (p.name === 'expense') || (p.name === 'invoice'));
        return pref ? pref : new Preferences();
      }),
    ).subscribe(preferences => {
      if (preferences.blob) {
        this.preferences = preferences;
        if (preferences.blob.columnData) {
          this.columnApi.setColumnState(preferences.blob.columnData);
        } else {
          this.autoSizeAll();
        }

        if (preferences.blob.unbillableVisible) {
          this.unbillableVisible = preferences.blob.unbillableVisible;
          this.gridApi.forEachNode(row => row.expanded = this.unbillableVisible);
        }
      } else {
        this.autoSizeAll();
      }
      this.gridApi.resetRowHeights();
      this.setPreferences(this.preferences);

      const eventList = [
        'gridColumnsChanged',
        'columnPinned',
        'columnVisible',
        'columnResized',
        'columnMoved',
      ];

      eventList.forEach(event => {
        this.gridApi.addEventListener(event, debounce(() => this.saveReportPreferences(), 300));
      });
    });
  }

  getStaticPreferences(token: string) {
    this.preferencesService.listStatic(token).pipe(
      map(prefs => {
        const pref = prefs.find(p => (p.name === 'expense') || (p.name === 'invoice'));
        return pref ? pref : new Preferences();
      }),
    ).subscribe(preferences => {
      if (preferences.blob) {
        this.setPreferences(preferences);
        if (preferences.blob.columnData) {
          this.columnApi.setColumnState(preferences.blob.columnData);
        }

        if (preferences.blob.unbillableVisible) {
          this.unbillableVisible = preferences.blob.unbillableVisible;
          this.gridApi.forEachNode(row => row.expanded = this.unbillableVisible);
        }
      } else {
        this.autoSizeAll();
      }
      this.gridApi.resetRowHeights();
    });
  }

  setPreferences(preferences: any) {
    let reportData: ReportFields;

    if (Object.keys(preferences.blob).find(key => key === 'invoiceData')) {
      reportData = preferences.blob.invoiceData as ReportFields;
    } else if (Object.keys(preferences.blob).find(key => key === 'settlementData')) {
      reportData = preferences.blob.settlementData as ReportFields;
    } else {
      reportData = preferences.blob.reportData as ReportFields;
    }

    const billFromEmpty: boolean = reportData.sender &&
                                   !reportData.sender.name &&
                                   !reportData.sender.phoneNumber &&
                                   !reportData.sender.address;
    if ((isEmpty(reportData) || billFromEmpty) && this.user && this.user.organization) {
      reportData.logo = this.user.organization.image;
      reportData.notes = this.user.organization.remittanceInfo;
      reportData.sender = {
        name: this.user.organization.name,
        phoneNumber: this.user.organization.phoneNumber,
        address: this.getAddressString(this.user.organization),
        email: ''
      };
      this.preferences.blob.reportData = reportData;
    }
    this.report.logo = this.report.logo ? this.report.logo : reportData.logo;
    this.report.notes = this.report.notes ? this.report.notes : reportData.notes;
    // should potentially check to see if theres a better way to see if all of the obj props are empty
    this.report.sender = this.report.sender &&
                          (this.report.sender.name || this.report.sender.phoneNumber || this.report.sender.address) ?
                          this.report.sender : reportData.sender;
  }

  getCheckboxes() {
    this.checkboxes = [];
    this.gridApi.forEachNode((node: RowNode) => this.constructCheckbox(node));
  }

  constructCheckbox(node: RowNode) {
    if (node.expanded || node.level === 0) {
      const checkboxObj: ReportCheckbox = {
        enabled: node.level === 0,
        height: node.rowHeight || 0,
        selected: !!node.isSelected(),
        onClick: () => {
          node.setSelected(!node.isSelected());
        },
      };
      this.checkboxes.push(checkboxObj);
    }
  }

  selectCheckboxes() {
    this.gridApi.forEachNode((node: RowNode) => {
      if (node.expanded || node.level === 0) {
        const roIndex = node.rowIndex as number;
        this.checkboxes[roIndex].selected = !!node.isSelected();
      }
    });
  }

  onGridReady(e: GridReadyEvent): void {
    if (!e.api || !e.columnApi) {
      return;
    }
    this.columnApi = e.columnApi;
    this.gridApi = e.api;

    if (this.static) {
      (this.columnApi.getAllColumns() as Column[]).forEach(column => {
        const colDef = column.getColDef();
        colDef.editable = false;
      });
      this.getStaticPreferences(this.staticToken);
    } else {
      this.applyReportPreferences();
    }
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.addEventListener('modelUpdated', () => {
      this.gridApi.forEachNode(row => row.expanded = this.unbillableVisible);
      this.getReportImages();
      this.getCheckboxes();
    });
    this.gridApi.addEventListener('rowSelected', () => this.selectCheckboxes());
    this.gridApi.addEventListener('columnRowGroupChanged', () => this.rowGroupCallback());
    this.gridApi.addEventListener('onGroupExpandedOrCollapsed', () => this.gridApi.redrawRows());
    this.gridApi.addEventListener('sortChanged', () => this.rowSortCallback());
    this.gridApi.addEventListener('displayedColumnsChanged', () => {
      this.gridApi.sizeColumnsToFit();
      this.gridApi.resetRowHeights();
    });
    this.columnApi.setColumnWidth('chargeId', 28);
    this.chargeGrid.gridOptions.getMainMenuItems = () => this.getMainMenuItems();
    this.chargeGrid.gridOptions.getContextMenuItems = (params: any) => this.getContextMenuItems(params);
    this.getActionMenuOptions();
  }

  onCellValueChange(e: ValueSetterParams): void {
    if (e.newValue === e.oldValue) {
      return;
    } else {
      this.getChargeTotal(e.data);
      this.chargeService.save(e.data).subscribe();
      this.gridApi.refreshCells();
    }
  }

  onDeleteRow(params: CellContextMenuEvent) {
    if (!params || !params.node || !params.node.data) {
      return;
    }
    this.chargeService.remove(params.node.data.id).subscribe(() => {
      const selected = [params.node.data];
      this.gridApi.updateRowData({ remove: selected });
    });
  }

  linkCharges() {
    const selectedCharges: Charge[] = this.gridApi.getSelectedRows();
    this.arrangeLinkedCharges(selectedCharges);
  }

  unlinkCharges(charge: Charge) {
    const chargeOrderData: OrderData[] = [{ id: charge.id, order: 0, parent: null }];
    // get group and perform a single unlinking action,
    // probably display a warning message?
    if (charge.attachments) {
      charge.attachments.forEach((attachment: Charge) => {
        chargeOrderData.push({ id: attachment.id, order: 0, parent: null });
      });
    }
    this.bulkSaveCharges(chargeOrderData);
  }

  arrangeLinkedCharges(charges: Charge[]) {
    let tickets: Ticket[] = [];
    charges.forEach((charge: Charge) => {
      const chargeTicketData: Ticket = charge.blob.ticket;
      // mapping the charge id to the ticket object so we
      // ensure the correct parent FK is set in the callback
      chargeTicketData.id = charge.id;
      if (charge.attachments.length) {
        // reset the ticket array to only include the parent if attachments are detected
        tickets = [chargeTicketData];
        charge.attachments.forEach((attachedCharge: Charge) => {
          const attachedChargeTicketData: Ticket = attachedCharge.blob.ticket;
          attachedChargeTicketData.id = attachedCharge.id;
          tickets.push(attachedChargeTicketData);
        });
      } else {
        tickets.push(chargeTicketData);
      }
    });
    const modal = this.arrangeModal.open(ArrangeModalComponent, {
      width: '652px',
      data: tickets
    });
    modal.componentInstance.callback = (chargeOrderData: OrderData[]) => this.bulkSaveCharges(chargeOrderData);
    modal.componentInstance.unlinkCallback = (charge: Charge) => this.unlinkCharges(charge);
  }

  bulkSaveCharges(chargeOrderData: OrderData[]) {
    const chargeObservables: Observable<Charge>[] = [];
    chargeOrderData.forEach((chargeOrderObj: OrderData) => {
      chargeObservables.push(this.chargeService.save(chargeOrderObj));
    });
    combineLatest(chargeObservables).pipe(
      mergeMap(charge => (this.reportService.get(charge[0].report)))
    ).subscribe(report => {
      this.setReportData(report);
      this.gridApi.forEachNode(row => row.expanded = this.unbillableVisible);
    });
  }

  rowGroupCallback() {
    this.gridApi.refreshCells();
    this.autoSizeAll();
    this.saveReportPreferences();
    this.getReportImages();
  }

  rowSortCallback() {
    this.gridApi.refreshCells();
    this.getReportImages();
  }

  autoSizeAll() {
    const allColumnIds: string[] = [];

    (this.columnApi.getAllColumns() as Column[]).forEach(column => {
      allColumnIds.push(column.getColId());
    });

    this.columnApi.autoSizeColumns(allColumnIds);
    this.columnApi.setColumnWidth('chargeId', 70);
    this.columnApi.setColumnWidth('total', 90);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    this.saveReportPreferences();
  }

  addCharge() {
    const newCharge = { report: this.report.id } as Charge;
    this.chargeService.save(newCharge).pipe(
      mergeMap(charge => this.reportService.get(charge.report))
    ).subscribe(report => this.report = report);
  }

  getContextMenuItems(params: CellContextMenuEvent) {
    const selectedCharges: Charge[] = this.gridApi && this.gridApi.getSelectedRows();
    let menuOptions = [];
    if (this.static) {
      menuOptions = [
        {
          name: 'Autosize Columns',
          action: () => this.autoSizeAll()
        }
      ];
    } else {
      menuOptions = [
        'copy',
        {
          name: 'Delete Row',
          action: () => this.onDeleteRow(params)
        },
        'separator',
        {
          name: 'Autosize Columns',
          action: () => this.autoSizeAll()
        }
      ];

      if (this.enableLinking()) {
        menuOptions.push('separator');
        menuOptions.push({ name: 'Link Tickets', action: () => this.linkCharges() });
      } else if (this.enableEditing()) {
        menuOptions.push('separator');
        menuOptions.push({ name: 'Arrange Tickets', action: () => this.linkCharges() });
        menuOptions.push({ name: 'Unlink Tickets', action: () => this.unlinkCharges(selectedCharges[0]) });
      }

    }
    return menuOptions;
  }

  getActionMenuOptions() {
    const selectedCharges: Charge[] = this.gridApi.getSelectedRows();
    this.actionOptions = [
      {
        name: ActionType.AutosizeColumns,
        onClick: () => this.autoSizeAll(),
        disabled: () => false,
      },
      {
        name: ActionType.LinkTickets,
        onClick: () => this.linkCharges(),
        disabled: () => !this.enableLinking(),
      },
      {
        name: ActionType.ArrangeTickets,
        onClick: () => this.linkCharges(),
        disabled: () => !this.enableEditing(),
      },
      {
        name: ActionType.UnlinkTickets,
        onClick: () => this.unlinkCharges(selectedCharges[0]),
        disabled: () => !this.enableEditing(),
      },
    ];
  }

  getMainMenuItems() {
    return [
      {
        name: 'Autosize Columns',
        action: () => this.autoSizeAll()
      },
      'resetColumns'
    ];
  }

  onCustomerReferenceSelected(reference: CustomerReference): void {
    this.selectedCustomerReference = reference;
    const address: ReportAddress = {
      street: this.selectedCustomerReference.customerAddress1,
      address2: this.selectedCustomerReference.customerAddress2,
      city: this.selectedCustomerReference.customerCity,
      state: this.selectedCustomerReference.customerState,
      zipcode: this.selectedCustomerReference.customerZipcode
    } as ReportAddress;
    this.report.customer.name = reference.customerName ? reference.customerName : '';
    this.report.customer.phoneNumber = reference.customerPhoneNumber ? reference.customerPhoneNumber : '';
    this.report.customer.address = this.getAddressString(address);
    this.report.customer.email = reference.customerEmail ? reference.customerEmail : '';
    this.report.ticketManagerCustomer = reference.id;
    this.isCustomerFieldVisible = false;
  }

  onCustomerReferenceBlur(): void {
    this.isCustomerFieldVisible = false;
  }

  onDriverReferenceSelected(reference: DriverReference): void {
    this.selectedDriverReference = reference;

    this.report.customer.name = reference.driverName ? reference.driverName : '';
    this.report.customer.phoneNumber = reference.driverPhoneNumber ? reference.driverPhoneNumber : '';
    this.isDriverFieldVisible = false;
  }

  onDriverReferenceBlur(): void {
    this.isDriverFieldVisible = false;
  }
}

export const currencyFormatter = (params: any) => {
  let negative = false;
  if (params.value < 0) {
    negative = true;
    params.value = Math.abs(params.value);
  }
  // first check if the value passed into here is a valid number,
  // then if so, create a currency formatted string that appends a dollar sign and commas appropriately
  // and rounds amount to the nearest hundredth, otherwise simply return the value unformatted
  if (!isNaN(+params.value) && isFinite(params.value)) {
    const amount = new Decimal(+params.value).toFixed(2);
    const amountDollars = new Decimal(amount.split('.')[0]).toNumber().toLocaleString();
    const amountCents = amount.split('.')[1];
    return (negative ? '-$' : '$') + amountDollars + '.' + amountCents;
  } else {
    return params.value;
  }
};

export const sameAggFunction = (values: any[]) => {
  if (values.every(value => value === values[0])) {
    return values[0];
  }
};

export const stackedAggFunction = (values: any[]) => {
  let stackedValues = '';
  for (let i = 0; i < values.length; i++) {
    stackedValues = i + 1 !== values.length ?
      stackedValues + values[i] + ', ' :
      stackedValues + values[i];
  }
  return stackedValues;
};
