<button class="btn btn-default action-menu__toggle" mat-icon-button [matMenuTriggerFor]="menu">
  <div class="action-menu-icon icomoon {{ icon }}" *ngIf="icon"></div>
  {{ title }}
</button>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let option of options"
    [disabled]="option.disabled()"
    (click)="onOptionClick(option)"
  >
    {{ option.name }}
  </button>
</mat-menu>
