<div class="container">
  <div class="grid-display">
    <div class="title">Jobs</div>
    <div class="job-reference-grid-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <action-menu title="Actions" [options]="actionOptions"></action-menu>
      <button class="btn btn-primary add-job" *ngIf="!hasReadOnlyTicketAccess" (click)="onAddJobClick()">
        {{ 'Add New Job Reference' | translate }}
      </button>
    </div>
    <ag-grid-angular
      #jobsGrid
      class="ag-theme-material jobs-grid"
      [animateRows]="true"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [getRowHeight]="getRowHeight"
      [pagination]="true"
      [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType"
      [cacheBlockSize]="cacheBlockSize"
      [maxBlocksInCache]="maxBlocksInCache"
      [frameworkComponents]="frameworkComponents"
      [sideBar]="sideBar"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanges()"
      rowSelection="multiple"
      suppressBrowserResizeObserver="true"
    ></ag-grid-angular>
  </div>
</div>
