<div class="box">
  <div class="container" [ngClass]="{ loading: loading }">
    <my-loader></my-loader>
    <ng-container *ngIf="newTicket || editableImage(); else correctionControls">
      <div class="controls">
        <mat-icon (click)="delete()">delete</mat-icon>
        <mat-icon (click)="rotate(90)">rotate_right</mat-icon>
        <mat-icon (click)="save()">save</mat-icon>
        <!-- <mat-icon>upload</mat-icon>
        <input type="file" name="file" id="file" class="inputfile" (change)="save($event)" #fileInput/> -->
      </div>
    </ng-container>
    <ng-template #correctionControls>
      <div class="controls">
        <mat-icon>upload</mat-icon>
        <input
          type="file"
          name="file"
          id="file"
          class="inputfile"
          (change)="save($event)"
          #fileInput
        />
      </div>
    </ng-template>

    <angular-cropper
      *ngIf="editableImage()"
      (click)="imageClick()"
      #imageEditor
      [cropperOptions]="config"
      [imageUrl]="imageUrl"
    ></angular-cropper>
    <img
      *ngIf="!newTicket && !editableImage()"
      (click)="imageClick()"
      [src]="imageUrl"
    />
  </div>
</div>
