<div class="ruckit-customer-reference-create">
  <form [formGroup]="referenceForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-title class="modal-title ruckit-customer-reference-create__title">
      <h1 *ngIf="mode === 'create'" translate>Create New Customer Reference</h1>
      <h1 *ngIf="mode === 'edit'" translate>Edit Customer Reference</h1>
      <div class="close-action" (click)="dialogRef.close()">
        <i class="icomoon icon-close"></i>
      </div>
    </div>
    <div mat-dialog-content class="ruckit-customer-reference-create__content">
      <mat-form-field>
        <input matInput placeholder="{{ 'Customer Code' | translate }}" formControlName="customerCode">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{ 'Name' | translate }}" formControlName="customerName">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{ 'Address 1' | translate }}" formControlName="customerAddress1">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{ 'Address 2' | translate }}" formControlName="customerAddress2">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{ 'City' | translate }}" formControlName="customerCity">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{ 'State' | translate }}" formControlName="customerState">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{ 'Zip Code' | translate }}" formControlName="customerZipcode">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{ 'Phone Number' | translate }}" formControlName="customerPhoneNumber">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{ 'Email' | translate }}" formControlName="customerEmail">
      </mat-form-field>
    </div>
    <div class="ruckit-customer-reference-create__submit">
      <button type="submit" class="btn btn-primary" [disabled]="!referenceForm.valid" (submit)="onSubmit()">
        {{ 'Submit' | translate }}
      </button>
    </div>
  </form>
</div>
