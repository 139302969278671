import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

type MenuOption = {
  active: boolean
  icon?: string
  onClick?: () => void
  label: string
  route?: string
  toggled?: boolean
  children?: MenuOption[]
};

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() drawerVisible = false;
  @Input() visible = true;

  version = environment.applicationVersion;

  menuOptions: MenuOption[] = [];

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (!this.visible) {
      this.drawerVisible = false;
    }
    this.setMenuOptions();
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event) => {
      const e: NavigationStart = event as NavigationStart;

      this.onRoutechange(e);
    });
  }

  setMenuOptions() {
    this.menuOptions = [{ active: false, icon: 'list_alt', label: 'Tickets', route: '/tickets' }];
    this.menuOptions.push({
      active: false,
      icon: 'link',
      label: 'References',
      onClick: () => {
        this.drawerVisible = true;
      },
      toggled: false,
      children: [
        { active: false, label: 'Customers', route: '/customers' },
        { active: false, label: 'Jobs', route: '/jobs' },
        { active: false, label: 'Drivers', route: '/drivers' },
        { active: false, label: 'Trucks', route: '/trucks' },
      ],
    });
    this.menuOptions = this.menuOptions.concat([{ active: false, icon: 'monetization_on', label: 'Invoices', route: '/invoices' },
    { active: false, icon: 'local_shipping', label: 'Settlements', route: '/settlements' },
    { active: false, icon: 'settings', label: 'Settings', route: '/settings/organization' }]);
  }

  onRoutechange(event: NavigationStart) {
    if (event.url.includes('uploader')) {
      this.drawerVisible = false;
    }
    if (event.url.includes('logout')) {
      this.drawerVisible = false;
      this.visible = false;
    }

    this.menuOptions.forEach((option) => {
      if (option.route && option.route === event.url) {
        option.active = true;
      }

      if (option.children) {
        option.children.forEach((child) => {
          if (child.route && child.route === event.url) {
            option.active = true;
            option.toggled = true;

            child.active = true;
          }
        });
      }
    });
  }

  onOptionClick(index: number, childIndex?: number) {
    this.menuOptions = [...this.menuOptions].map((option, i) => {
      const selected = (index === i);

      if (selected) {
        option.active = true;

        if (childIndex !== undefined && option.children) {
          option.children = option.children.map((child, j) => {
            const childSelected = (j === childIndex);

            if (childSelected) {
              child.active = true;

              if (child.onClick) {
                child.onClick();
              }

              if (child.route && child.route !== this.router.url) {
                this.router.navigateByUrl(child.route);
              }
            }

            if (!childSelected) {
              child.active = false;
            }

            return child;
          });
        }

        if (childIndex === undefined && option.children) {
          option.toggled = !option.toggled;
        }

        if (option.onClick) {
          option.onClick();
        }

        if (option.route && option.route !== this.router.url) {
          this.router.navigateByUrl(option.route);
        }
      }

      if (!selected && childIndex === undefined) {
        option.toggled = false;
        option.active = false;

        if (option.children) {
          option.children.forEach(child => child.active = false);
        }
      }

      return option;
    });
  }

  toggleSidebar() {
    this.drawerVisible = !this.drawerVisible;
  }
}
