'use strict';

import { Compiler, Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'alert-panel',
  templateUrl: './alert-panel.component.html',
  styleUrls: ['./alert-panel.component.scss']
})
export class AlertPanelComponent implements OnInit {
  alert = 'Update Available';
  updateNoticeHidden = false;
  updateAvailable = false;
  applicationVersion = environment.applicationVersion;
  releasedVersion!: string;

  constructor(
    private http: HttpClient,
    private compiler: Compiler
  ) {}

  ngOnInit() {
    this.getRevision();
  }

  compareVersions(): void {
    if (
      this.applicationVersion &&
      this.releasedVersion &&
      this.applicationVersion.toLowerCase().trim() !==
        this.releasedVersion.toLowerCase().trim()
    ) {
      this.updateAvailable = true;
    } else {
      this.updateAvailable = false;
    }
  }

  getRevision(): void {
    const expire = Date.now();
    this.http.get('REVISION?expire=' + expire).subscribe(
      (response: any) => {
        this.releasedVersion = response.text();
        this.compareVersions();
      },
      () => {
        this.updateNoticeHidden = true;
      }
    );
  }

  reload(): void {
    this.compiler.clearCache();
    window.location.reload();
  }
}
