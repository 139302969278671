import { Component, AfterViewInit, ViewContainerRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';
import { TicketService } from '../../../tickets/ticket.service';
import { DriverReference } from '../driver-reference';

@Component({
  selector: 'driver-reference-cell',
  templateUrl: './driver-reference-cell.component.html',
  styleUrls: ['./driver-reference-cell.component.scss'],
  providers: [TicketService],
})
export class DriverReferenceCellComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('driverReferenceInput', { read: ViewContainerRef }) jobReferenceInput: any;
  params!: ICellEditorParams;
  gridApi!: GridApi;
  reference!: DriverReference;
  value = '';


  constructor(
    private ticketService: TicketService,
  ) { }

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.gridApi = params.api;
  }

  ngAfterViewInit(): void { }

  getValue() {
    if (this.reference) {
      return this.reference.driverName;
    }

    const cell = this.gridApi.getFocusedCell();
    const row = cell ? this.gridApi.getDisplayedRowAtIndex(cell.rowIndex) : null;

    return row && row.data.driverName ? row.data.driverName : '';
  }

  isCancelBeforeStart?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  isPopup() {
    return true;
  }

  onReferenceClicked(reference: DriverReference): void {
    const cell = this.gridApi.getFocusedCell();
    const row = cell ? this.gridApi.getDisplayedRowAtIndex(cell.rowIndex) : null;
    if (row) {
      this.ticketService.updateFieldReference<DriverReference>(row.data.id, reference, 'driver').subscribe(ticket => {
        this.reference = reference;
        row.setData(ticket);
        this.gridApi.stopEditing();
      });
    }
  }

  onReferenceClosed(): void {
    this.gridApi.stopEditing();
  }
}
