import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ResourceService } from '../resource.service';

@Component({
  selector: 'checkbox-cell',
  templateUrl: './checkbox-cell.component.html',
  styleUrls: ['./checkbox-cell.component.scss']
})
export class CheckboxCellComponent implements AgRendererComponent {
  value!: boolean;
  rowId!: string;
  field!: string;
  service!: any;
  isDisabled = false;
  readOnly = false;
  callback!: (updates: object, id?: string) => void;

  constructor() { }

  agInit(params: any): void {
    this.value = params.value;
    this.rowId = params.data && params.data.id;
    this.field = params.field;
    this.callback = params.callback;
    this.service = params.service as ResourceService<any>;
    this.isDisabled = params.isDisabled;
    this.readOnly = params.readOnly;
  }

  refresh(): boolean {
    return false;
  }

  getValue() {
    return this.value;
  }

  valueChange(value: any) {
    if (this.readOnly) {
      return;
    }
    const updates: { [key: string]: any } = {};
    updates[this.field] = value;
    if (this.service) {
      this.service.patch(updates, this.rowId).subscribe((result: any) => {
        this.value = result[this.field];
        if (this.callback) {
          this.callback(updates, this.rowId);
        }
      });
    }
  }
}
