import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { ToolbarComponent } from './index';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragulaModule } from 'ng2-dragula';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularCropperjsModule } from 'angular-cropperjs';

import { environment } from '../../environments/environment';

// Components
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { LoaderComponent } from '../directives/index';
import { NotificationComponent } from './notification/notification.component';
import { PaginationComponent } from '../shared/pagination/pagination.component';
import { NoResultsComponent } from '../shared/index';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { SearchComponent } from './search/search.component';
import {
  SidebarComponent,
  ActionBarComponent,
  NotFoundComponent,
  AlertPanelComponent
} from './index';
import { MenuLinkComponent, MenuToggleComponent } from '../directives/index';
import { NguiMapModule } from '@ngui/map';

// AG Grid Components
import { AgGridModule } from 'ag-grid-angular';
import { JobReferenceCellComponent } from '../references/job-reference/job-reference-cell/job-reference-cell.component';
import { JobReferenceSelectorComponent } from '../references/job-reference/job-reference-selector/job-reference-selector.component';
import {
  AutocompleteCellComponent,
  BulkActionDialogComponent,
  CheckboxCellComponent,
  DatepickerCellComponent,
  GridNotificationBarComponent,
  ImageIndicatorCellComponent,
  MultiValueCellComponent,
  StatusCellComponent,
  SelectAllHeaderComponent,
  GridImageToolPanelComponent,
  ReviewerCellComponent,
  NavBarComponent
} from './index';

// Ticket Table
import {
  TicketGridComponent,
  TicketGridDateCellComponent,
  TicketGridDatetimeCellComponent,
  TicketService
} from '../tickets/index';

// Jobs Table
import { JobReferenceService } from '../references/job-reference/job-reference.service';
import { JobReferenceGridComponent } from '../references/job-reference/job-reference-grid/job-reference-grid.component';

// Drivers Table
import { DriverReferenceService } from '../references/driver-reference/driver-reference.service';
import { DriverReferenceGridComponent } from '../references/driver-reference/driver-reference-grid/driver-reference-grid.component';

// Trucks Table
import { TruckReferenceService } from '../references/truck-reference/truck-reference.service';
import { TruckReferenceGridComponent } from '../references/truck-reference/truck-reference-grid/truck-reference-grid.component';

// Upload Components
import { BatchUploadComponent } from '../upload/batch-upload.component';
import { BatchUploadNotificationBarComponent } from '../upload/batch-upload-notification-bar.component';
import { ScaleSyncUploadComponent } from '../upload/scale-sync-upload.component';
import { OcrService } from '../upload/ocr.service';

// Reports
import { ReportService } from '../reports/report/report.service';
import { SendReportDialogComponent } from '../reports/send-report-dialog/send-report-dialog.component';

// Pipes
import {
  TypeFilterPipe,
  StatusFilterPipe,
  MenuFilterPipe,
  RecipientIdFilterPipe,
  SenderIdFilterPipe,
  FilterPipe,
  MomentPipe,
  KindFilterPipe,
  TelephonePipe,
  TitleCasePipe,
  GroupByPipe,
  PropertyFilterPipe,
  SafeHtmlPipe,
  MetersToMilesPipe,
  TimeFromNowPipe,
  JoinPipe,
  SortPipe,
  UniqueFilterPipe,
  FilterArrayPipe
} from '../shared/pipes/index';

// Services
import { UserService } from '../users/user.service';
import { DataService } from './data.service';
import { FileUploadService } from './file-upload/file-upload.service';
import { PreferencesService } from './preferences/preferences.service';
import { ClipboardModule } from 'ngx-clipboard';
import { CustomerReferenceGridComponent } from '../references/customer-reference/customer-reference-grid/customer-reference-grid.component';
import { CustomerReferenceSelectorComponent } from
 '../references/customer-reference/customer-reference-selector/customer-reference-selector.component';
import { CustomerReferenceCellComponent } from '../references/customer-reference/customer-reference-cell/customer-reference-cell.component';
import { DecamelPipe } from './pipes/decamel.pipe';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { JobReferenceCreateModalComponent } from
 '../references/job-reference/job-reference-create-modal/job-reference-create-modal.component';
import { CustomerReferenceCreateModalComponent } from
 '../references/customer-reference/customer-reference-create-modal/customer-reference-create-modal.component';
import { DriverReferenceCreateModalComponent }
 from '../references/driver-reference/driver-reference-create-modal/driver-reference-create-modal.component';
import { DriverReferenceCellComponent } from '../references/driver-reference/driver-reference-cell/driver-reference-cell.component';
import { DriverReferenceSelectorComponent }
 from '../references/driver-reference/driver-reference-selector/driver-reference-selector.component';
import { CustomerReferenceService } from '../references/customer-reference/customer-reference.service';
import { InvoiceService } from '../reports/invoice/invoice.service';
import { TruckReferenceCreateModalComponent }
 from '../references/truck-reference/truck-reference-create-modal/truck-reference-create-modal.component';
import { TruckReferenceCellComponent } from '../references/truck-reference/truck-reference-cell/truck-reference-cell.component';
import { TruckReferenceSelectorComponent } from '../references/truck-reference/truck-reference-selector/truck-reference-selector.component';
import { ExportModalComponent } from './export-modal/export-modal.component';
import { SyncModalComponent } from './sync-modal/sync-modal.component';
import { ReadOnlyCellComponent } from './grid/readonly-cell.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/');

const mapsApiUrl =
  'https://maps.google.com/maps/api/js?libraries=geometry,visualization,places,drawing&key=' +
  environment.googleMapsKey;

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatTableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatRippleModule,
    MatSelectModule,
    MatRadioModule,
    MatOptionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatSidenavModule,
    FlexLayoutModule,
    DragulaModule.forRoot(),
    NguiMapModule.forRoot({
      apiUrl: mapsApiUrl
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ClipboardModule,
    AgGridModule.withComponents([
      BulkActionDialogComponent,
      GridImageToolPanelComponent,
      ReviewerCellComponent,
      TicketGridDateCellComponent,
      TicketGridDatetimeCellComponent,
      AutocompleteCellComponent,
      ImageIndicatorCellComponent,
      MultiValueCellComponent,
      StatusCellComponent,
      CheckboxCellComponent,
      JobReferenceCellComponent,
      SelectAllHeaderComponent,
      DatepickerCellComponent,
      CustomerReferenceCellComponent,
      DriverReferenceCellComponent,
      TruckReferenceCellComponent,
      NoResultsComponent,
      ReadOnlyCellComponent,
    ]),
    InfiniteScrollModule,
    AngularCropperjsModule
  ],
  providers: [
    DataService,
    UserService,
    FileUploadService,
    PreferencesService,
    TicketService,
    OcrService,
    JobReferenceService,
    DriverReferenceService,
    TruckReferenceService,
    CustomerReferenceService,
    ReportService,
    InvoiceService,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  // entryComponents: [
  //   SendReportDialogComponent,
  //   JobReferenceCreateModalComponent,
  //   CustomerReferenceCreateModalComponent,
  //   DriverReferenceCreateModalComponent,
  //   TruckReferenceCreateModalComponent,
  //   ExportModalComponent,
  // ],
  declarations: [
    ToolbarComponent,
    ImageEditorComponent,
    DropdownComponent,
    LoaderComponent,
    NoResultsComponent,
    NotificationComponent,
    PaginationComponent,
    ActionMenuComponent,
    TypeFilterPipe,
    StatusFilterPipe,
    MenuFilterPipe,
    RecipientIdFilterPipe,
    SenderIdFilterPipe,
    FilterPipe,
    MomentPipe,
    KindFilterPipe,
    TelephonePipe,
    TitleCasePipe,
    GroupByPipe,
    PropertyFilterPipe,
    SafeHtmlPipe,
    MetersToMilesPipe,
    TimeFromNowPipe,
    JoinPipe,
    SortPipe,
    UniqueFilterPipe,
    FilterArrayPipe,
    DecamelPipe,
    SearchComponent,
    SidebarComponent,
    ActionBarComponent,
    NotFoundComponent,
    AlertPanelComponent,
    MenuLinkComponent,
    MenuToggleComponent,
    AutocompleteCellComponent,
    ReadOnlyCellComponent,
    BulkActionDialogComponent,
    SendReportDialogComponent,
    CheckboxCellComponent,
    DatepickerCellComponent,
    GridNotificationBarComponent,
    ImageIndicatorCellComponent,
    MultiValueCellComponent,
    StatusCellComponent,
    SelectAllHeaderComponent,
    NavBarComponent,
    TicketGridComponent,
    JobReferenceCellComponent,
    JobReferenceSelectorComponent,
    TruckReferenceCellComponent,
    TruckReferenceSelectorComponent,
    TicketGridDateCellComponent,
    TicketGridDatetimeCellComponent,
    GridImageToolPanelComponent,
    ReviewerCellComponent,
    BatchUploadComponent,
    BatchUploadNotificationBarComponent,
    ScaleSyncUploadComponent,
    JobReferenceGridComponent,
    DriverReferenceGridComponent,
    TruckReferenceGridComponent,
    CustomerReferenceGridComponent,
    CustomerReferenceSelectorComponent,
    DriverReferenceSelectorComponent,
    CustomerReferenceCellComponent,
    DriverReferenceCellComponent,
    PhoneNumberPipe,
    JobReferenceCreateModalComponent,
    CustomerReferenceCreateModalComponent,
    DriverReferenceCreateModalComponent,
    TruckReferenceCreateModalComponent,
    ExportModalComponent,
    SyncModalComponent,
  ],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatTableModule,
    TranslateModule,
    ToolbarComponent,
    FormsModule,
    LoaderComponent,
    ImageEditorComponent,
    DropdownComponent,
    NotificationComponent,
    MatInputModule,
    PaginationComponent,
    NoResultsComponent,
    DragulaModule,
    MatFormFieldModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TypeFilterPipe,
    StatusFilterPipe,
    MenuFilterPipe,
    RecipientIdFilterPipe,
    SenderIdFilterPipe,
    FilterPipe,
    MomentPipe,
    KindFilterPipe,
    TelephonePipe,
    TitleCasePipe,
    GroupByPipe,
    PropertyFilterPipe,
    SafeHtmlPipe,
    MetersToMilesPipe,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    ActionMenuComponent,
    TimeFromNowPipe,
    JoinPipe,
    SortPipe,
    UniqueFilterPipe,
    FilterArrayPipe,
    SearchComponent,
    SidebarComponent,
    ActionBarComponent,
    NotFoundComponent,
    AlertPanelComponent,
    MenuLinkComponent,
    MenuToggleComponent,
    NguiMapModule,
    FlexLayoutModule,
    CustomerReferenceSelectorComponent,
    DriverReferenceSelectorComponent,
    AgGridModule,
    NavBarComponent,
    ExportModalComponent,
    SyncModalComponent
  ]
})
export class SharedModule {}
