<div class="ruckit-job-reference-selector">
  <div class="ruckit-job-reference-selector__search-bar">
    <i
      *ngIf="visible"
      class="icon-close ruckit-job-reference-selector__search-bar__close"
      (click)="onCloseClick()"
    >
    </i>
    <i
      *ngIf="hasControls && !visible"
      class="icon-pencil ruckit-job-reference-selector__search-bar__edit"
      [ngClass]="{'icon-disabled': !selectedJobReference}"
      (click)="selectedJobReference && onEditReferenceClick()"
    >
    </i>
    <i
      *ngIf="hasControls && !visible"
      class="icon-search ruckit-job-reference-selector__search-bar__search"
      (click)="onInputFocus()"
    >
    </i>
    <input
      #jobReferenceInput
      matInput
      class="search"
      type="text"
      [disabled]="!visible"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (input)="onSearchChange($event.target.value)"
      (blur)="onInputBlur()"
    />
  </div>
  <div class="ruckit-job-reference-selector__results--container">
    <div
      *ngIf="visible"
      class="ruckit-job-reference-selector__results"
      infiniteScroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      [scrollWindow]="false"
      (scrolled)="onScrollDown()"
      (scrolledUp)="onScrollUp()"
    >
      <div *ngIf="loading" class="ruckit-job-reference-selector__results__loader">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div *ngIf="!loading && references.length <= 0" class="ruckit-job-reference-selector__results__missing">
        <div class="ruckit-job-reference-selector__result">
          No References Found
        </div>
      </div>
      <div *ngFor="let reference of references">
        <div class="ruckit-job-reference-selector__result" (mousedown)="onReferenceClicked($event, reference)">
          <div class="ruckit-job-reference-selector__result__title">
            {{ reference.jobCode }} - {{ reference.jobName }}
          </div>
          <div class="ruckit-job-reference-selector__result__content">
            <div class="ruckit-job-reference-selector__result__content__item">
              <div class="ruckit-job-reference-selector__result__content__item__header">Origin</div>
              <div class="ruckit-job-reference-selector__result__content__item__content">{{ reference.origin }}</div>
            </div>
            <div class="ruckit-job-reference-selector__result__content__item">
              <div class="ruckit-job-reference-selector__result__content__item__header">Material</div>
              <div class="ruckit-job-reference-selector__result__content__item__content">{{ reference.material }}</div>
            </div>
            <div class="ruckit-job-reference-selector__result__content__item">
              <div class="ruckit-job-reference-selector__result__content__item__header">Destination</div>
              <div class="ruckit-job-reference-selector__result__content__item__content">{{ reference.destination }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="visible" class="ruckit-job-reference-selector__footer">
      <i class="icon icon-plus"></i>
      <div class="ruckit-job-reference-selector__footer__content" (mousedown)="onAddClick()">
        Add New Job Reference
      </div>
    </div>
  </div>
</div>
