import { LocationOwner } from './location-owner';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class LocationOwnerSerializer {
  fromJson(json: any): LocationOwner {
    json = camelcaseKeysDeep(json);
    const locationOwner = new LocationOwner();
    if (!json) {
      return locationOwner;
    }

    locationOwner.id = json.id;
    locationOwner.organization = json.organization;
    locationOwner.createdAt = json.createdAt;
    locationOwner.lastModified = json.lastModified;
    locationOwner.ownerOrganization = json.ownerOrganization;
    locationOwner.name = json.name;

    return locationOwner;
  }

  toJson(locationOwner: LocationOwner): any {
    const json: { [key: string]: any } = {
      id: locationOwner.id,
      organization: locationOwner.organization,
      createdAt: locationOwner.createdAt,
      lastModified: locationOwner.lastModified,
      ownerOrganization: locationOwner.ownerOrganization,
      name: locationOwner.name
    };

    for (const property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
