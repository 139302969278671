import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';

import shortid = require('shortid');
import {
  NotificationsService,
  Notification,
  NotificationType,
} from '../notification/notifications.service';
import { AttachmentService } from '../../attachments/attachment.service';
import { Attachment } from '../../attachments/attachment';

// constants
import { TRIPTICKETIMAGETYPE } from '../../app.constants';

@Component({
  selector: 'image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent {
  @ViewChild('imageEditor', { static: false }) imageEditor!: CropperComponent;
  @Input() id!: string;
  @Input() imageUrl!: string;
  @Input() external = false;
  @Input() attachment!: Attachment;
  @Input() newTicket = false;
  /* eslint-disable @angular-eslint/no-output-on-prefix */
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<string> = new EventEmitter();
  @Output() onImageClick: EventEmitter<any> = new EventEmitter();
  loading = false;
  error = false;

  config = {
    autoCrop: false,
    autoCropArea: 1,
    checkOrientation: false,
    background: false,
    modal: false,
    guides: false,
    center: false,
    highlight: false,
    movable: false,
    scalable: false,
    zoomable: false,
    viewMode: 0,
    strict: false,
    dragCrop: false,
    rotatable: true,
    cropBoxMovable: false,
    cropBoxResizable: false,
    responsive: true,
  };

  constructor(
    private attachmentService: AttachmentService,
    public notificationsService: NotificationsService
  ) {}

  // ngOnChanges() {
  //   if (this.imageEditor && this.imageEditor.cropper) {
  //     this.imageEditor.cropper.replace(this.imageUrl).destroy();
  //   }
  // }

  imageError(): boolean {
    if (this.imageEditor && !this.error) {
      this.error = this.imageEditor.loadError;
      if (this.error) {
        this.pushError();
      }
    }
    return this.error;
  }

  editableImage(): boolean {
    return (
      !this.imageError() &&
      this.imageUrl.match(/.(jpg|jpeg|png|gif|base64)/i) !== null &&
      this.imageUrl.includes('amazonaws') &&
      !this.imageUrl.includes('twilio')
    );
  }

  save(e?: any) {
    this.loading = true;
    const reader = new FileReader();
    let imageFile: any;
    if (e) {
      imageFile = e.target.files[0];
      reader.readAsDataURL(imageFile);
      reader.onload = () => {
        this.saveImageData(imageFile.name, reader.result);
      };
    } else {
      const blob = this.imageEditor.cropper
        .getCroppedCanvas()
        .toDataURL('image/jpeg');
      let imageName = 'edited.jpg';
      if (this.imageUrl && this.imageUrl.length) {
        imageName = this.imageUrl.split('/').pop() || imageName;
        if (imageName.includes('?')) {
          imageName = this.imageUrl.split('?').shift() || 'edited.jpg';
        }
      }
      this.saveImageData(imageName, blob);
    }
  }

  delete() {
    this.attachmentService.remove(this.attachment).subscribe(() => {
      this.onDelete.emit(this.attachment.id);
    });
  }

  saveImageData(imageName: string, blob: any) {
    const file = this.getFile(imageName, blob);
    this.attachmentService.save(file).subscribe((attachment: Attachment) => {
      if (this.imageEditor && this.imageEditor.cropper) {
        this.imageEditor.cropper.replace(
          (this.imageUrl = String(attachment.file))
        );
      }
      this.onSave.emit(attachment);
      this.loading = false;
    });
  }

  getFile(name: string, file: any) {
    const fileExtension = name.split('.').pop();
    return {
      ...(this.attachment && this.attachment.id && { id: this.attachment.id }),
      name: `${this.attachmentService.generateUUID()}.${fileExtension}`,
      fileFormat: 'image',
      fileExtension,
      fileType: TRIPTICKETIMAGETYPE,
      file,
      tickets: [this.id],
    };
  }

  rotate(degrees: number) {
    this.imageEditor.cropper.rotate(degrees);
  }

  imageClick() {
    this.onImageClick.emit(true);
  }

  pushError() {
    const notification: Notification = {
      context: {},
      id: shortid.generate(),
      message:
        'An error occured loading this image in the editor. Please reload and try again.',
      originator: 'image-editor',
      type: NotificationType.Danger,
    };

    this.notificationsService.addNotification(notification);
  }
}
