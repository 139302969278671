<!-- <div class="ruckit-image-cell">
  <img *ngIf="value" class="thumbnail" [src]="value"/>
  <div *ngIf="!value && !this.readonly" class="upload-image-dropzone">
    
    <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
    <label class="upload-label" for="file" translate>Upload Image</label>
    <i class="icon-no-results"></i>
  </div>
</div> -->
<span *ngIf="value" class="icon-ticket">
                
</span>