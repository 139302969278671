import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { GridApi, INoRowsOverlayParams } from 'ag-grid-community';

type NoResultsParams = INoRowsOverlayParams & { api?: GridApi; onClick?: () => void; type?: string; readOnly?: boolean };

@Component({
  selector: 'no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent implements INoRowsOverlayAngularComp {
  type = 'items';
  onClick?: () => void;
  showButton = false;
  readOnly = false;

  params?: NoResultsParams = {} as NoResultsParams;

  constructor() { }

  agInit(params: NoResultsParams): void {
    this.params = params;
    this.readOnly = !!params.readOnly;
    if (this.params.onClick) {
      this.onClick = this.params.onClick;
    }

    if (this.params.type) {
      this.type = this.params.type;
    }
  }
}
