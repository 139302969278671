<div class="sidebar-ticket-image">
  <ng-container *ngIf="attachments && attachments.length > 0; else noImage">
    <button
      translate
      class="edit"
      *ngIf="!editing && !readOnly"
      (click)="editing = true"
    >
      Edit Image(s)
    </button>

    <!-- ticket images -->
    <ng-container
      *ngFor="
        let image of attachments | filterArray: 'fileType':TICKETIMAGETYPE
      "
    >
      <label class="image-label" translate>Ticket Image(s)</label>
      <img *ngIf="!editing" [src]="image.file" />
      <image-editor
        *ngIf="editing"
        [id]="ticket.id"
        [imageUrl]="image.file"
        [attachment]="image"
        (onSave)="onSave($event, image.id)"
        (onDelete)="onDelete($event)"
      ></image-editor>
    </ng-container>

    <!-- signature images -->
    <ng-container
      *ngFor="
        let image of attachments | filterArray: 'fileType':SIGNATUREIMAGETYPE
      "
    >
      <label class="image-label-signature" translate>Signature Image(s)</label>
      <img *ngIf="!editing" [src]="image.file" />
      <image-editor
        *ngIf="editing"
        [id]="ticket.id"
        [imageUrl]="image.file"
        [attachment]="image"
        (onSave)="onSave($event, image.id)"
        (onDelete)="onDelete($event)"
      ></image-editor>
    </ng-container>
  </ng-container>

  <ng-template #noImage>
    <p class="no-image" translate>No Image Found</p>
  </ng-template>

  <div class="upload-image-container">
    <input
      type="file"
      name="upload-file"
      id="upload-file"
      class="upload-file"
      (change)="onFileAdd($event)"
      #fileInput
    />
    <label class="upload-label" for="upload-file" translate>Upload Image</label>
  </div>
</div>
