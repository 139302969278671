import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/* eslint-disable @typescript-eslint/naming-convention */
export enum SyncOption {
  All = 'All',
  Selected = 'Selected',
}

type DialogData = { selected: boolean };

type SyncCallback = (syncOption: SyncOption) => void;

@Component({
  selector: 'sync-modal',
  templateUrl: './sync-modal.component.html',
  styleUrls: ['./sync-modal.component.scss'],
})
export class SyncModalComponent implements OnInit {
  public syncOptions = SyncOption;
  syncOption = SyncOption.All;

  constructor(
    public dialogRef: MatDialogRef<SyncModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public callback: SyncCallback = () => {};

  ngOnInit() {
    if (this.data.selected) {
      this.syncOption = SyncOption.Selected;
    }
  }

  onSubmit() {
    this.callback(this.syncOption);
    this.dialogRef.close();
  }
}
