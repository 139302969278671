import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Decimal } from 'decimal.js';

import { Ocr, OcrResults, TextAnnotation } from './ocr';
import { handleError } from '../shared/api.service';
import { OcrSerializer } from './ocr.serializer';

import { AuthenticationService } from '../shared/authentication.service';
import { Ticket } from '../tickets';
import { isEmpty } from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';

type WeightResults = {
  weightDetected: boolean
  gross: number
  tare: number
  net: number
};

@Injectable()
export class OcrService {
  baseUrl = 'https://sprout-api-staging.herokuapp.com/';
  user = this.authenticationService.user();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  process(body: any, query: any = null) {
    const ticketUrl = this.baseUrl + 'tickets.json';

    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }

    return this.http.post(ticketUrl, body, {}).pipe(
      map(data => this.convertRecord(data)),
      catchError((res: Response) => handleError(res))
    );
  }

  getResults(ticket: Ticket, external = false): Promise<OcrResults> {
    return new Promise((resolve) => {
      if (ticket.ticketMetas && ticket.ticketMetas[0]) {
        if (ticket.ticketMetas[0].ticketData && !isEmpty(ticket.ticketMetas[0].ticketData)) {
          resolve(ticket.ticketMetas[0].ticketData as OcrResults);
        } else {
          resolve(this.detectTicketData(ticket.ticketMetas[0].serviceData, external));
        }
      } else {
        this.process({
          ticket: {
            identifier: ticket.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            remote_attachment_url: ticket.image
          }
        }).subscribe((results: Ocr) => {
          resolve(this.detectTicketData(results.textAnnotations, external));
        }, err => {
          resolve({} as OcrResults);
          throw(err);
        });
      }
    });
  }

  detectTicketData(textAnnotations: TextAnnotation[], external = false): OcrResults {
    // resolved data object with defaults
    const detectedData: OcrResults = {
      ticketNumber: '',
      quantity: '',
      ticketDate: ''
    };
    if (textAnnotations) {
      const orgFlags = {
        isPTP: false,
        isMunoz: false,
        isBenitez: false
      };
      if (this.user && this.user.organization) {
        orgFlags.isPTP = this.user.organization.name.includes('PTP Integration') || external;
        orgFlags.isMunoz = this.user.organization.name.includes('Munoz');
        orgFlags.isBenitez = this.user.organization.name.includes('Benitez');
      }
      // setting up a flag for specific ticket types
      // NOTE, the yantis rule s meant to exclude the marietta tickets yantis sometimes gets from suppliers
      // the phone number rules are in place of names where the logos are hard to detect or are often incorrect
      const fullTextResult = textAnnotations[0] && textAnnotations[0].description ? textAnnotations[0].description.toUpperCase() : '';
      const typeFlags = {
        isYantis: fullTextResult.includes('YANTIS COMPANY') &&
                  !fullTextResult.includes('MARTIN MARIETTA') &&
                  !fullTextResult.includes('VULCAN CONSTRUCTION MATERIALS') &&
                  !fullTextResult.includes('MUNOZ TRUCKING'),
        isCowboy: fullTextResult.includes('COWBOY TRUCKING'),
        isTripleB: fullTextResult.includes('TRIPLE B'),
        isCapitolAgg: fullTextResult.includes('CAPITOL AGGREGATES'),
        isGuidoTrucks: fullTextResult.includes('GUIDO TRUCKS'),
        isHanson: fullTextResult.includes('HANSON'),
        isVulcan: fullTextResult.includes('VULCAN CONSTRUCTION MATERIALS'),
        isTarango: fullTextResult.includes('214-973-0640'),
        isLindamood: fullTextResult.includes('LINDAMOOD'),
        isSilvas: fullTextResult.includes('817-653-7613'),
        isJDandSons: fullTextResult.includes('JD AND SON TRUCKING ENTERPRISES LLC') ||
                     fullTextResult.includes('JORGE DAVILA TRUCKING')
      };

      let weightDetectionFields: WeightResults = { weightDetected: false } as WeightResults;

      textAnnotations.forEach((annotation: TextAnnotation, i: number) => {
        if (i > 0) {
          detectedData.ticketNumber = this.detectTicketNumber(detectedData.ticketNumber, annotation,
                                      textAnnotations, i, typeFlags, orgFlags);
          detectedData.ticketDate = this.detectDate(detectedData.ticketDate, annotation, textAnnotations, i);
          weightDetectionFields = this.detectWeight(weightDetectionFields, annotation, textAnnotations, i, typeFlags);
          const weightVerified: boolean = weightDetectionFields.net > 0 &&
          weightDetectionFields.net === weightDetectionFields.gross - weightDetectionFields.tare;
          if (weightVerified) {
            detectedData.quantity = Decimal.mul(weightDetectionFields.net, 0.0005).toFixed(2);
          }
        }
      });
    }
    return detectedData;
  }

  private detectTicketNumber(currentDetection: string, selectedAnnotation: TextAnnotation,
                             annotationsList: TextAnnotation[], index: number, typeFlags: any, orgFlags: any): string {
    const resultText = selectedAnnotation.description;
    // breaking this out so we arent looking for this fields existence in the numbr setting logic
    const previousText = annotationsList[index - 1] && annotationsList[index - 1].description;
    let hasType = false;
    for (const i in typeFlags) {
      if (typeFlags[i] === true) {
        hasType = true;
      }
    }
    if (/^\d+$/.test(resultText)) {
      if (resultText.length > 5 && resultText.length <= 12 && previousText !== 'BOX' && !hasType) {
        // if result text is a number and between 6 and 12 digits,
        // and it it not detected immediately after a PO BOX string,
        // and the ticket is not detected to be yantic or triple b,
        // set it as the ticket number if one has not previously been set
        // otherwise defer to a previously set number
        currentDetection = currentDetection.length === 0 ? resultText : currentDetection;
      } else if (typeFlags.isJDandSons && (resultText.length === 5 || resultText.length === 6) &&
                 ['703517', '110358', '75370', '75011'].indexOf(resultText) === -1) {
        // if the type flag for JD and sons is true, and the result text is either 5 or 6 digits long,
        // and is not the zip code or PO box for eithe of the JD and Sons plants, set that as the detected number,
        // if one has not been set already
        currentDetection = currentDetection.length === 0 ? resultText : currentDetection;
      } else if (typeFlags.isTarango && resultText.length >= 6 && resultText.length <= 12) {
        currentDetection = currentDetection.length === 0 ? resultText.substring(0, 6) : currentDetection;
      } else if ((typeFlags.isCapitolAgg && resultText.length === 8) ||
                 (typeFlags.isHanson && resultText.length === 10) ||
                 (typeFlags.isVulcan && resultText.length === 8)) {
        currentDetection = resultText;
      } else if (typeFlags.isCowboy && 0 < index && index < 10 && resultText.length > 4) {
        // if the ticket is detected as a cowboy ticket, the index is between 1 - 10,
        // the length of the text is greater than 4, is a number, set the detection as that object
        currentDetection = currentDetection.length === 0 || resultText.length === 6 ? resultText : currentDetection;
      } else if (typeFlags.isGuidoTrucks && 0 < index && index < 32 && resultText.length > 3 &&
                 !resultText.includes('0810') && !resultText.includes('7506') && !resultText.includes('1800')) {
        // if the ticket is detected as a guido ticket, the index is between 1 - 29,
        // the length of the text is greater than 4, is a number, and is not a number
        // from the guido address, set the detection as that object
        currentDetection = currentDetection.length === 0 ? resultText : currentDetection;
      } else if (resultText.length === 5) {
        if (orgFlags.isMunoz || orgFlags.isPTP) {
          if ((typeFlags.isYantis && !resultText.includes('78247')) ||
              (typeFlags.isSilvas && !resultText.includes('76112')) ||
              (typeFlags.isLindamood && !resultText.includes('75060'))) {
            // if result text is a number, 5 digits long, is detected as a yantis ticket, and is not the yantis area code,
            // set it as the ticket number if one has not previously been set,
            // otherwise defer to a previously set number (NOTE: this is a very loose rule that should be removed sooner than later)
            currentDetection = currentDetection.length === 0 ? resultText : currentDetection;
          } else if ([2, 21, 22, 23, 24, 31, 32, 33, 34].indexOf(index) > -1) {
            // if result text is a number, 5 digits long, and falls in one of these
            // specific array indices, set it as the ticket number if one has not previously been set,
            // otherwise defer to a previously set number (NOTE: this is a very loose rule that should be removed sooner than later)
            currentDetection = currentDetection.length === 0 ? resultText : currentDetection;
          }
        } else if (orgFlags.isBenitez || orgFlags.isPTP) {
          if (typeFlags.isTripleB || index === annotationsList.length - 1 || index === annotationsList.length - 2) {
            // if result text is a number, 5 digits long, is a triple b ticket or is either the second to last or
            // last text detected in a ticket, set it as the ticket number, regardless if one has been set already
            // (NOTE: this is another loose rule for Benitez that should be removed sooner than later)
            currentDetection = resultText;
          }
        }
      }
    } else if (resultText.includes('TICKET') && resultText.length > 8) {
      // if result text is not a number, at least 9 digits long, and contains the text TICKET in it,
      // set it as the ticket number if one has not previously been set
      // (NOTE: this is another loose rule that should be removed sooner than later)
      currentDetection = currentDetection.length === 0 ? resultText.substring(6) : currentDetection;
    } else if (/^[R]{1}\d{7}$/.test(resultText) && (orgFlags.isMunoz || orgFlags.isPTP)) {
      // if the result text matches the following patter: 'R' followed by 7 digits,
      // and the organization using ticket manager is Munoz, use that ticket number
      // (NOTE: this rule is specifically made for Munoz and should be consolidated once classification is in place)
      currentDetection = resultText;
    } else if (typeFlags.isGuidoTrucks && 0 < index && index < 30 && resultText.length > 5 &&
               resultText.startsWith('NO') || resultText.startsWith('N°')) {
      // if the ticket is detected as a guido ticket, the index is between 1 - 29,
      // the length of the text is greater than 5, and starts with either 'NO' or 'N°',
      // set the detection as that object
      currentDetection = resultText.substring(2);
    }
    return currentDetection;
  }

  private detectDate(currentDetection: string, selectedAnnotation: TextAnnotation,
                     annotationsList: TextAnnotation[], index: number): string {
    const resultText = selectedAnnotation.description;
    // date detection logic
    // if the detected text includes 'Date' or 'Oate' (the latter being a common detection for that word)
    // and we detect text annotations 5 or 6 positions away, we can guess that this could be a potential date
    if (!currentDetection && [ 'date', 'oate' ].indexOf(resultText.toLowerCase()) > -1 && annotationsList.length > 8 &&
        (annotationsList[index + 5] || annotationsList[index + 6])) {
      let month; let day; let year; let monthPos; let dayPos; let yearPos;
      // if we detect that the next OCR text is ':', and that the lengths of the date fields
      // are correct (2 for day/month, 4 for year), then we will set those at the index positions
      if (annotationsList[index + 1].description === ':' &&
          annotationsList[index + 2].description.length <= 2 &&
          annotationsList[index + 4].description.length <= 2 &&
          annotationsList[index + 6].description.length <= 4) {
        monthPos = 2; dayPos = 4; yearPos = 6;
      // otherwise if the VERY next texts are in the right correcposding length pattern,
      // we will use those as the date text position
      } else if (annotationsList[index + 1].description.length <= 2 &&
                 annotationsList[index + 3].description.length <= 2 &&
                 annotationsList[index + 5].description.length <= 4) {
        monthPos = 1; dayPos = 3; yearPos = 5;
      }
      if (monthPos && dayPos && yearPos) {
        // here we set up the correct month.
        // if we detect that the number is two digits long and is greater than 12,
        // we can determine that the month should be modified
        month = annotationsList[index + monthPos].description.length === 2 &&
                Number(annotationsList[index + monthPos].description) > 12 ?
                '1' + annotationsList[index + monthPos].description.substring(1) :
                annotationsList[index + monthPos].description;
        day = annotationsList[index + dayPos].description;
        // if the year is detected to be two digits long, append a 20 to the front
        year = annotationsList[index + yearPos].description.length === 2 ?
              '20' + annotationsList[index + yearPos].description : annotationsList[index + yearPos].description;
        if (/^\d+$/.test(month) && Number(month) > 0 &&
            /^\d+$/.test(day) && Number(day) > 0 && Number(day) <= 31 &&
            /^\d+$/.test(year) && Number(year) > 0) {
          // lastly, after determining that the dected texts are indeed all valid numbers,
          // we check that the date constructed is both less than or equal to todays date,
          // and is no less than two weeks prior
          const constructedDate = month + '-' + day + '-' + year;
          const today = moment();
          const oneMonthAgo = moment().clone().subtract(1, 'months').startOf('day');
          currentDetection = moment(constructedDate, 'MM-DD-YYYY').isSameOrBefore(today) &&
                             moment(constructedDate, 'MM-DD-YYYY').isSameOrAfter(oneMonthAgo) ?
                             constructedDate : '';

        }
      }
    }
    return currentDetection;
  }

  private detectWeight(weightDetectionFields: WeightResults, selectedAnnotation: TextAnnotation,
                       annotationsList: TextAnnotation[], index: number, typeFlags: any): WeightResults {
    const resultText = selectedAnnotation.description;
    // weight detection logic
    // NOTE: for now we only try to detect pounds so that our validation rules
    // are a bit stricter around the numbers we detect
    if (resultText.toUpperCase().includes('GROSS') && index !== 0) {
      // here we first set a flag if we ever detect the term 'GROSS' in the text results of the ticket
      // if so, we'll set the detectedFlag to true, and THEN look for amounts that will corresppond
      // with the typical gross/tare/net amounts
      weightDetectionFields.weightDetected = typeFlags.isVulcan ? index > 400 : true;
    }
    if (weightDetectionFields.weightDetected && /^\d+$/.test(resultText)) {
      // first we detect if we detect a ticket will contain weights,
      // and that the current detected text is a valid number
      if (resultText.length === 5) {
        // if the number if 5 digits long (a typical weight in pounds),
        // we first set the gross, then if that is set, the tare,
        // then if that is set, the net
        if (weightDetectionFields.tare) {
          weightDetectionFields.net = weightDetectionFields.net ? weightDetectionFields.net : Number(resultText);
        }
        if (weightDetectionFields.gross) {
          weightDetectionFields.tare = weightDetectionFields.tare ? weightDetectionFields.tare : Number(resultText);
        }
        weightDetectionFields.gross = weightDetectionFields.gross ? weightDetectionFields.gross : Number(resultText);
      } else if (resultText.length === 2) {
        // if the number if 2 digits long (the first two digits if the format is 00,000),
        // we first construct the number if the value after next
        // is also a number and exactly 3 digits long,
        // then we set the gross, then if that is set, the tare,
        // then if that is set, the net
        if (annotationsList[index + 2] &&
            /^\d+$/.test(annotationsList[index + 2].description) &&
            annotationsList[index + 2].description.length === 3) {
          const constructedAmt = resultText + annotationsList[index + 2].description;
          if (constructedAmt.length === 5) {
            if (weightDetectionFields.tare) {
              weightDetectionFields.net = weightDetectionFields.net ? weightDetectionFields.net : Number(constructedAmt);
            }
            if (weightDetectionFields.gross) {
              weightDetectionFields.tare = weightDetectionFields.tare ? weightDetectionFields.tare : Number(constructedAmt);
            }
            weightDetectionFields.gross = weightDetectionFields.gross ? weightDetectionFields.gross : Number(constructedAmt);
          }
        }
      }
    }
    return weightDetectionFields;
  }

  private convertRecord(data: any): Ocr {
    try {
      data = data;
    } catch (err) {
      // Ignore
    }

    return new OcrSerializer().fromJson(data);
  }
}
