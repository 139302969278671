<batch-upload-notification-bar></batch-upload-notification-bar>
<div class="container">
  <div class="upload-images">
    <div class="upload-image-dropzone" [ngClass]="{filled: savedTickets.length}">
      <div *ngIf="loader.active" class="loader-container">
        <my-loader></my-loader>
        <p *ngIf="loader.type === 'uploading'" translate>Uploading Ticket Images</p>
        <p *ngIf="loader.type === 'analyzing'" translate>{{ savedTickets.length }}/{{ uploadTotal }} Tickets Analyzed
        </p>
      </div>
      <ng-container *ngIf="savedTickets.length && selectedRecordIndex === null">
        <div *ngFor="let ticket of savedTickets | sort: 'uploadIndex'; let i = index"
          class="image-container single">
          <image-editor *ngIf="ticket.image" [id]="ticket.id" [imageUrl]="ticket.image" (onSave)="saveImage($event)"></image-editor>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedRecordIndex !== null">
        <div class="image-container single">
          <image-editor *ngIf="savedTickets[selectedRecordIndex] && savedTickets[selectedRecordIndex].image"
            [id]="savedTickets[selectedRecordIndex].id" [imageUrl]="savedTickets[selectedRecordIndex].image"
            (onSave)="saveImage($event)"></image-editor>
        </div>
      </ng-container>
      <div class="viewer-controls" [ngClass]="{'has-images': savedTickets.length}">
        <div *ngIf="savedTickets.length" class="left-controls">
          <ng-container *ngIf="savedTickets.length > 1 && !external">
            <mat-icon class="active">insert_photo</mat-icon>
          </ng-container>
          <p>TICKET {{ selectedRecordIndex + 1 }} OF {{ savedTickets.length }}</p>
        </div>
        <input type="file" multiple name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput />
        <label class="upload-label" for="file" *ngIf="!savedTickets.length">
          <i class="icon-upload"></i>
          <span translate>Drag and drop or choose images to upload.</span>
        </label>
        <button class="btn btn-secondary upload" *ngIf="!savedTickets.length">CHOOSE IMAGES</button>
      </div>
    </div>
  </div>
</div>
