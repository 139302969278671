import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from './../shared/shared.module';

import { SettingsComponent } from './settings.component';
import { SettingsOrganizationComponent } from './settings-organization/settings-organization.component';
import { SettingsIntegrationsComponent } from './settings-integrations/settings-integrations.component';
import { OrganizationService } from '../organizations/organization.service';
import { QuickbooksCompanyService } from '../integrations/quickbooks/quickbooks.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    SettingsComponent,
    SettingsOrganizationComponent,
    SettingsIntegrationsComponent
  ],
  providers: [
    OrganizationService,
    QuickbooksCompanyService
  ],
  exports: [
    SettingsComponent,
    SettingsOrganizationComponent,
    SettingsIntegrationsComponent
  ],
})
export class SettingsModule { }
