import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'status-cell',
  template: `
    <div class="ticket-status" [ngClass]="{
      'success': value === statusList.successValue,
      'failure': value === statusList.failureValue,
      'pending': value === statusList.pendingValue
    }">
      {{ value | titlecase | translate }}
    </div>
  `,
  styles: [`
    .ticket-status { font-weight: 700; }
    .success { color: #00c852; }
    .failure { color: #b00120; }
    .pending { color: #f5a623; }
  `],
})
export class StatusCellComponent implements AgRendererComponent {
  value!: string;
  statusList!: {
    pendingValue: string
    successValue: string
    failureValue: string
  };

  constructor() { }

  agInit(params: any): void {
    this.value = params.value;
    this.statusList = params.statusList;
  }

  refresh(): boolean {
    return false;
  }

  getValue(): string {
    return this.value;
  }
}
