const camelcaseKeysDeep = require('camelcase-keys-deep');

export class TicketFilter {
  id: string;
  name: string;

  constructor(filterInfo: any) {
    filterInfo = camelcaseKeysDeep(filterInfo);

    this.id = filterInfo.id;
    this.name = filterInfo.name;
  }
}
