import { Resource } from '../../shared/resource';

export class DriverReference extends Resource {
  id!: string;
  value = '';
  createdAt?: string;
  organization?: string;
  driverCode?: string;
  driverName?: string;
  truckNumber?: string;
  truckType?: string;
  carrierName?: string;
  email?: string;
  phoneNumber?: string;
  cdl?: string;
}
