import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { DriverReference } from '../driver-reference';
import { DriverReferenceService } from '../driver-reference.service';

type ExcludedFields = (
  'id'
  | 'createdAt'
  | 'classes'
  | 'loading'
  | 'selected'
  | 'filterOptions'
  | 'organization'
  | 'truckNumber'
  | 'truckType'
);
export type DriverReferenceFormModel = Pick<DriverReference, Exclude<keyof DriverReference, ExcludedFields>>;
export type CreateDriverReferenceCallback = (response$: Observable<DriverReference>) => void;
export type DriverReferenceDialogData = {
  data: {
    model: DriverReferenceFormModel
    mode: string
  }
  width: string
};

@Component({
  selector: 'driver-reference-create-modal',
  templateUrl: './driver-reference-create-modal.component.html',
  styleUrls: ['./driver-reference-create-modal.component.scss'],
  providers: [DriverReferenceService],
})
export class DriverReferenceCreateModalComponent implements OnInit {
  referenceForm = this.formBuilder.group({
    driverCode: [''],
    driverName: [''],
    carrierName: [''],
    email: [''],
    phoneNumber: [''],
    cdl: [''],
  });

  mode = 'create';

  public callback!: CreateDriverReferenceCallback;

  constructor(
    private formBuilder: FormBuilder,
    private driverReferenceService: DriverReferenceService,
    public dialogRef: MatDialogRef<DriverReferenceCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DriverReferenceFormModel,
  ) { }


  ngOnInit() {
    if (this.data && this.data.model) {
      this.setFormFieldData(this.data.model);
    }

    this.mode = this.data && this.data.mode;
  }

  onSubmit() {
    let value: DriverReferenceFormModel = this.referenceForm.value;

    if (this.data && this.data.model) {
      value = { ...this.data.model, ...this.referenceForm.value };
    }

    this.callback(this.driverReferenceService.save(value));

    this.dialogRef.close();
  }

  setFormFieldData(data: DriverReferenceFormModel): void {
    this.referenceForm.patchValue(data);
  }
}
