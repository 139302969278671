import { ReportService } from '../report/report.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SettlementService extends ReportService {
  filters = {
    type: {
      filterType: 'field',
      values: ['expense'],
    }
  };
}
