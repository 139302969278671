import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TruckReferenceService } from '../truck-reference.service';
import { TruckReference } from '../truck-reference';
import {
  TruckReferenceFormModel,
  TruckReferenceDialogData,
  TruckReferenceCreateModalComponent
} from '../truck-reference-create-modal/truck-reference-create-modal.component';

@Component({
  selector: 'truck-reference-selector',
  templateUrl: './truck-reference-selector.component.html',
  styleUrls: ['./truck-reference-selector.component.scss'],
  providers: [TruckReferenceService],
})
export class TruckReferenceSelectorComponent implements OnInit {
  @Input() visible = true;
  @Input() placeholder = 'Truck #';
  @Input() value = '';
  @Input() data!: TruckReferenceFormModel;
  @Input() truckReference?: TruckReference;
  @Input() hasControls = true;

  @Output() referenceFocused: EventEmitter<boolean> = new EventEmitter();
  @Output() referenceSelected: EventEmitter<TruckReference> = new EventEmitter();
  @Output() referenceCreated: EventEmitter<TruckReference> = new EventEmitter();
  /* eslint-disable @angular-eslint/no-output-on-prefix */
  @Output() onBlur: EventEmitter<boolean> = new EventEmitter();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('truckReferenceInput', { static: true, read: ViewContainerRef }) truckReferenceInput: any;

  selectedTruckReference?: TruckReference;
  loading = true;
  scrollDistance = 5;
  scrollUpDistance = 5;
  throttle = 100;
  query = '';
  editing = false;

  references: TruckReference[] = [];

  constructor(
    private truckReferenceCreateModal: MatDialog,
    private truckReferenceService: TruckReferenceService,
  ) { }

  ngOnInit() {
    this.query = this.value;

    this.truckReferenceService.list(this.buildQueryString(this.query)).subscribe(references => {
      this.references = references;
      this.loading = false;
    });
  }

  public setSelectedReference(reference: TruckReference): void {
    this.selectedTruckReference = reference;
    this.value = reference.truckNumber ? reference.truckNumber : '';
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  buildQueryString(query = ''): { filters?: string; page_size?: number } {
    if (!query || query === '') {
      return {
        page_size: 10,
      };
    }

    return {
      filters: `(truck_number__icontains=${query}) | (truck_number__icontains=${query})`,
      page_size: 10,
    };
  }

  onInputFocus(): void {
    this.visible = true;
    this.loading = true;

    this.references = [];

    this.referenceFocused.emit(true);

    this.truckReferenceService.list(this.buildQueryString(this.query)).subscribe(references => {
      this.references = references;
      this.loading = false;
    });
  }

  onInputBlur(): void {
    this.visible = false;
    this.references = [];
    this.onBlur.emit(true);

    if (this.selectedTruckReference) {
      if (this.selectedTruckReference.truckNumber && this.selectedTruckReference.truckNumber !== this.value) {
        this.value = this.selectedTruckReference.truckNumber;
      }
    } else {
      this.value = '';
    }
  }

  onReferenceClicked(event: any, truckReference: TruckReference): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.referenceSelected.emit(truckReference);
    this.visible = false;

    this.selectedTruckReference = truckReference;

    this.value = truckReference.truckNumber ? truckReference.truckNumber : '';
  }

  onSearchChange(query: string): void {
    query = query.toUpperCase();

    this.loading = true;
    this.references = [];

    this.truckReferenceService.list(this.buildQueryString(query)).subscribe(results => {
      this.references = results;
      this.loading = false;
    });
  }

  onCloseClick(): void {
    this.visible = false;
    this.onClose.emit(true);
    this.references = [];
  }

  onAddClick(): void {
    const data: TruckReferenceDialogData = {
      width: '500px',
      data: {
        model: this.data,
        mode: 'create',
      }
    };

    const dialog = this.truckReferenceCreateModal.open(TruckReferenceCreateModalComponent, data);

    dialog.componentInstance.callback = (response$: Observable<TruckReference>) => {
      response$.subscribe((truckReference) => {
        this.referenceCreated.emit(truckReference);
        this.selectedTruckReference = truckReference;

        this.visible = false;
      });
    };
  }

  onScrollDown(): void {
    const newResults = this.truckReferenceService.listNext();
    if (newResults) {
      newResults.subscribe(results => {
        this.references = [...this.references, ...results];
      });
    }
  }

  onScrollUp(): void { }

  onEditReferenceClick() {
    if (!this.selectedTruckReference) {
      return;
    }

    this.visible = false;
    this.references = [];

    const data: TruckReferenceDialogData = {
      width: '500px',
      data: {
        model: this.selectedTruckReference,
        mode: 'edit',
      },
    };

    const dialog = this.truckReferenceCreateModal.open(TruckReferenceCreateModalComponent, data);

    dialog.componentInstance.callback = (response$: Observable<TruckReference>) => {
      response$.subscribe((truckReference) => {
        this.referenceCreated.emit(truckReference);
        this.selectedTruckReference = truckReference;

        this.references.push(truckReference);

        this.visible = false;
      });
    };

    return false;
  }
}
