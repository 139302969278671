import { Reference } from '../reference';

export class CustomerReference extends Reference {
  customerCode? = '';
  customerName? = '';
  customerAddress1? = '';
  customerAddress2? = '';
  customerCity? = '';
  customerState? = '';
  customerZipcode? = '';
  customerPhoneNumber? = '';
  customerEmail? = '';
  display = 'Customer Reference';
  // eslint-disable-next-line @typescript-eslint/member-ordering
  [key: string]: any;
}
