import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InvoiceGridComponent } from './invoice-grid/invoice-grid.component';
import { SettlementService } from './settlement/settlement.service';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceService } from './invoice/invoice.service';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { ReportService } from './report/report.service';
import { SettlementGridComponent } from './settlement-grid/settlement-grid.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ArrangeModalComponent } from '../shared/arrange-modal/arrange-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
  ],
  declarations: [
    ReportBuilderComponent,
    InvoiceGridComponent,
    SettlementGridComponent,
    ArrangeModalComponent,
  ],
  providers: [SettlementService, InvoiceService, ReportService],
  // entryComponents: [
  //   ArrangeModalComponent
  // ],
  exports: [
    ReportBuilderComponent,
    InvoiceGridComponent,
    SettlementGridComponent,
  ],
})
export class ReportsModule {}
