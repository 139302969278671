import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Report } from './report';
import { ReportSerializer } from './report.serializer';
import { ResourceService } from '../../shared/resource.service';
import { requestHeaders, externalRequestHeaders } from '../../shared/api.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReportService extends ResourceService<Report> {
  baseUrl = environment.serverUrl + 'reports/';

  public search = '';
  public allSelected = false;

  constructor(http: HttpClient) {
    super(http, 'reports/', new ReportSerializer());
  }

  getStatic(id: string, token: string): Observable<Report> {
    const ticketUrl = this.baseUrl + `${id}/`;

    return this.http.get(ticketUrl, { headers: externalRequestHeaders(token) }).pipe(
      map(res => this.captureMetaData(res)),
      map(data => this.convertRecord(data)),
      catchError((res: Response) => this.handleError(res))
    );
  }

  add(id: string, ticketIds: string[] = []) {
    const ticketUrl = this.baseUrl + `${id}/add/`;
    return this.http.post(ticketUrl, {tickets: ticketIds}, { headers: requestHeaders() });
  }

  send(id: string, email: string) {
    const ticketUrl = this.baseUrl + `${id}/send/`;
    return this.http.post(ticketUrl, { to: [email] }, { headers: requestHeaders() });
  }
}
