'use strict';
import {
  Component, Input, Output, ElementRef, ViewChild, EventEmitter, HostListener,
  OnChanges, OnInit
} from '@angular/core';
import { find, groupBy, get as _get } from 'lodash';

const findIndex = (arr: any, el: Element | null) => Array.prototype.indexOf.call(arr, el);
const includes = (arr: any, el: Element | null) => findIndex(arr, el) !== -1;

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges, OnInit {
  @ViewChild('optionsEl', { static: false }) optionsElRef!: ElementRef;
  @Input() selectedOption: { [x: string]: any } | undefined;
  @Input() selectedItems = [];
  @Input() title!: string;
  @Input() icon: any;
  @Input() setTitle = true;
  @Input() changeTitle = true;
  @Input() config: any;
  @Input() options = [];
  @Input() backgroundColor = 'rgba(255, 255, 255, 0.15)';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextPage: EventEmitter<any> = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled: any;
  open = false;
  menuHeight = '0px';
  menuWidth = '100%';
  autocompleteTerm: any;
  searchTerm: any;
  keySearchTerm = '';
  searching = false;
  keyTimeout: any;
  groups = {};
  groupKeys: string[] = [];
  buttonText = '';

  constructor(private el: ElementRef, private _eref: ElementRef) {
    this.config = this.config || {};
  }

  @HostListener('document:click', ['$event']) documentClick(event: { target: any }) {
    if (!this._eref.nativeElement.contains(event.target) && this.open) {
      this.toggleMenu();
    }
  }


  ngOnInit() {
    this.config = this.config || {};
    this.config = {
      nameProperty: 'name',
      subtitleProperty: 'subtitle',
      subtitle: false,
      imageProperty: 'image',
      image: false,
      multiselect: false,
      deselectOption: null,
      selectedProp: 'selected',
      style: '',
      pin: '',
      groupBy: 'group',
      group: false,
      selectable: true,
      rightAlign: false,
      autocomplete: false,
      ...this.config
    };

    if (!Array.isArray(this.options)) {
      // eslint-disable-next-line no-throw-literal
      throw { error: 'drowpdown options must be an array.', dropdown: this };
    }
    this.setSelectedOption();
  }

  ngOnChanges() {
    if (this.config && this.config.group) {
      this.groups = groupBy(this.options, this.config.groupBy);
    }
    this.groupKeys = Object.keys(this.groups);
    // setTimeout(() => {
    //   this.setSize();
    // }, 0);
    this.setButtonText();
  }

  public setSelectedOption() {
    if (this.options && this.options.hasOwnProperty('find')) {
      if (!this.config.multiselect) {
        this.selectedOption = this.options.find((option) => option[this.config.selectedProp]) || this.selectedOption;
        this.autocompleteTerm = this.config.autocomplete && this.selectedOption ? this.selectedOption[this.config.nameProperty] : null;
      }
    }
    if (this.config.multiselect && this.selectedItems && this.selectedItems.length > 0) {
      this.selectedItems.forEach((item: any) => {
        this.options.forEach((option: any) => {
          if (option.id === item.id) {
            return option[this.config.selectedProp] = true;
          }

          return;
        });
      });
    }
  }

  onKeyPress(e: any) {
    if (
      this.el && this.el.nativeElement &&
      this.el.nativeElement.contains(document.activeElement) &&
      !this.config.autocomplete
    ) {
      if (e.code === 'Escape' && this.open) {
        this.toggleMenu();
      }
      const options = this.optionsElRef.nativeElement.children[0].children;
      if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
        if (!this.open) {
          this.toggleMenu();
        } else if (options.length) {
          const lastOption = options[options.length - 1];
          const index = findIndex(options, document.activeElement);
          if (e.code === 'ArrowDown') {
            if (!includes(options, document.activeElement)) {
              options[0].focus();
            } else if (document.activeElement !== lastOption) {
              options[index + 1].focus();
            }
          } else if (e.code === 'ArrowUp') {
            if (!includes(options, document.activeElement)) {
              lastOption.focus();
            } else if (index !== 0) {
              options[index - 1].focus();
            }
          }
        }
        e.preventDefault();
      } else if (e.key.match(/^\w$/) && e.target.nodeName.toLowerCase() !== 'input') {
        if (this.keyTimeout) {
          clearTimeout(this.keyTimeout);
        }
        this.keyTimeout = setTimeout(() => {
          this.keySearchTerm = '';
        }, 1000);
        this.keySearchTerm += e.key.toLowerCase();
        const option: any = find(options, (o: HTMLElement) => o.innerText.toLowerCase().match(RegExp('^' + this.keySearchTerm)));
        if (option) {
          option.focus();
        }
      }
    }
    setTimeout(() => {
      if (this.open && !this.el.nativeElement.contains(document.activeElement)) {
        this.toggleMenu();
      }
    }, 0);
  }
  buttonKeyPress(e: { code: string; preventDefault: () => void }) {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      this.toggleMenu();
    }
  }
  optionKeyPress(e: { code: string; preventDefault: () => void }, option: any) {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      this.toggleOption(option);
    }
  }
  toggleMenu() {
    if (!this.disabled) {
      this.open = !this.open;
    }
    this.searching = false;
    this.setSize();
  }

  setSize() {
    if (this.optionsElRef && this.optionsElRef.nativeElement) {
      const menu = this.optionsElRef.nativeElement;
      this.menuHeight = (this.open ? menu.offsetHeight : 0) + 'px';
      this.menuWidth = this.optionsElRef.nativeElement.offsetWidth + 'px';

      const rect = menu.getBoundingClientRect();
      if (rect.height + menu.parentElement.getBoundingClientRect().top > window.innerHeight) {
        menu.parentElement.style.transform = 'translateY(-' +
          (rect.height + menu.parentElement.getBoundingClientRect().top - window.innerHeight) + 'px)';
      } else if (!this.open) {
        menu.parentElement.style.transform = 'opacity(0)';
        menu.parentElement.style.transition = 'opacity 2s linear';
      } else if (this.open) {
        menu.parentElement.style.transform = 'opacity(1)';
        menu.parentElement.style.transition = 'opacity 2s linear';
      }
    }
  }

  toggleOption(option: { [x: string]: any; disabled: any }) {
    if (!option.disabled) {
      if (this.config.multiselect) {
        option[this.config.selectedProp] = !option[this.config.selectedProp];
        this.selectedItems = this.options.filter((_option) => _option[this.config.selectedProp]);
        this.onSelect.emit(this.selectedItems);
      } else {
        this.deselectAll();
        this.selectedOption = option;
        this.onSelect.emit(this.selectedOption);
        this.toggleMenu();
        this.autocompleteTerm = this.config.autocomplete ? this.selectedOption[this.config.nameProperty] : null;
      }
      this.setButtonText();
    }
  }
  isSelected(option: { [x: string]: any; button: any }) {
    if (!this.config.multiselect) {
      return !option.button && (option[this.config.selectedProp] || option === this.selectedOption);
    } else {
      return option[this.config.selectedProp];
    }
  }
  public deselectAll() {
    this.options.forEach((option: any) => {
      if (typeof option === 'object') {
        option[this.config.selectedProp] = undefined;
      }
    });
    this.selectedOption = undefined;
    if (this.config.multiselect) {
      this.selectedItems.length = 0;
      this.onSelect.emit(this.selectedItems);
    }
  }

  setButtonText() {
    this.setSelectedOption();
    if (this.config.multiselect) {
      this.buttonText = this.selectedItems.length > 0 ?
        this.optionDisplayMultiple(this.selectedItems) :
        this.title;
    } else {
      // single select
      this.buttonText = this.selectedOption ?
        this.optionDisplay(this.selectedOption) +
        (this.config.subtitle ?
          ' ' + this.optionSubtitleDisplay(this.selectedOption) : '') :
        this.title;
    }
    if (!this.setTitle) {
      this.buttonText = '';
    }
    if (!this.changeTitle) {
      this.buttonText = this.title;
    }
  }

  optionDisplay(option: any) {
    if (typeof option === 'object') {
      return _get(option, this.config.nameProperty);
    } else {
      return option;
    }
  }
  optionDisplayMultiple(items: any[]) {
    return items.map((item: any) => _get(item, this.config.nameProperty)).join(', ');
  }
  optionSubtitleDisplay(option: any) {
    if (typeof option === 'object') {
      return _get(option, this.config.subtitleProperty);
    } else {
      return option;
    }
  }
  optionImageDisplay(option: any) {
    if (typeof option === 'object') {
      return _get(option, this.config.imageProperty);
    } else {
      return option;
    }
  }

  changeSearch() {
    this.searching = true;
    this.onSearch.emit(this.searchTerm);
  }
  changeAutocomplete() {
    this.onSearch.emit(this.autocompleteTerm);
    this.deselectAll();
    this.toggleMenu();
  }
  onScroll(e: any) {
    if (!this.config.loadingOptions &&
      e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
      this.nextPage.emit();
    }

    const div = e.currentTarget;
    if (div.clientHeight + div.scrollTop + e.deltaY >= div.scrollHeight) {
      e.preventDefault();
      div.scrollTop = div.scrollHeight;
    } else if (div.scrollTop + e.deltaY <= 0) {
      e.preventDefault();
      div.scrollTop = 0;
    }
  }
  windowScroll(e: { preventDefault: () => void }) {
    if (this.open) {
      e.preventDefault();
    }
    return false;
  }
}
