// Angular 5
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/core';
import '@angular/common';
import '@angular/common/http';
import '@angular/router';

import 'rxjs';

// Other vendors for example jQuery, Lodash or Bootstrap
// You can import js, ts, css, sass, ...
import '../node_modules/timepicker/jquery.timepicker.js';
import '../node_modules/timepicker/jquery.timepicker.css';
import 'moment-duration-format';
