import { throwError as observableThrowError, Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { each, clone, find as _find, filter as _filter, uniq, isEmpty } from 'lodash';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import * as fileSaver from 'file-saver';

import { Serializer } from './serializer';
import { Resource } from './resource';
import { FilterValues } from './filter';
import { FilterValuesSerializer } from './filter.serializer';
import { IServerSideGetRowsParams } from 'ag-grid-community';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';

const decamelizeKeysDeep = require('decamelize-keys-deep');
const camelcaseKeysDeep = require('camelcase-keys-deep');

export class ResourceService<T extends Resource> {
  baseUrl = environment.serverUrl;
  appVersion = environment.applicationVersion;
  public nextUri: any;
  public previousUri: any;
  public count: any;
  public unreadCount: any;
  resourceReq!: Subscription;
  mockEndpoint = false;
  mockSearchKeys = ['name'];
  resourceUrl = '';
  search = '';
  filters: { [key: string]: any } = {};

  constructor(
    protected http: HttpClient,
    protected endpoint: string,
    protected serializer: Serializer
  ) {
    this.resourceUrl = this.baseUrl + this.endpoint;
    if (this.endpoint.includes('LOCAL:')) {
      const parts = this.endpoint.split('LOCAL:');
      if (parts) {
        this.resourceUrl = parts[parts.length - 1];
      }
    }
  }

  exportGrid(
    type: string,
    included: string[] = [],
    excluded: string[] = [],
    filters: { [key: string]: string } = {},
    search = '',
    summary = false,
    images = true,
  ): Observable<any> {
    const url = `${this.baseUrl}export/`;

    let params = new HttpParams();
    const _filters = this.buildFilters(filters);
    if (filters && !isEmpty(filters)) {
      params = params.set('filters', _filters);
    }
    if (search) {
      params = params.set('search', search);
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    const payload = {
      [type]: included,
      [`excluded_${type}`]: excluded,
      summary_only: summary,
      include_images: images,
    };

    return this.http.post(url, payload, { headers: this.requestHeaders(), params, responseType: 'text' });
  }

  exportGridLaserfiche(
    included: string[] = [],
    excluded: string[] = [],
    filters: { [key: string]: string } = {},
    search = '',
    allSelected: boolean
  ): Observable<any> {
    const url = `${this.baseUrl}pandas-export/`;

    let params = new HttpParams();
    const _filters = this.buildFilters(filters);
    if (filters && !isEmpty(filters)) {
      params = params.set('filters', _filters);
    }
    if (search) {
      params = params.set('search', search);
    }

    const payload = {
      include: included,
      exclude: excluded,
      include_images: true
    };
    if (allSelected) {
      delete payload['include'];
    }

    const headers = this.requestHeaders(undefined, {
      'X-EXPORT-FILENAME': 'ruckit-tickets',
      'X-EXPORT-ACCEPT': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    return this.http.post(url, payload, { headers, params: params, responseType: 'text' });
  }

  exportGridPandas(
    included: string[] = [],
    excluded: string[] = [],
    filters: { [key: string]: string } = {},
    search = '',
    allSelected: boolean,
    fields: string[] = [],
    includeImages: boolean = true,
    hasExportTracking: boolean = false
  ): Observable<any> {
    const url = `${this.baseUrl}pandas-export/`;
    let params = new HttpParams();
    const _filters = this.buildFilters(filters);
    let headers = this.requestHeaders();
    if (filters && !isEmpty(filters)) {
      params = params.append('filters', _filters);
    }
    if (search) {
      params = params.append('search', search);
    }

    const payload = {
      include: included,
      exclude: excluded,
      include_images: includeImages,
      mark_as_exported: '',
      fields
    };
    if (hasExportTracking) {
      payload['mark_as_exported'] = 'True';
    }
    if (!fields || !fields.length) {
      delete payload['fields'];
    } else {
      headers = this.requestHeaders(undefined, {
        'X-EXPORT-FILENAME': 'ruckit-tickets',
        'X-EXPORT-ACCEPT': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
    }
    if (allSelected) {
      delete payload['include'];
    }

    return this.http.post(url, payload, { headers, params, responseType: 'text' });
  }

  syncToTicketPro(
    include: string[] = [],
    exclude: string[] = [],
    filters: { [key: string]: string } = {},
    search = '',
    allSelected: boolean,
  ): Observable<any> {
    const url = `${this.baseUrl}ptp-sync/`;
    let params = new HttpParams();
    const _filters = this.buildFilters(filters);
    const headers = this.requestHeaders();

    if (filters && !isEmpty(filters)) {
      params = params.append('filters', _filters);
    }
    if (search) {
      params = params.append('search', search);
    }

    const payload = allSelected ? {
      exclude
    } : {
      include,
      exclude
    };

    return this.http.post(url, payload, { headers, params, responseType: 'text' });
  }

  getValuesForFieldQuery(field: string, value = ''): Observable<string[]> {
    const apiField = field.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join('_');
    const param = `${apiField}__icontains`;

    const query = { [param]: value };
    return this.list(query).pipe(
      map(tickets => tickets.map(ticket => ticket[field] ? ticket[field] : '')),
      map(fields => fields.filter(f => f !== '')),
      map(fields => uniq(fields)),
    );
  }

  list(query?: any): Observable<T[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }

    return this.http.get(this.resourceUrl, {
      headers: this.requestHeaders(),
      params
    }).pipe(
      map(res => this.captureMetaData(res)),
      map(data => this.filterLocally(data, params)),
      map(data => this.paginateLocally(data, params)),
      map(data => this.convertData(data)),
      catchError((res: HttpResponse<any>) => this.handleError(res))
    );
  }

  get(id?: string): Observable<T> {
    const resourceUrl = id ? `${this.resourceUrl}${id}/` : `${this.resourceUrl}`;

    return this.http.get(resourceUrl, {
      headers: this.requestHeaders()
    }).pipe(
      map(res => this.captureMetaData(res)),
      map(data => this.convertRecord(data)),
      catchError((res: HttpResponse<any>) => this.handleError(res))
    );
  }

  save(model: any): Observable<T> {
    const resourceUrl = this.resourceUrl;

    model = clone(model);
    model = this.serializer.toJson(model) as T;

    if (!model.id) {
      return this.http.post(resourceUrl, model, {
        headers: this.requestHeaders()
      }).pipe(
        map(res => this.convertRecord(res))
      );
    } else {
      return this.http.put(`${resourceUrl}${model.id}/`, model, {
        headers: this.requestHeaders()
      }).pipe(
        map(res => this.convertRecord(res))
      );
    }
  }

  patch(properties: any, id?: string) {
    const resourceUrl = id ? `${this.resourceUrl}${id}/` : `${this.resourceUrl}`;
    const newProperties = decamelizeKeysDeep(clone(properties));

    return this.http.patch(resourceUrl, newProperties, {
      headers: this.requestHeaders()
    }).pipe(
      map(res => camelcaseKeysDeep(res))
    );
  }

  listNext(): Observable<T[]> | null {
    if (this.nextUri) {
      return this.http.get(this.nextUri, {
        headers: this.requestHeaders()
      }).pipe(
        map(res => this.captureMetaData(res)),
        map(data => this.convertData(data)),
        catchError((res: HttpResponse<any>) => this.handleError(res))
      );
    } else {
      return null;
    }
  }

  remove(model: any) {
    const resourceUrl = this.resourceUrl;
    const id = typeof model === 'string' ? model : model.id;
    return this.http.delete(`${resourceUrl}${id}/`, {
      headers: this.requestHeaders()
    });
  }

  listFilters(slug: string, query?: any): Observable<T[]> {
    const filtersUrl = `${this.resourceUrl}${slug}/`;

    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '6');
    if (query && query['search']) {
      params = params.set('search', query['search']);
    }

    return this.http.get(filtersUrl, {
      headers: this.requestHeaders(),
      params
    }).pipe(
      map(res => this.captureMetaData(res)),
      map(res => this.convertData(res)),
      catchError((res: HttpResponse<any>) => this.handleError(res))
    );
  }

  getFilterValues(): Observable<FilterValues> {
    const filtersUrl = `${this.resourceUrl}filters/`;

    return this.http.get(filtersUrl, {
      headers: this.requestHeaders()
    }).pipe(
      map(data => this.convertFilterValues(data)),
      map(data => {
        Object.keys(data).forEach(key => {
          data[key] = data[key].filter(value => value !== '');
        });
        return data;
      }),
      catchError((res: HttpResponse<any>) => this.handleError(res))
    );
  }

  convertFilterValues(filters: any): FilterValues {
    try {
      filters = filters;
    } catch (err) {
      throw err;
    }
    return new FilterValuesSerializer().fromJson(filters);
  }

  captureMetaData(res: any): any {
    const json = res;
    this.nextUri = json.next;
    this.previousUri = json.previous;
    this.count = json['count'] || json['results'] && json['results'].length;
    this.unreadCount = json['unread_count'] || 0;
    this.mockEndpoint = json['mock'];
    this.mockSearchKeys = json['mockSearchKeys'];
    return json.results || json;
  }

  public convertData(data: any): T[] {
    return data && data.map((item: any) => this.serializer.fromJson(item));
  }

  convertRecord(data: any): T {
    try {
      data = data.json && typeof data.json === 'function' ? data.json() : data;
    } catch (err) {
      // Ignore
    }
    return this.serializer.fromJson(data) as T;
  }

  removeNulls(obj: any) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeNulls(obj[key]);
      } else if (obj[key] == null) {
        delete obj[key];
      }
    });

    return obj;
  }

  requestHeaders(xhr?: XMLHttpRequest, customHeaders: { [key: string]: any } | null = null): HttpHeaders {
    let tokenString;
    const headerObject: { [key: string]: string } = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    const user = localStorage.getItem('currentUser');
    const token = user ? JSON.parse(user).token : null;
    if (token) {
      tokenString = `Token ${token}`;
    }
    if (tokenString) {
      headerObject.Authorization = tokenString;
    }
    if (xhr && tokenString) {
      xhr.setRequestHeader('Authorization', tokenString);
    }

    if (customHeaders) {
      each(customHeaders, (value: string, header: string) => {
        if (xhr) {
          xhr.setRequestHeader(header, value);
        } else {
          headerObject[header] = value;
        }
      });
    }

    return new HttpHeaders(headerObject);
  }

  public handleError(error: HttpResponse<any> | any) {
    return observableThrowError(this.parseErrors(error));
  }


  unCamelCase(value: string): string {
    return value && value.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join('_');
  }

  /**
   * Requests rows from the server-side API for use in AG Grid tables.
   *
   * @param params {IServerSideGetRowsParams} An object containing two callbacks
   * (success and failure) and a request object with details that row the grid
   * is looking for.
   */
  getRows(params: IServerSideGetRowsParams): void {
    const { startRow, endRow, sortModel, filterModel } = params.request;
    const createOrderField = (model: any): string => {
      const { sort, colId } = model;
      const field = this.unCamelCase(colId);

      return sort === 'asc' ? field : `-${field}`;
    };

    /**
     * Generate / calculate pagination parameters
     */
    const pageSize = endRow - startRow;
    const page = Math.floor(endRow / pageSize);
    const ordering = sortModel.length > 0 ? sortModel.map(createOrderField).join(',') : '-created_at';

    /**
     * Generate request params based on pg grid server data model
     */
    let requestParams = new HttpParams();
    requestParams = requestParams.set('page_size', pageSize.toString());
    requestParams = requestParams.set('page', page.toString());
    requestParams = requestParams.set('ordering', ordering);
    requestParams = requestParams.set('search', this.search);
    if (this.resourceUrl.includes('tickets')) {
      requestParams = requestParams.set('include_ticket_metas', 'True');
    }

    /**
     * Build the query filters based on the filter model returned from AG Grid.
     */
    const combinedFilters = { ...this.filters, ...filterModel };
    if (combinedFilters.image && combinedFilters.image.values) {
      requestParams = requestParams.set('image__isempty', combinedFilters.image.values[0]);
      delete combinedFilters.image;
    }
    const filters = this.buildFilters(combinedFilters);
    if (filters) {
      requestParams = requestParams.set('filters', filters);
    }

    /**
     * If an exsiting request is in progress, unsubscribe from it to cancel the
     * request and avoid latent returns.
     */
    if (this.resourceReq && typeof this.resourceReq.unsubscribe === 'function') {
      this.resourceReq.unsubscribe();
    }

    /**
     * Request the resource with the request parameters we've built up.
     *
     * Here, we bind the request to the `resourceReq` property so that we can
     * manage and control the subscription when necessary.
     */

    // Cast to any since the gridApi property is set to private for some reason on `params.parentNode`
    // const parent = <any>params.parentNode;
    params.api.hideOverlay();

    this.resourceReq = this.http.get(this.resourceUrl, {
      headers: this.requestHeaders(),
      params: requestParams
    }).subscribe((response: any) => {
      const results = response.results;
      const count = response.count;
      const records: T[] = results.map((record: any) => this.serializer.fromJson(record));

      if (records.length <= 0) {
        params.api.showNoRowsOverlay();
      }
      params.success({ rowData: records, rowCount: count });
    }, () => {
      params.failCallback();
    });
  }

  /**
   * Processes the applied filters with consideration for the filter type,
   * format, binding operator, and multiple conditions.
   *
   * @param filterModel The set of filters to process
   *
   * @returns Conditions for use with the API's `filters` query param
   */
  buildFilters(filterModel: any): string {
    const params: string[] = [];
    const booleanColumns: string[] = ['invoiced', 'billable', 'verified', 'exported'];

    Object.keys(filterModel).forEach(key => {
      const filter = filterModel[key];
      const isBoolean = booleanColumns.includes(key);

      let name = key === 'image' ?
       `${this.unCamelCase(key)}__isempty` : isBoolean ? this.unCamelCase(key) : `${this.unCamelCase(key)}__in`;
      let values = '';

      if (filter.filterType === undefined) {
        const _params = this.processConditionalFilters(key, filter);
        const operator = filter.operator === 'AND' ? '&' : '|';
        params.push(_params.filter(Boolean).join(operator));
      } else if (filter.filterType === 'date') {
        const _params = this.buildDateFilter(key, filter);
        params.push(_params.filter(Boolean).join('|'));
      } else {
        if (filter.values.includes('isnull')) {
          values = filter.values && filter.values.join(',');
          values = values.replace(/,isnull,*/, '');
          values = values.replace(/^\,/, '');
        } else {
          values = filter.values && filter.values.join(',');
          if (values.includes('-- Blank --')) {
            values = values.replace(/-- Blank --,*/, '');
            values = values.replace(/^\,/, '');
            values = values.concat(',');
            values = values.replace(/\,{2,}/, ',');
          }
        }
        if (key === 'exported' && values === 'False') {
          name = 'unexported';
          values = 'True';
        }
        if (values) {
          if (filter.values.includes('isnull')) {
            params.push(`(${name}=${values})|(${this.unCamelCase(key)}__isnull=True)`);
          } else {
            params.push(`(${name}=${values})`);
          }
        }
      }
    });

    return params && params.length ? `${params.filter(Boolean).join('&')}` : '';
  }

  /**
   * Processes the date-type filters from the `filter` object and returns each
   * condition's parameter(s).
   *
   * A switch evaluates the `filter.type` property and determines what format
   * and modifier to use when building the query parameters.
   *
   * @param key The query key to use for the filter argument
   * @param filter The filter to process
   *
   * @returns Array of prepared query parameters
   */
  buildDateFilter(key: string, filter: any): string[] {
    const params: string[] = [];
    let name = `${this.unCamelCase(key)}`;
    const dateType = key === 'createdAt' ? 'datetime' : 'date';
    const dateFrom = filter.dateFrom && moment(filter.dateFrom, 'YYYY-MM-DD').toDate();
    let dateTo = filter.dateTo && moment(filter.dateTo, 'YYYY-MM-DD').toDate();
    let value = dateType === 'datetime' ? dateFrom.toISOString() : dateFrom;
    let valueArray: any[];
    let notEqual = false;

    switch (filter.type) {
      case 'equals':
        name = `${this.unCamelCase(key)}__range`;
        dateFrom.setHours(0, 0, 0, 0);
        dateTo = clone(dateFrom);
        dateTo.setHours(23, 59, 59, 999);
        valueArray = dateType === 'datetime' ?
          [dateFrom.toISOString(), dateTo.toISOString()] :
          [moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')];
        value = valueArray.filter(Boolean).join(',');
        break;
      case 'greaterThan':
        name = `${this.unCamelCase(key)}__gte`;
        dateFrom.setHours(0, 0, 0, 0);
        value = dateType === 'datetime' ?
          dateFrom.toISOString() : moment(dateFrom).format('YYYY-MM-DD');
        break;
      case 'lessThan':
        name = `${this.unCamelCase(key)}__lte`;
        dateFrom.setHours(23, 59, 59, 999);
        value = dateType === 'datetime' ?
          dateFrom.toISOString() : moment(dateFrom).format('YYYY-MM-DD');
        break;
      case 'notEqual':
        name = `${this.unCamelCase(key)}__range`;
        dateFrom.setHours(0, 0, 0, 0);
        dateTo = clone(dateFrom);
        dateTo.setHours(23, 59, 59, 999);
        valueArray = dateType === 'datetime' ?
          [dateFrom.toISOString(), dateTo.toISOString()] :
          [moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')];
        value = valueArray.filter(Boolean).join(',');
        notEqual = true;
        break;
      case 'inRange':
        name = `${this.unCamelCase(key)}__range`;
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 59, 999);
        valueArray = dateType === 'datetime' ?
          [dateFrom.toISOString(), dateTo.toISOString()] :
          [moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')];
        value = valueArray.filter(Boolean).join(',');
        break;
    }

    if (value) {
      params.push(`${notEqual ? '~' : ''}(${name}=${value})`);
    }

    return params;
  }

  /**
   * Processes the conditions on the `filter` object and joins each condition's
   * parameters with `&` in the event that there is more than one.
   *
   * @param key The query key to use for the filter argument
   * @param filter The filter to process
   *
   * @returns Array of prepared query parameters
   */
  processConditionalFilters(key: string, filter: any): string[] {
    const params: string[] = [];
    if (filter.condition1) {
      const param = this.buildDateFilter(key, filter.condition1).filter(Boolean).join('&');
      params.push(param);
    }
    if (filter.condition2) {
      const param = this.buildDateFilter(key, filter.condition2).filter(Boolean).join('&');
      params.push(param);
    }

    return params;
  }

  saveFile(response: any) {
    const blob = new Blob([response._body], { type: 'text/xml' });
    fileSaver.saveAs(blob, 'ruckit-ticket-manager.qwc');
  }

  private filterLocally(data: any, params: any): T[] {
    const ignoredKeys = ['ordering', 'filters', 'page', 'page_size'];
    if (this.endpoint.includes('LOCAL:') || this.mockEndpoint) {
      params.forEach((values: any, key: any) => {
        if (values && values.length && !ignoredKeys.includes(key)) {
          data = _filter(data, (o) => {
            if (o.hasOwnProperty(key)) {
              const value = values.join(',');
              const property = o[key];
              return property.toLowerCase() === value.toLowerCase();
            } else if (key === 'search') {
              let filter = false;
              for (const mockKey of this.mockSearchKeys) {
                if (o.hasOwnProperty(mockKey)) {
                  const value = values.join(',');
                  const property = o[mockKey];
                  filter = property.toLowerCase() === value.toLowerCase();
                  if (filter) {
                    break;
                  }
                }
              }
              return filter;
            } else {
              return true;
            }
          });
        } else if (values && values.length && key === 'filters') {
          const _values = clone(values);
          _values.filter(Boolean).forEach((value: any) => {
            value = value.replace(/\(|\)/g, '');
            const [_key, _value] = value.split('=');
            data = _filter(data, (o) => {
              if (o.hasOwnProperty(_key)) {
                const property = o[_key];
                return property.toLowerCase() === _value.toLowerCase();
              }

              return;
            });
          });
        }
      });
      this.count = data.length;
    }

    return data;
  }

  private paginateLocally(data: any, params: any): T[] {
    if (this.endpoint.includes('LOCAL:') || this.mockEndpoint) {
      this.count = clone(data.length);
      const _params: { [key: string]: any } = {};
      params.forEach((values: any, key: any) => {
        if (values && values.length) {
          _params[key] = values.join(',');
        }
      });
      if ((_params['page'] && _params['page'] !== '1') || !_params['page']) {
        _params['recordsToSkip'] = (_params['page'] - 1) * _params['page_size'];
      } else {
        _params['recordsToSkip'] = 0;
      }
      data = data.slice(
        _params['recordsToSkip'],
        _params['recordsToSkip'] + _params['page_size']
      );
    }

    return data;
  }

  private mergeFilters(filters: any): string {
    return filters.map((filter: any) => {
      if (filter.multiple && filter.values) {
        return filter.values.map((value: any) => {
          const _value = [filter.key, value].join('=');
          return `(${_value})`;
        }).filter(Boolean).join('|');
      } else if (filter.values) {
        let values = filter.values;
        if (values === true) {
          values = 'True';
        }
        if (values === false) {
          values = 'False';
        }
        const _value = [filter.key, values].join('=');
        return `(${_value})`;
      }
    }).filter(Boolean).join('&');
  }


  private parseErrors(err: any) {
    let errors: any[] = [];
    if (err.status >= 500) {
      errors.push(err.statusText);
    } else if (typeof err._body === 'string') {
      try {
        const body = JSON.parse(err._body);
        if (body.detail) {
          errors.push(body.detail);
        } else {
          errors = this.rescurseErrorObject(body, errors);
        }
      } catch (e) { }
    } else {
      errors.push(err);
    }
    return errors;
  }

  private rescurseErrorObject(obj: any, errors: any) {
    each(obj, (msg, key) => {
      if (Array.isArray(msg)) {
        errors = errors.concat(msg.map(err => (key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + err));
      } else if (typeof msg === 'string') {
        errors.push((key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + msg);
      } else if (typeof msg === 'object') {
        errors = this.rescurseErrorObject(msg, errors);
      }
    });
    return errors;
  }
}
