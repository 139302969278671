import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DragulaService } from 'ng2-dragula';

export class OrderData {
  id!: string;
  parent?: string | null;
  order!: number;
}

@Component({
  selector: 'arrange-modal',
  templateUrl: './arrange-modal.component.html',
  styleUrls: ['./arrange-modal.component.scss']
})
export class ArrangeModalComponent implements OnInit {
  list!: any[];
  callback: any;
  unlinkCallback: any;
  orderData!: OrderData[];

  constructor(
    public dragulaService: DragulaService,
    public dialogRef: MatDialogRef<ArrangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any[],
  ) {
    this.list = data;
    this.generateOrderData(this.list);
  }

  ngOnInit() {
    this.dragulaService.createGroup('item-list', {
      moves: (_el, _container, handle) => {
        if (!handle) {
          handle = new Element();
        }
        return handle.className === 'handle';
      }
    });
    this.dragulaService.dropModel('item-list').subscribe(({ sourceModel }) => {
      this.list = sourceModel.filter(obj => obj !== undefined);
      this.generateOrderData(this.list);
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.dragulaService.destroy('item-list');
    });
  }

  generateOrderData(list: any[]) {
    this.orderData = [];
    list.forEach((obj: any, index: number) => {
      const objOrderData: OrderData = {
        id: obj.id,
        order: index,
        parent: list[0].id !== obj.id ? list[0].id : null
      };
      this.orderData.push(objOrderData);
    });
  }

  save() {
    this.callback(this.orderData);
    this.dialogRef.close();
  }

  unlink(item: any) {
    this.list = this.list.filter(obj => obj.id !== item.id);
    this.unlinkCallback(item);
    this.generateOrderData(this.list);
  }
}
