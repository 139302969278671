import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobReference } from '../job-reference';
import { FormBuilder } from '@angular/forms';
import { JobReferenceSerializer } from '../job-reference.serializer';
import { JobReferenceService } from '../job-reference.service';
import { Observable } from 'rxjs';

type ExcludedFields = (
  'id'
  | 'createdAt'
  | 'classes'
  | 'loading'
  | 'selected'
  | 'filterOptions'
  | 'organization'
);
export type JobReferenceFormModel = Pick<JobReference, Exclude<keyof JobReference, ExcludedFields>>;
export type CreateJobReferenceCallback = (response$: Observable<JobReference>) => void;
export type JobReferenceDialogData = {
  data: {
    model: JobReferenceFormModel
    mode: string
  }
  width: string
};

@Component({
  selector: 'job-reference-create-modal',
  templateUrl: './job-reference-create-modal.component.html',
  styleUrls: ['./job-reference-create-modal.component.scss'],
  providers: [JobReferenceSerializer],
})
export class JobReferenceCreateModalComponent implements OnInit {
  referenceForm = this.formBuilder.group({
    value: [''],
    destination: [''],
    haulRate: [''],
    invoiceRate: [''],
    jobCode: [''],
    jobName: [''],
    material: [''],
    origin: [''],
    orderNumber: [''],
  });

  mode = 'create';

  public callback!: CreateJobReferenceCallback;

  constructor(
    private formBuilder: FormBuilder,
    private jobReferenceService: JobReferenceService,
    public dialogRef: MatDialogRef<JobReferenceCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JobReferenceFormModel,
  ) { }

  ngOnInit() {
    if (this.data && this.data.model) {
      this.setFormFieldData(this.data.model);
    }

    this.mode = this.data && this.data.mode;
  }

  onSubmit() {
    let value: JobReferenceFormModel = this.referenceForm.value;

    if (this.data && this.data.model) {
      value = { ...this.data.model, ...this.referenceForm.value };
    }

    this.callback(this.jobReferenceService.save(value));

    this.dialogRef.close();
  }

  setFormFieldData(data: JobReferenceFormModel): void {
    this.referenceForm.controls['value'].setValue(data.value);
    this.referenceForm.controls['destination'].setValue(data.destination);
    this.referenceForm.controls['haulRate'].setValue(data.haulRate);
    this.referenceForm.controls['invoiceRate'].setValue(data.invoiceRate);
    this.referenceForm.controls['jobCode'].setValue(data.jobCode);
    this.referenceForm.controls['jobName'].setValue(data.jobName);
    this.referenceForm.controls['material'].setValue(data.material);
    this.referenceForm.controls['origin'].setValue(data.origin);
    this.referenceForm.controls['orderNumber'].setValue(data.orderNumber);
  }
}
