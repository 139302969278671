import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { zip, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import * as moment from 'moment';

import { AgGridAngular } from 'ag-grid-angular';
import { ValueSetterParams, GridReadyEvent, ColumnApi, GridApi, CellContextMenuEvent, Column, RowNode } from 'ag-grid-community';

import { SelectAllHeaderComponent } from '../../shared/grid/select-all-header.component';
import { AutocompleteCellComponent } from '../../shared/grid/autocomplete-cell.component';
import { CheckboxCellComponent } from '../../shared/grid/checkbox-cell.component';
import { TicketGridDateCellComponent } from '../../tickets/ticket-date-cell.component';
import { BulkActionDialogComponent } from '../../shared/grid/bulk-action-dialog.component';

import { AuthenticationService } from '../../shared/authentication.service';
import { DriverSettlement } from '../settlement/settlement';
import { ReportProfile } from '../report/report';
import { SettlementService } from '../settlement/settlement.service';
import { NoResultsComponent, ActionMenuOption } from '../../../app/shared';

/* eslint-disable @typescript-eslint/naming-convention */
enum ActionType {
  ResetFilters = 'Reset Filters'
}

@Component({
  selector: 'settlement-grid',
  templateUrl: './settlement-grid.component.html',
  styleUrls: ['../../../style/grid.scss', './settlement-grid.component.scss'],
  providers: [SettlementService],
})
export class SettlementGridComponent {
  @ViewChild('settlementGrid', { static: true }) settlementGrid!: AgGridAngular;
  user = this.authenticationService.user();
  loading = true;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();

  columnApi!: ColumnApi;
  gridApi!: GridApi;

  frameworkComponents = {
    autocompleteCell: AutocompleteCellComponent,
    selectAllHeader: SelectAllHeaderComponent,
    customNoRowsOverlay: NoResultsComponent
  };

  noRowsOverlayComponent = 'customNoRowsOverlay';

  noRowsOverlayComponentParams = {
    type: 'settlements',
  };

  columnDefs = [
    {
      headerName: 'Select All',
      field: 'select',
      headerComponent: 'selectAllHeader',
      pinned: 'left',
      width: 70,
      editable: false,
      checkboxSelection: true,
      headerComponentParams: {
        checkboxSelection: true,
        service: this.reportService,
        selected: this.reportService.allSelected
      }
    },
    {
      headerName: 'Created Date',
      field: 'reportDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: false,
      cellRendererFramework: TicketGridDateCellComponent,
      width: 140,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: false,
      cellRendererFramework: TicketGridDateCellComponent,
      width: 140,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Settlement #',
      field: 'reportNumber',
      filter: false,
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Driver',
      field: 'customer.name',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.reportService.getValuesForFieldQuery('customerName').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: false,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      cellRenderer: (params: any): string => {
        const value: ReportProfile = params.value;

        if (!value) {
          return '';
        }

        const firstName = value.firstName ? value.firstName : '';
        const lastName = value.lastName ? value.lastName : '';

        return `${firstName} ${lastName}`;
      },
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Last Edited',
      field: 'lastModified',
      cellRenderer: (params: any): any => {
        const lastModified: string = params.value;
        const lastModifiedBy: ReportProfile = params.data && params.data.lastModifiedBy;

        if (!lastModified || !lastModifiedBy) {
          return '';
        }

        const firstName = lastModifiedBy.firstName ? lastModifiedBy.firstName : '';
        const lastName = lastModifiedBy.lastName ? lastModifiedBy.lastName : '';

        return moment(lastModified).format('MM/DD/YY h:mma') + ` ${firstName} ${lastName}`;
      },
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Total',
      field: 'total',
      editable: false,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'Sent',
      field: 'sent',
      cellRendererFramework: CheckboxCellComponent,
      cellRendererParams: {
        service: this.reportService,
        field: 'sent',
        callback: (updates: object, settlementId: string) => this.onCheckboxCellValueChange(updates, settlementId)
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        cellRenderer: (params: any) => {
          if (params.value === 'True') {
            return 'Sent';
          }

          return 'Not Sent';
        },
        values: ['True', 'False'],
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: false,
      sortable: true,
      resizable: true,
    },
    {
      headerName: 'PDF',
      field: 'pdfUrl',
      cellRenderer: linkRenderer,
      editable: false,
      sortable: true,
      resizable: true,
    }
  ];

  rowModelType = 'serverSide';
  serverSideStoreType = 'partial';
  cacheBlockSize = 100;
  maxBlocksInCache = 10;

  settlementFiltersString = localStorage.getItem('settlementFilters') as string;
  settlementFilters = this.settlementFiltersString && this.settlementFiltersString.length && this.settlementFiltersString[0] === '{' ?
                  JSON.parse(this.settlementFiltersString) : {};

  actionOptions: ActionMenuOption[] = [];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private reportService: SettlementService,
    private bulkDeleteDialog: MatDialog
  ) {
    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.loading = true;
      this.search = search;
      this.reportService.search = this.search;
      this.refreshTable();
    });
  }

  getContextMenuItems(params: any) {
    return [
      {
        name: 'Edit',
        action: () => this.onEditSettlement(params)
      },
      {
        name: 'Delete',
        action: () => this.onDeleteSettlement(params)
      },
      'separator',
      {
        name: 'CSV Export',
        action: () => this.onExport('csv')
      },
      {
        name: 'Excel Export',
        action: () => this.onExport('excel')
      },
    ];
  }

  onEditSettlement(params: CellContextMenuEvent) {
    if (!params.api) {
      return;
    }
    let selected = params.api.getSelectedRows()[0];
    if (!selected) {
      selected = params.node.data;
    }
    this.router.navigate(['settlements', selected.id, 'edit']);
  }

  onDeleteSettlement(params: CellContextMenuEvent) {
    if (!params.api) {
      return;
    }
    let selected = params.api.getSelectedRows();
    if (!selected || !selected.length) {
      selected = [params.node.data];
      params.node.setSelected(true);
    }
    const dialog = this.bulkDeleteDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected,
        type: 'Expense',
      },
    });

    dialog.componentInstance.callback = () => {
      const jobs = zip(
        ...selected.map((j: DriverSettlement) => this.deleteSettlement(j))
      );

      jobs.subscribe(() => {
        this.refreshTable(true);
      });
    };
  }

  onExport(type: string) {
    const exportFlags = {
      onlySelected: !this.reportService.allSelected,
      processCellCallback: (cell: any) => {
        // if a name object is detected, lets format for the export
        if (cell.value && cell.value.firstName) {
          return cell.value.firstName + ' ' + cell.value.lastName;
        } else {
          return cell.value;
        }
      }
    };
    if (type === 'csv') {
      this.gridApi.exportDataAsCsv(exportFlags);
    } else if (type === 'excel') {
      this.gridApi.exportDataAsExcel(exportFlags);
    }
  }

  deleteSettlement(settlement: DriverSettlement): Observable<any> {
    return this.reportService.remove(settlement);
  }

  refreshTable(clear = false): void {
    if (clear) {
      this.gridApi.deselectAll();
      this.gridApi.clearRangeSelection();
    }
    this.gridApi.refreshCells();
    this.gridApi.purgeServerSideCache();
  }

  onCellValueChanged(e: ValueSetterParams): void {
    const settlement: DriverSettlement = { ...e.data, type: 'expense' };

    delete settlement.selected;

    this.reportService.save(settlement).subscribe();
  }

  onGridReady(e: GridReadyEvent): void {
    if (!e.columnApi || !e.api) {
      return;
    }

    this.columnApi = e.columnApi;
    this.gridApi = e.api;
    this.settlementGrid.gridOptions.getContextMenuItems = (params) => this.getContextMenuItems(params);
    this.settlementGrid.gridOptions.getRowNodeId = (data: any) => data.id;
    this.gridApi.setServerSideDatasource(this.reportService);
    this.setActionsMenu();
  }

  setActionsMenu() {
    this.actionOptions = this.actionOptions.concat([
      {
        name: ActionType.ResetFilters,
        onClick: () => this.gridApi.setFilterModel(null),
        disabled: () => false
      }
    ]);
  }

  onFilterChanges() {
    this.settlementFilters = this.gridApi.getFilterModel();
    const filterKeys = Object.keys(this.settlementFilters);
    filterKeys.forEach(key => {
      const obj = this.settlementFilters[key];
      if(!obj.values || !obj.values.length) {
        delete this.settlementFilters[key];
      }
    });
    localStorage.setItem('settlementFilters', JSON.stringify(this.settlementFilters));
  }

  onFirstDataRendered(): void {
    this.autoSizeAll();
  }

  changeSearch(term?: string) {
    this.searchChanged.next(term);
  }

  autoSizeAll(): void {
    const allColumnIds: string[] = [];

    (this.columnApi.getAllColumns() as Column[]).forEach(column => {
      allColumnIds.push(column.getColId());
    });

    this.columnApi.autoSizeColumns(allColumnIds);
  }

  onAddSettlementClick(): void {
    this.reportService.save({ type: 'expense' }).subscribe(() => this.refreshTable());
  }

  onCheckboxCellValueChange(updates: any, settlementId: string) {
    const settlement = this.gridApi.getRowNode(settlementId) as RowNode;
    Object.keys(updates).forEach((element: any) => {
      settlement.data[element] = updates[element];
    });
    settlement.updateData(settlement.data);
  }
}

const linkRenderer = (params: any) => params.value ? '<a href="' + params.value + '" target="_blank" translate>View</a>' : '';
