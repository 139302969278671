import { Component, ViewChild } from '@angular/core';
import {
  IToolPanel,
  IToolPanelParams,
  RowClickedEvent,
  RowNode,
} from 'ag-grid-community';
import { AttachmentService } from '../../attachments/attachment.service';
import { Attachment } from '../../attachments/attachment';

import { Ticket } from '../../tickets/ticket';

// constants
import {
  TRIPSIGNATUREIMAGETYPE,
  TRIPTICKETIMAGETYPE,
} from '../../app.constants';

interface ImageToolPanelParams extends IToolPanelParams {
  readOnly?: boolean;
}

@Component({
  selector: 'grid-image-panel',
  templateUrl: './grid-image-panel.component.html',
  styleUrls: ['./grid-image-panel.component.scss'],
})
export class GridImageToolPanelComponent implements IToolPanel {
  @ViewChild('ticketImage', { static: false }) ticketImage: any;

  editing = false;
  ticket!: Ticket;
  attachments!: Attachment[];
  imageUrl = '';
  imageVisible = false;
  readOnly = false;
  TICKETIMAGETYPE = TRIPTICKETIMAGETYPE;
  SIGNATUREIMAGETYPE = TRIPSIGNATUREIMAGETYPE;
  private params!: ImageToolPanelParams;

  constructor(private attachmentService: AttachmentService) {}

  refresh(): void {}

  agInit(params: ImageToolPanelParams): void {
    this.params = params;
    this.readOnly = !!params.readOnly;
    this.params.api.addEventListener('rowClicked', this.onRowClick.bind(this));
    this.params.api.addEventListener(
      'toolPanelVisibleChanged',
      this.onResize.bind(this)
    );
    this.params.api.addEventListener('dragEvent', this.onResize.bind(this));
  }

  onRowClick(e: RowClickedEvent) {
    this.ticket = e.data;
    this.imageUrl = e.data.image;
    this.attachments = [...this.ticket.attachments];

    this.editing = false;
  }

  onResize(e: DragEvent) {
    const itemType = e.type;
  }

  onSave(attachment: Attachment) {
    if (attachment.id) {
      const attachments = this.attachments.map((a) =>
        a.id === attachment.id ? attachment : a
      );
      this.attachments = [...attachments];
      this.ticket.attachments = [...attachments];
      this.editing = false;
      this.updateRow(this.ticket.id, (attachments[0]?.file as string) || '');
    } else {
      this.attachments = [attachment];
      this.ticket.attachments = [attachment];
      this.editing = false;
      this.updateRow(this.ticket.id, (attachment?.file as string) || '');
    }
  }

  onDelete(id: string) {
    this.attachments = this.attachments.filter((a) => a.id !== id);
    this.ticket.attachments = this.attachments.filter((a) => a.id !== id);
    this.editing = false;
    this.updateRow(this.ticket.id, (this.attachments[0]?.file as string) || '');
  }

  onFileAdd(e: any) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.saveImageData(file.name, reader.result);
    };
  }

  saveImageData(imageName: string, blob: any) {
    const file = this.getFile(imageName, blob);
    this.attachmentService.save(file).subscribe((attachment: Attachment) => {
      const attachments = [...this.attachments, attachment];
      this.attachments = [...attachments];
      this.ticket.attachments = [...attachments];
      this.updateRow(this.ticket.id, attachment.file as string);
    });
  }

  getFile(name: string, file: any) {
    const fileExtension = name.split('.').pop();
    return {
      name: `${this.attachmentService.generateUUID()}.${fileExtension}`,
      fileFormat: 'image',
      fileExtension,
      fileType: TRIPTICKETIMAGETYPE,
      file,
      tickets: [this.ticket.id],
    };
  }

  updateRow(id: string, imageUrl: string) {
    const row = this.params.api.getRowNode(id) as RowNode;
    this.params.api.flashCells({
      rowNodes: [row],
      columns: ['ticketImage'],
    });
    row.setData({ ...row.data, ...{ image: imageUrl } });
  }
}
