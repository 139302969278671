
<div class="integrations-container">
  <div class="integrations-data">
    <h2 translate>Quickbooks Desktop</h2>
    <form #enableQuickbooks="ngForm" class="quickbooks-form">
        <div class="form-block">
          <div class="left-side">
            <h2 translate>Enable Quickbooks Desktop Integration</h2>
            <p translate>To begin the Quickbooks Desktop integration process you must have the toggle enabled. The toggle must always be enabled if you want to continue to sync Ticket Manager with Quickbooks Desktop. </p>
          </div>
          <div class="right-side">
            <div class="switch-container">
              <label class="switch">
                <input type="checkbox" id="quickbooks-enabled" name="quickbooks-enabled" (change)="toggleQuickbooks()" [(ngModel)]="hasQuickbooksEnabled">
                <span class="slider"></span>
              </label>
            </div>
          </div>
        </div>
      </form>
    <form #getQuickbooksFile="ngForm" class="quickbooks-form">
      <div class="form-block">
        <div class="left-side">
          <h2 translate>Integration Details</h2>
          <p translate>Click on the setup button to download the two files needed to integrete with Quickbooks Desktop. 
                       Once Quickbooks Desktop is integrated with Ticket Manager the customer references that you 
                       created in Ticket Manager will be replaced by your Quickbooks customers. You will then have to 
                       create and manage all your customers inside of Quickbooks Desktop. With the Quickbooks Desktop 
                       integration you will be able to send the invoices you created in Ticket Manager to Quickbooks
                       Desktop.</p>
        </div>
        <div class="right-side">
          <button class="btn btn-primary" [disabled]="loading || !hasQuickbooksEnabled" (click)="setupQuickbooks()" translate>
            Setup
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
