import { Component, Input } from '@angular/core';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html'
})

export class NotificationComponent {
  @Input() level = 'danger';
  open = true;
  transition = '';

  constructor() {
  }

  close() {
    this.transition = 'fade';
    setTimeout(() => {
      this.open = false;
    }, 1000);
    setTimeout(() => {
      this.transition = 'fade slide';
    }, 500);
  }

  reset() {
    this.transition = '';
    this.open = true;
  }
}
