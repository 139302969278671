import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { each, extend } from 'lodash';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {
  title = 'Ruckit';
  public token!: string;
  baseUrl = environment.serverUrl;

  constructor() {
    // set token if saved in local storage
    const userValue = localStorage.getItem('currentUser');
    if (userValue) {
      const currentUser = JSON.parse(userValue);
      this.token = currentUser && currentUser.token;
    }
  }

  getStates() {
    return [
      { name: 'Alabama', abbreviation: 'AL' },
      { name: 'Alaska', abbreviation: 'AK' },
      { name: 'American Samoa', abbreviation: 'AS' },
      { name: 'Arizona', abbreviation: 'AZ' },
      { name: 'Arkansas', abbreviation: 'AR' },
      { name: 'California', abbreviation: 'CA' },
      { name: 'Colorado', abbreviation: 'CO' },
      { name: 'Connecticut', abbreviation: 'CT' },
      { name: 'Delaware', abbreviation: 'DE' },
      { name: 'District Of Columbia', abbreviation: 'DC' },
      { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
      { name: 'Florida', abbreviation: 'FL' },
      { name: 'Georgia', abbreviation: 'GA' },
      { name: 'Guam', abbreviation: 'GU' },
      { name: 'Hawaii', abbreviation: 'HI' },
      { name: 'Idaho', abbreviation: 'ID' },
      { name: 'Illinois', abbreviation: 'IL' },
      { name: 'Indiana', abbreviation: 'IN' },
      { name: 'Iowa', abbreviation: 'IA' },
      { name: 'Kansas', abbreviation: 'KS' },
      { name: 'Kentucky', abbreviation: 'KY' },
      { name: 'Louisiana', abbreviation: 'LA' },
      { name: 'Maine', abbreviation: 'ME' },
      { name: 'Marshall Islands', abbreviation: 'MH' },
      { name: 'Maryland', abbreviation: 'MD' },
      { name: 'Massachusetts', abbreviation: 'MA' },
      { name: 'Michigan', abbreviation: 'MI' },
      { name: 'Minnesota', abbreviation: 'MN' },
      { name: 'Mississippi', abbreviation: 'MS' },
      { name: 'Missouri', abbreviation: 'MO' },
      { name: 'Montana', abbreviation: 'MT' },
      { name: 'Nebraska', abbreviation: 'NE' },
      { name: 'Nevada', abbreviation: 'NV' },
      { name: 'New Hampshire', abbreviation: 'NH' },
      { name: 'New Jersey', abbreviation: 'NJ' },
      { name: 'New Mexico', abbreviation: 'NM' },
      { name: 'New York', abbreviation: 'NY' },
      { name: 'North Carolina', abbreviation: 'NC' },
      { name: 'North Dakota', abbreviation: 'ND' },
      { name: 'Northern Mariana Islands', abbreviation: 'MP' },
      { name: 'Ohio', abbreviation: 'OH' },
      { name: 'Oklahoma', abbreviation: 'OK' },
      { name: 'Oregon', abbreviation: 'OR' },
      { name: 'Palau', abbreviation: 'PW' },
      { name: 'Pennsylvania', abbreviation: 'PA' },
      { name: 'Puerto Rico', abbreviation: 'PR' },
      { name: 'Rhode Island', abbreviation: 'RI' },
      { name: 'South Carolina', abbreviation: 'SC' },
      { name: 'South Dakota', abbreviation: 'SD' },
      { name: 'Tennessee', abbreviation: 'TN' },
      { name: 'Texas', abbreviation: 'TX' },
      { name: 'Utah', abbreviation: 'UT' },
      { name: 'Vermont', abbreviation: 'VT' },
      { name: 'Virgin Islands', abbreviation: 'VI' },
      { name: 'Virginia', abbreviation: 'VA' },
      { name: 'Washington', abbreviation: 'WA' },
      { name: 'West Virginia', abbreviation: 'WV' },
      { name: 'Wisconsin', abbreviation: 'WI' },
      { name: 'Wyoming', abbreviation: 'WY' }
    ];
  }
}

export const parseErrors = (err: any) => {
  let errors: any[] = [];
  if (err.status >= 500) {
    errors.push(err.statusText);
  } else if (typeof err._body === 'string') {
    try {
      const body = JSON.parse(err._body);
      if (body.detail) {
        errors.push(body.detail);
      } else {
        errors = rescurseErrorObject(body, errors);
      }
    } catch (e) {}
  } else {
    errors.push(err);
  }
  return errors;
};

export const rescurseErrorObject = (obj: any, errors: any) => {
  each(obj, (msg, key) => {
    if (Array.isArray(msg)) {
      errors = errors.concat(msg.map(err => (key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + err ));
    } else if (typeof msg === 'string') {
      errors.push((key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + msg);
    } else if (typeof msg === 'object') {
      errors = rescurseErrorObject(msg, errors);
    }
  });
  return errors;
};

export const requestHeaders = (xhr?: XMLHttpRequest) => {
  let headers = new HttpHeaders();
  const headersObj: { [key: string]: any } = {};
  if (! xhr ) {
    /* eslint-disable @typescript-eslint/naming-convention */
    extend(headersObj, {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    });
  }

  const userValue = localStorage.getItem('currentUser');

  if (!userValue) {
    return;
  }

  const currentUser = JSON.parse(userValue);
  const token = currentUser && currentUser.token;
  const tokenString = 'Token ' + token;
  headersObj['Authorization'] = tokenString;

  each(headersObj, (value: string, header: string) => {
    if (xhr) {
      xhr.setRequestHeader(header, value);
    } else {
      headers = headers.append(header, value);
    }
  });
  return headers;
};
export const externalRequestHeaders = (token: string) => {
  let headers = new HttpHeaders();
  headers = headers.append('Accept', 'application/json');
  headers = headers.append('Content-Type', 'application/json');
  headers = headers.append('Authorization', 'Token ' + token);
  return headers;
};

export const handleError = (error: any) => observableThrowError(parseErrors(error));
