import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterArray', pure: true })
export class FilterArrayPipe implements PipeTransform {
  transform(items: any[], key: string, value: string): any[] {
    return items && items.length && key && value
      ? items.filter((item) => item[key] === value)
      : items;
  }
}
