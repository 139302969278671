import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CustomerReferenceService } from '../customer-reference.service';
import { CustomerReference } from '../customer-reference';
import {
  CustomerReferenceFormModel,
  CustomerReferenceDialogData,
  CustomerReferenceCreateModalComponent
} from '../customer-reference-create-modal/customer-reference-create-modal.component';

@Component({
  selector: 'customer-reference-selector',
  templateUrl: './customer-reference-selector.component.html',
  styleUrls: ['./customer-reference-selector.component.scss'],
  providers: [CustomerReferenceService],
})
export class CustomerReferenceSelectorComponent implements OnInit {
  @Input() visible = true;
  @Input() placeholder = 'Customer Name or Customer Code';
  @Input() value = '';
  @Input() data!: CustomerReferenceFormModel;
  @Input() customerReference?: CustomerReference;
  @Input() hasControls = true;

  @Output() referenceFocused: EventEmitter<boolean> = new EventEmitter();
  @Output() referenceSelected: EventEmitter<CustomerReference> = new EventEmitter();
  @Output() referenceCreated: EventEmitter<CustomerReference> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur: EventEmitter<boolean> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('customerReferenceInput', { static: true, read: ViewContainerRef }) jobReferenceInput: any;

  selectedCustomerReference?: CustomerReference;
  loading = true;
  scrollDistance = 5;
  scrollUpDistance = 5;
  throttle = 100;
  query = '';
  editing = false;

  references: CustomerReference[] = [];

  constructor(
    private customerReferenceCreateModal: MatDialog,
    private customerReferenceService: CustomerReferenceService,
  ) { }

  ngOnInit() {
    this.query = this.value;

    if (this.customerReference) {
      this.selectedCustomerReference = this.customerReference;
    }

    this.customerReferenceService.list(this.buildQueryString(this.query)).subscribe(references => {
      this.references = references;
      this.loading = false;
    });
  }

  public setSelectedReference(reference: CustomerReference): void {
    this.selectedCustomerReference = reference;
    this.value = reference.customerName ? reference.customerName : '';
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  buildQueryString(query = ''): { filters?: string; page_size?: number } {
    if (!query || query === '') {
      return {
        page_size: 10,
      };
    }

    return {
      filters: `(customer_name__icontains=${query}) | (customer_code__icontains=${query})`,
      page_size: 10,
    };
  }

  onInputFocus(): void {
    this.visible = true;
    this.loading = true;

    this.references = [];

    this.referenceFocused.emit(true);

    this.customerReferenceService.list(this.buildQueryString(this.query)).subscribe(references => {
      this.references = references;
      this.loading = false;
    });
  }

  onInputBlur(): void {
    this.visible = false;
    this.references = [];
    this.onBlur.emit(true);

    if (this.selectedCustomerReference) {
      if (this.selectedCustomerReference.customerName && this.selectedCustomerReference.customerName !== this.value) {
        this.value = this.selectedCustomerReference.customerName;
      }
    } else {
      this.value = '';
    }
  }

  onReferenceClicked(event: any, customerReference: CustomerReference): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.referenceSelected.emit(customerReference);
    this.visible = false;

    this.selectedCustomerReference = customerReference;

    this.value = customerReference.customerName ? customerReference.customerName : '';
  }

  onSearchChange(query: string): void {
    query = query.toUpperCase();

    this.loading = true;
    this.references = [];

    this.customerReferenceService.list(this.buildQueryString(query)).subscribe(results => {
      this.references = results;
      this.loading = false;
    });
  }

  onCloseClick(): void {
    this.visible = false;
    this.onClose.emit(true);
    this.references = [];
  }

  onAddClick(): void {
    const data: CustomerReferenceDialogData = {
      width: '500px',
      data: {
        model: this.data,
        mode: 'create',
      }
    };

    const dialog = this.customerReferenceCreateModal.open(CustomerReferenceCreateModalComponent, data);

    dialog.componentInstance.callback = (response$: Observable<CustomerReference>) => {
      response$.subscribe((customerReference) => {
        this.referenceCreated.emit(customerReference);
        this.selectedCustomerReference = customerReference;

        this.visible = false;
      });
    };
  }

  onScrollDown(): void {
    const newResults = this.customerReferenceService.listNext();
    if (newResults) {
      newResults.subscribe(results => {
        this.references = [...this.references, ...results];
      });
    }
  }

  onScrollUp(): void { }

  onEditReferenceClick() {
    if (!this.selectedCustomerReference) {
      return;
    }

    this.visible = false;
    this.references = [];

    const data: CustomerReferenceDialogData = {
      width: '500px',
      data: {
        model: this.selectedCustomerReference,
        mode: 'edit',
      },
    };

    const dialog = this.customerReferenceCreateModal.open(CustomerReferenceCreateModalComponent, data);

    dialog.componentInstance.callback = (response$: Observable<CustomerReference>) => {
      response$.subscribe((customerReference) => {
        this.referenceCreated.emit(customerReference);
        this.selectedCustomerReference = customerReference;

        this.references.push(customerReference);

        this.visible = false;
      });
    };

    return false;
  }
}
