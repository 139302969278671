import { Component, OnInit, Input } from '@angular/core';

export interface ActionMenuOption {
  onClick: () => void
  name: string
  icon?: string
  disabled: () => boolean
};

@Component({
  selector: 'action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {
  @Input() options: ActionMenuOption[] = [];
  @Input() icon?: string;
  @Input() title?: string;

  constructor() { }

  ngOnInit() { }

  onOptionClick(option: ActionMenuOption) {
    option.onClick();
  }
}
