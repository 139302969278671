import { clone } from 'lodash';

import { Resource } from '../shared/resource';
import { Organization } from '../organizations/organization';

export class User extends Resource {
  email?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  token?: string;
  organization?: Organization;
  organizationName?: string;
  createdAt?: string;
  driverNotificationEmail?: boolean;
  jobNotificationEmail?: boolean;
  afCarrierDispatchNotificationEmail?: boolean;
  lastModified?: string;
  phoneNumber?: string;
  title?: string;
  status?: string;
  image?: string;
  remittanceInfo?: string;
  favoriteTags?: any[];
  favoriteLocation?: string;
  readOnlyUser?: boolean;
  tags?: any[];
  isDriver?: boolean;
  isCarrier?: boolean;
  isRuckit?: boolean;
  isScaleit?: boolean;
  passwordCurrent?: string;
  password?: string;
  passwordConfirmation?: string;
  features?: any;
  enabledFeatures?: string[];

  url(action: any): string {
    return '/team/' + this.id + '/' + action;
  }

  filterOptions(options: any): any[] {
    options = clone(options);

    return options;
  }
}
