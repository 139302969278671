<div class="app">
  <toolbar
    *ngIf="loggedIn"
    (hamburgerClicked)="onHamburgerClicked()"
    title="{{ 'Ticket Manager' | translate }}"
  ></toolbar>
  <sidebar
    class="app__sidebar"
    [visible]="loggedIn && !router.url.includes('uploader')"
    [drawerVisible]="sidebarOpen && loggedIn"
  >
    <div class="app__sidebar__content">
      <router-outlet></router-outlet>
    </div>
  </sidebar>
</div>
