<div class="container">
  <div class="grid-display">
    <div class="title">Customers</div>
    <div class="customer-reference-grid-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <action-menu title="Actions" [options]="actionOptions"></action-menu>
      <button *ngIf="!hasQuickbooksEnabled"
        class="btn btn-primary add-customer"
        [disabled]="hasQuickbooksEnabled"
        (click)="onAddCustomerClick()">
        Add New Customer Reference
      </button>
    </div>
    <ag-grid-angular
      #customerGrid
      class="ag-theme-material customer-grid"
      [animateRows]="true"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [pagination]="true"
      [getRowHeight]="getRowHeight"
      [rowModelType]="rowModelType"
      [serverSideStoreType]="serverSideStoreType"
      [cacheBlockSize]="cacheBlockSize"
      [maxBlocksInCache]="maxBlocksInCache"
      [frameworkComponents]="frameworkComponents"
      [sideBar]="sideBar"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
      (gridReady)="onGridReady($event)"
      (filterChanged)="onFilterChanges()"
      rowSelection="multiple"
      suppressBrowserResizeObserver="true"
    ></ag-grid-angular>
  </div>
</div>

