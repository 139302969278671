import { Resource } from '../shared/resource';

export class Ocr extends Resource {
  identifier!: string;
  attachment!: string;
  ocrResult: any;
  textAnnotations!: TextAnnotation[];
  ocrResultText!: string[];
  textMatches!: any[];
  properties: any;
  tags!: string[];
  createdAt!: string;
  updatedAt!: string;
  loading = false;
  export = false;
  errors!: any[];
  boundingBoxes!: any[];

  updateBoundingBoxCoordinates(attachmentWidth: number, imageWidth: number) {
    if (this.textAnnotations && this.textAnnotations.length) {
      this.boundingBoxes = this.textAnnotations.map((annotation: any) => {
        try {
          const description = annotation['description'];
          const verticies = annotation['boundingPoly']['vertices'];
          const top = Math.min(...verticies.map((v: any) => v['y']));
          const left = Math.min(...verticies.map((v: any) => v['x']));
          const width = Math.max(...verticies.map((v: any) => v['x'])) - left;
          const height = Math.max(...verticies.map((v: any) => v['y'])) - top;
          const fontSize = height * 0.40;

          // Transfor for scaled images
          const scale = imageWidth / attachmentWidth;

          return {
            description,
            top: (top * scale) - (top * scale * 0.05),
            left: left * scale,
            width: width * scale,
            height: (height * scale) / 2,
            fontSize: (fontSize * scale)
          };
        } catch (err) {
          return;
        }
      });
    }
  }
}

export class OcrResults {
  ticketNumber!: string;
  ticketDate!: string;
  quantity!: string;
}

export class TextAnnotation {
  locale?: string;
  description!: string;
  boundingPoly?: any;
}
