import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Charge } from './charge';
import { ChargeSerializer } from './charge.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class ChargeService extends ResourceService<Charge> {
  public search = '';
  public allSelected = false;

  constructor(http: HttpClient) {
    super(http, 'charges/', new ChargeSerializer());
  }
}
