import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { MatDatepicker } from '@angular/material/datepicker';
import moment = require('moment');

@Component({
  selector: 'datepicker-cell',
  template: `
    <mat-form-field>
      <input matInput [matDatepicker]="picker" [(ngModel)]="value" (dateChange)="onDateChange($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #picker></mat-datepicker>
  `,
  styles: [`
    ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding-bottom: 0;
      padding-top: 0;
    }

    ::ng-deep .mat-form-field-infix {
      border: none;
      padding: 0;
    }

    ::ng-deep .mat-calendar-table td {
      border: none;
    }

    ::ng-deep .mat-calendar-table {
      border: none;
    }

    ::ng-deep .mat-calendar-table-header tr {
      background-color: white;
    }

    ::ng-deep .mat-calendar-table thead tr {
      height: 0px;
    }

    ::ng-deep .mat-datepicker-popup {
      border: 1px solid #eee;
    }

    input {
      border-radius: 0;
      height: initial;
      border: none;

      &:focus {
        border: none;
      }
    }
  `],
})
export class DatepickerCellComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('picker', { static: false, read: MatDatepicker }) picker!: MatDatepicker<Date>;

  value!: string;
  params: any;

  ngAfterViewInit() {
    this.picker.open();
  }

  isPopup(): boolean {
    return false;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
  }

  getValue(): string {
    return this.value;
  }

  onDateChange(e: any): void {
    setTimeout(() => {
      this.value = moment(e.value).format('YYYY-MM-DD');
      this.params.stopEditing();
    }, 100);
  }
}
