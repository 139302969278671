<div class="export-modal">
  <div mat-dialog-title class="modal-title export-modal__title">
    <h1 translate>Export to CSV</h1>
  </div>
  <div mat-dialog-content class="export-modal__content">
    <div class="export-modal__content__export-options">
      <label id="export-options-label">What do you want to export?</label>
      <mat-radio-group
        aria-labelledby="export-options-label"
        class="export-modal__content__export-options--group"
        [(ngModel)]="exportOption">
        <mat-radio-button [value]="exportOptions.All">Export All Tickets</mat-radio-button>
        <mat-radio-button [value]="exportOptions.Selected">Export Selected Tickets</mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-checkbox *ngIf="imagesExportable" [(ngModel)]="includeImages">Include Images in Export</mat-checkbox>
  </div>
  <div class="export-modal__submit">
    <button type="submit" (click)="onSubmit()" class="btn btn-primary">Export</button>
  </div>
</div>
