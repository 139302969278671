import { ReportsModule } from './reports/reports.module';
import {
  Injectable, NgModule, ApplicationRef, ErrorHandler
} from '@angular/core';
import {
  BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import * as enterprise from 'ag-grid-enterprise';
enterprise.LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  'CompanyName=Ruckit, Inc.,LicensedApplication=Ticket Manager,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021994,ExpiryDate=18_January_2023_[v2]_MTY3NDAwMDAwMDAwMA==6a05fe56c864048ee96aae9a0905d6ce'
);

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxCurrencyModule } from 'ngx-currency';

import { environment } from '../environments/environment';

import * as Sentry from '@sentry/browser';

import { SharedModule } from './shared/shared.module';

// Shared Components
import { AppComponent } from './app.component';
import { RuckitButtonComponent } from './shared/buttons/index';
import { ExportDialogComponent } from './shared/export-dialog/export-dialog.component';
import { RuckitForgotPasswordDialogComponent } from './shared/forgot-password-dialog/forgot-password-dialog.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginComponent } from './login/login.component';
import { EditUserComponent } from './users/edit-user.component';
import { StyleguideComponent } from './styleguide/styleguide.component';
import { SampleDialogComponent } from './styleguide/styleguide.component';

// Settings
import { SettingsModule } from './settings/settings.module';
import { AuthGuard } from './guards/auth.guard';

// Services
import { ApiService } from './shared';
import { AuthenticationService } from './shared';
import { UploadService } from './shared/upload.service';
import { TicketFilterService } from './tickets/ticket.filter.service';
import { AttachmentService } from './attachments/attachment.service';

import { routing } from './app.routing';

import { NgXtruncateModule } from 'ngx-truncate';

import {
  CdAutocompleteComponent,
  CdAutocompleteDirective
} from './directives/index';

import { MomentModule } from 'angular2-moment';
import { ClipboardModule } from 'ngx-clipboard';

import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// Gross but so are sentrys ts defs
// cast to any so we can add a beforeSend
if (!['local-dev', 'dev'].includes(environment.errorTrackingEnv)) {
  (Sentry as any).init({
    dsn: 'https://3b14efc7832d4b29952f7758c3c72de0@sentry.io/246434',
    environment: environment.errorTrackingEnv,
    release: environment.applicationVersion
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error: any) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    // override hammerjs default configuration
    pan: { threshold: 5 },
    swipe: {
      velocity: 0.4,
      threshold: 20,
      direction: 31 // /!\ ugly hack to allow swipe in all direction
    }
  } as any;
}

@NgModule({
  imports: [
    BrowserModule,
    MatFormFieldModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatChipsModule,
    MatGridListModule,
    MatTabsModule,
    MatIconModule,
    MatTableModule,
    MatAutocompleteModule,
    MatInputModule,
    MatExpansionModule,
    OverlayModule,
    NgxCurrencyModule,
    NgXtruncateModule,
    MomentModule,
    MyDatePickerModule,
    MatTooltipModule,
    ClipboardModule,
    ReactiveFormsModule,
    ReportsModule,
    SettingsModule,
    routing
  ],
  declarations: [
    AppComponent,
    RuckitButtonComponent,
    ExportDialogComponent,
    PasswordResetComponent,
    RuckitForgotPasswordDialogComponent,
    LoginComponent,
    EditUserComponent,
    StyleguideComponent,
    SampleDialogComponent,
    CdAutocompleteComponent,
    CdAutocompleteDirective,
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    ApiService,
    TicketFilterService,
    AttachmentService,
    UploadService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  // entryComponents: [
  //   CdAutocompleteComponent,
  //   RuckitForgotPasswordDialogComponent,
  //   ExportDialogComponent,
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef) { }
}
