import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'styleguide',
  templateUrl: 'styleguide.component.html',
  styleUrls: ['./styleguide.component.scss']
})

export class StyleguideComponent implements OnInit {
  user: any;
  // dropdown configuration
  dropdownOptions = ['thing one', 'thing two', 'thing three'];
  // dropdowns can also accept arrays of objects, with the propery "name" being displayed,
  // there is an optional 'selected', property, the value returned is the whole object. ie:
  actionDropdownOptions = [
    {name: 'preselected option', selected: true},
    {name: 'thing one', id: 1234124},
    {name: 'thing 2', id: 971234976},
    {name: 'thing 3', id: 123414212},
  ];
  search = '';
  searchableDropdownOptions: any[] = [];
  searchableDropdownConfig = {
    searchable: true,
    loadingOptions: false
  };

  groupedOptions = [
    {name: 'whatevs', group: 'group5'},
    {name: 'option name1', group: 'group1'},
    {name: 'option name2', group: 'group1'},
    {name: 'option name3', group: 'group1'},
    {name: 'option name4', group: 'group1'},
    {name: 'option name5', group: 'another group'},
    {name: 'option name6', group: 'group1'},
    {name: 'option name7', group: 'group2'},
    {name: 'option name8', group: 'group2'},
    {name: 'option name9', group: 'group2'},
    {name: 'option name10', group: 'group2'},
    {name: 'option name11', group: 'group2'},
    {name: 'option name11', group: 'group3'},
    {name: 'option name12', group: 'group3'},
    {name: 'option name13', group: 'group3'},
    {name: 'option name14', group: 'group3'},
    {name: 'option name15', group: 'group4'},
    {name: 'option name16', group: 'group4'},
    {name: 'option name17', group: 'group4'}
  ];
  groupedDropdownConfig = {
    group: true,
    groupBy: 'group' // optional, defaults to 'group'. Accepts string or function.
  };

  searchTimeout: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    const userValue = localStorage.getItem('currentUser');
    this.user = userValue ? JSON.parse(userValue) : null;
    this.addMoreOptions();
  }

  addMoreOptions(term = 'Option') {
    for (let i = 1; i <= 100; i++) {
      this.searchableDropdownOptions.push(term + (this.searchableDropdownOptions.length + 1) );
    }
  }

  setSelectedOption() { }

  timeChange() { }

  dropdownSearch(term: any) {
    this.searchableDropdownOptions = [];
    this.searchableDropdownConfig.loadingOptions = true;
    this.searchableDropdownConfig = cloneDeep(this.searchableDropdownConfig);
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.addMoreOptions(term);
      this.searchableDropdownConfig.loadingOptions = false;
      this.searchableDropdownConfig = cloneDeep(this.searchableDropdownConfig);
    }, 1000);
  }

  dropdownNextPage() {
    this.searchableDropdownConfig.loadingOptions = true;
    this.searchableDropdownConfig = cloneDeep(this.searchableDropdownConfig);
    setTimeout(() => {
      this.addMoreOptions();
      this.searchableDropdownConfig.loadingOptions = false;
      this.searchableDropdownConfig = cloneDeep(this.searchableDropdownConfig);
    }, 1000);
  }

  openDialog(): void {
    console.log('Open Dialog...');
    this.dialog.open(SampleDialogComponent, {
      width: '250px',
      data: { name: 'Ruckit' }
    });
  }
}

@Component({
  selector: 'sample-dialog',
  templateUrl: 'sample-dialog.component.html',
})
export class SampleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SampleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
