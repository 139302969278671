import { User } from './../../users/user';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { find as _find } from 'lodash';

import { AuthenticationService } from '../index';
import { UserService } from '../../users/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: [UserService]
})
export class ToolbarComponent implements OnInit {
  @Output() hamburgerClicked = new EventEmitter<boolean>();

  user?: User;
  loggedIn = false;

  constructor(
    private authenticationService: AuthenticationService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.user = this.authenticationService.user();
    this.loggedIn = this.authenticationService.isLoggedIn();
  }

  onHamburgerClick() {
    if (!this.router.url.includes('uploader') && this.loggedIn) {
      this.hamburgerClicked.emit(true);
    }
  }
}
