import { Component, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ResourceService } from '../resource.service';

@Component({
  selector: 'autocomplete-cell',
  templateUrl: './autocomplete-cell.component.html',
  styleUrls: ['./autocomplete-cell.component.scss'],
})
export class AutocompleteCellComponent implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild('autocompleteInput', { static: true, read: ViewContainerRef }) autocompleteInput: any;

  params: any;
  value!: string;
  autocompleteControl = new FormControl();
  options: string[] = [];
  filteredOptions!: Observable<string | string[]>;


  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;

    this.autocompleteControl.setValue(this.value);

    this.onChanges();
  }

  ngAfterViewInit(): void {
    this.focusOnInputNextTick(this.autocompleteInput);
  }

  onChanges() {
    const service = this.params.myService as ResourceService<any>;
    const field = this.params.column.colId;

    this.autocompleteControl.valueChanges.subscribe((v: string) => {
      this.value = v;
    });

    this.filteredOptions = this.autocompleteControl.valueChanges.pipe(
      startWith(this.value),
      switchMap((f: string) => service.getValuesForFieldQuery(field, f)),
      map((fields: string[]) => {
        const value = this.value ? this.value : '';

        return fields.filter(f => f.toLowerCase().indexOf(value.toLowerCase()) === 0);
      }),
    );
  }

  getValue() {
    return this.value;
  }

  isPopup?(): boolean {
    return false;
  }

  isCancelBeforeStart?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  onKeyDown(e: any) {
    const key = e.which || e.keyCode;

    if (key === 37 || key === 38) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  private focusOnInputNextTick(input: ViewContainerRef) {
    setTimeout(() => {
        input.element.nativeElement.focus();
    }, 0);
  }
}
