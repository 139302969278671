import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { TicketFilter } from './ticket.filter';
import { requestHeaders } from '../shared/api.service';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable()
export class TicketFilterService {
  baseUrl = environment.serverUrl;
  public nextUris: { [key: string]: any } = {};

  constructor(private http: HttpClient) { }

  getFilters(filterType: string, search = ''): Observable<TicketFilter[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '6');
    if (search) {
      params = params.set('search', search);
    }

    const filtersUrl = this.baseUrl + 'scaletickets/' + filterType + '/';

    return this.http.get(filtersUrl, {
      headers: requestHeaders(),
      params
    }).pipe(
      map(res => this.extractData(filterType, res)),
      catchError(this.handleError)
    );
  }

  getNext(filterType: string): Observable<TicketFilter[]> | null {
    if (this.nextUris[filterType]) {
      return this.http.get(this.nextUris[filterType], { headers: requestHeaders() }).pipe(
        map((res) => this.extractData(filterType, res)),
        catchError(this.handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(filterType: string, res: any) {
    const resObj = res;
    this.nextUris[filterType] = resObj.next;
    const body = resObj.results;
    if (body) {
      return body.map((filter: any) => new TicketFilter(filter));
    } else {
      return {};
    }
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body: any = error || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
