<div class="ruckit-truck-reference-selector">
  <div class="ruckit-truck-reference-selector__search-bar">
    <i
      *ngIf="visible"
      class="icon-close ruckit-truck-reference-selector__search-bar__close"
      (click)="onCloseClick()"
    >
    </i>
    <i
      *ngIf="hasControls && !visible"
      class="icon-pencil ruckit-truck-reference-selector__search-bar__edit"
      [ngClass]="{'icon-disabled': !selectedTruckReference}"
      (click)="selectedTruckReference && onEditReferenceClick()"
    >
    </i>
    <i
      *ngIf="hasControls && !visible"
      class="icon-search ruckit-truck-reference-selector__search-bar__search"
      (click)="onInputFocus()"
    >
    </i>
    <input
      #truckReferenceInput
      matInput
      class="search"
      type="text"
      [disabled]="!visible"
      (blur)="onInputBlur()"
      (input)="onSearchChange($event.target.value)"
      (focus)="onInputFocus()"
      [(ngModel)]="value"
    />
  </div>
  <div class="ruckit-truck-reference-selector__results--container">
    <div
      *ngIf="visible"
      class="ruckit-truck-reference-selector__results"
      infiniteScroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      [scrollWindow]="false"
      (scrolled)="onScrollDown()"
      (scrolledUp)="onScrollUp()"
    >
      <div *ngIf="loading" class="ruckit-truck-reference-selector__results__loader">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div *ngIf="!loading && references.length <= 0" class="ruckit-truck-reference-selector__results__missing">
        <div class="ruckit-truck-reference-selector__result">
          No References Found
        </div>
      </div>
      <div *ngFor="let reference of references">
        <div class="ruckit-truck-reference-selector__result" (mousedown)="onReferenceClicked($event, reference)">
          <div class="ruckit-truck-reference-selector__result__title">
            {{ reference.truckCode }} - {{ reference.truckNumber }}
          </div>
          <div class="ruckit-truck-reference-selector__result__content">
            <div class="ruckit-truck-reference-selector__result__content__item">
              <div class="ruckit-truck-reference-selector__result__content__item__header">{{ 'License Plate' | translate }}</div>
              <div class="ruckit-truck-reference-selector__result__content__item__content">{{ reference.licensePlate }}</div>
            </div>
            <div class="ruckit-truck-reference-selector__result__content__item">
              <div class="ruckit-truck-reference-selector__result__content__item__header">{{ 'Truck Type' | translate }}</div>
              <div class="ruckit-truck-reference-selector__result__content__item__content">{{ reference.truckType }}</div>
            </div>
            <div class="ruckit-truck-reference-selector__result__content__item">
              <div class="ruckit-truck-reference-selector__result__content__item__header">{{ 'Truck Number' | translate }}</div>
              <div class="ruckit-truck-reference-selector__result__content__item__content">{{ reference.truckNumber }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="visible" class="ruckit-truck-reference-selector__footer">
      <i class="icon icon-plus"></i>
      <div class="ruckit-truck-reference-selector__footer__content" (mousedown)="onAddClick()">
        {{ 'Add New Truck Reference' | translate }}
      </div>
    </div>
  </div>
</div>
