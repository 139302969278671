import { PhoneNumberPipe } from './../../../shared/pipes/phone-number.pipe';
import { Component, ViewChild } from '@angular/core';
import { CustomerReferenceService } from '../customer-reference.service';
import { QuickbooksCompanyService } from '../../../integrations/quickbooks/quickbooks.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ValueSetterParams, CellContextMenuEvent, ColumnApi, Column, ColDef } from 'ag-grid-community';
import {
  AutocompleteCellComponent,
  DatepickerCellComponent,
  SelectAllHeaderComponent,
  BulkActionDialogComponent,
  AuthenticationService,
  NoResultsComponent,
  ActionMenuOption
} from '../../../../app/shared';
import { CustomerReference } from '../customer-reference';
import { zip, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { CustomerReferenceCreateModalComponent } from '../customer-reference-create-modal/customer-reference-create-modal.component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Preferences } from '../../../shared/preferences/preferences';
import { PreferencesSerializer } from '../../../shared/preferences/preferences.serializer';
import { PreferencesService } from '../../../shared/preferences/preferences.service';
import { Notification, NotificationType, NotificationsService } from '../../../shared/notification/notifications.service';
import { debounce } from 'lodash';
import shortid = require('shortid');
import { User } from '../../../users/user';
import { OrganizationService } from '../../../organizations/organization.service';

/* eslint-disable @typescript-eslint/naming-convention */
enum ActionType {
  ResetFilters = 'Reset Filters'
}

@Component({
  selector: 'customer-reference-grid',
  templateUrl: './customer-reference-grid.component.html',
  styleUrls: ['../../../../style/grid.scss', './customer-reference-grid.component.scss'],
  providers: [
    CustomerReferenceService,
    QuickbooksCompanyService,
    OrganizationService,
    PreferencesSerializer,
    PhoneNumberPipe,
  ],
})
export class CustomerReferenceGridComponent {
  @ViewChild('customerGrid', {static: true}) customerGrid!: AgGridAngular;

  columnApi!: ColumnApi;
  gridApi!: GridApi;

  enabledFeatures: string[] = this.authenticationService.enabledFeatures() || [];
  hasReadOnlyTicketAccess = this.enabledFeatures.includes('hasReadonlyTicketAccess');

  rowModelType = 'serverSide';
  serverSideStoreType = 'partial';
  cacheBlockSize = 100;
  maxBlocksInCache = 10;

  frameworkComponents = {
    autocompleteCell: AutocompleteCellComponent,
    datepikcerCell: DatepickerCellComponent,
    selectAllHeader: SelectAllHeaderComponent,
    customNoRowsOverlay: NoResultsComponent,
  };

  noRowsOverlayComponent = 'customNoRowsOverlay';

  noRowsOverlayComponentParams = {
    type: 'customer references',
    onClick: () => this.onAddCustomerClick(),
    readOnly: this.hasReadOnlyTicketAccess
  };

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel'
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ]
  };

  defaultColDef: ColDef = {
    sortable: true,
    resizable: true
  };

  columnDefs = [
    {
      headerName: 'Select All',
      field: 'select',
      headerComponent: 'selectAllHeader',
      pinned: 'left',
      width: 70,
      editable: false,
      checkboxSelection: true,
      headerComponentParams: {
        checkboxSelection: true,
        service: this.customerReferenceService,
        selected: this.customerReferenceService.allSelected
      }
    },
    {
      headerName: 'Customer Code',
      field: 'customerCode',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerCode').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerName').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Email Address',
      field: 'customerEmail',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerEmail').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Address 1',
      field: 'customerAddress1',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerAddress1').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Address 2',
      field: 'customerAddress2',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerAddress2').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'City',
      field: 'customerCity',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerCity').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'State',
      field: 'customerState',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerState').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Zip Code',
      field: 'customerZipcode',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerZipcode').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
    {
      headerName: 'Phone Number',
      field: 'customerPhoneNumber',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params: any) => {
          this.customerReferenceService.getValuesForFieldQuery('customerCode').subscribe(fields => {
            fields.push('-- Blank --');
            params.success(fields);
          });
        },
        newRowsAction: 'keep',
        buttons: ['reset']
      },
      cellRenderer: (params: any): string => {
        const value: string = params.value;

        if (!value) {
          return '';
        }

        return this.phoneNumberPipe.transform(value);
      },
      editable: !this.hasReadOnlyTicketAccess,
      cellEditor: 'autocompleteCell',
      cellEditorParams: {
        service: this.customerReferenceService,
      },
      onCellValueChanged: (e: ValueSetterParams) => this.onCellValueChanged(e),
    },
  ];

  searchChanged: Subject<string> = new Subject<string>();
  search = '';
  loading = false;
  hasQuickbooksEnabled = true;

  preferences!: Preferences;
  user: User = this.authenticationService.user() as User;

  customerFiltersString = localStorage.getItem('customerFilters') as string;
  customerFilters = this.customerFiltersString && this.customerFiltersString.length && this.customerFiltersString[0] === '{' ?
                  JSON.parse(this.customerFiltersString) : {};
  
  actionOptions: ActionMenuOption[] = [];

  constructor(
    private customerReferenceService: CustomerReferenceService,
    private bulkDeleteDialog: MatDialog,
    private customerReferenceCreateModal: MatDialog,
    private preferencesService: PreferencesService,
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService,
    public organizationService: OrganizationService,
    private phoneNumberPipe: PhoneNumberPipe
  ) {
    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.search = search;
      this.customerReferenceService.search = this.search;
      this.refreshTable();
    });
  }

  getContextMenuItems(params: CellContextMenuEvent) {
    const menuItems: any[] = [];
    if (!this.hasQuickbooksEnabled && !this.hasReadOnlyTicketAccess) {
      menuItems.push({
        name: 'Delete',
        action: () => this.onDeleteCustomerReference(params)
      });
    }
    menuItems.push({
      name: 'Export',
      subMenu: [
        {
          name: 'Export Selected',
          action: () => this.onExportSelected(params),
        },
        {
          name: 'Export All',
          action: () => this.onExportAll(),
        },
      ]
    });
    return menuItems;
  }

  onCellValueChanged(e: ValueSetterParams): void {
    const customer: CustomerReference = { ...e.data };

    delete customer.classes;
    delete customer.loading;
    delete customer.selected;

    this.customerReferenceService.save(customer).subscribe();
  }

  refreshTable(clear = false): void {
    if (clear) {
      this.gridApi.deselectAll();
      this.gridApi.clearRangeSelection();
    }
    this.gridApi.refreshCells();
    this.gridApi.purgeServerSideCache();
  }

  onDeleteCustomerReference(params: CellContextMenuEvent) {
    if (!params.api) {
      return;
    }

    let selected = params.api.getSelectedRows();
    if (!selected || !selected.length) {
      selected = [params.node.data];
      params.node.setSelected(true);
    }

    const dialog = this.bulkDeleteDialog.open(BulkActionDialogComponent, {
      width: '500px',
      data: {
        selected,
        type: 'Customer Reference',
      },
    });

    dialog.componentInstance.callback = () => {
      const customers = zip(
        ...selected.map((customer: CustomerReference) => this.deleteCustomerReference(customer))
      );

      customers.subscribe(() => {
        this.refreshTable(true);
      });
    };
  }

  deleteCustomerReference(customer: CustomerReference): Observable<any> {
    return this.customerReferenceService.remove(customer);
  }

  onGridReady(e: GridReadyEvent): void {
    if (!e.columnApi || !e.api) {
      return;
    }

    this.columnApi = e.columnApi;
    this.gridApi = e.api;

    this.applyPreferences();
    if (this.user.organization) {
      this.organizationService.get(this.user.organization.id).subscribe(org => {
        if (org.hasQuickbooksEnabled) {
          this.disableEditing();
        } else {
          this.hasQuickbooksEnabled = false;
        }
      });
    }

    e.api.setServerSideDatasource(this.customerReferenceService);
    this.customerGrid.gridOptions.getContextMenuItems = (params: any) => this.getContextMenuItems(params);
    this.setActionsMenu();
  }

  setActionsMenu() {
    this.actionOptions = this.actionOptions.concat([
      {
        name: ActionType.ResetFilters,
        onClick: () => this.gridApi.setFilterModel(null),
        disabled: () => false
      }
    ]);
  }

  onFilterChanges() {
    this.customerFilters = this.gridApi.getFilterModel();
    const filterKeys = Object.keys(this.customerFilters);
    filterKeys.forEach(key => {
      const obj = this.customerFilters[key];
      if(!obj.values || !obj.values.length) {
        delete this.customerFilters[key];
      }
    });
    localStorage.setItem('customerFilters', JSON.stringify(this.customerFilters));
  }

  onFirstDataRendered(): void {
    this.autoSizeAll();
  }

  disableEditing() {
    this.hasQuickbooksEnabled = true;
    (this.columnApi.getAllColumns() as Column[]).forEach(column => {
      const newColDef = column.getUserProvidedColDef();
      if (newColDef) {
        newColDef.editable = false;
        column.setColDef(newColDef, column.getUserProvidedColDef());
      }
    });
  }

  autoSizeAll(): void {
    const allColumnIds: string[] = [];

    (this.columnApi.getAllColumns() as Column[]).forEach(column => {
      allColumnIds.push(column.getColId());
    });

    this.columnApi.autoSizeColumns(allColumnIds);
  }

  onAddCustomerClick(): void {
    const dialog = this.customerReferenceCreateModal.open(CustomerReferenceCreateModalComponent, {
      width: '500px',
    });

    dialog.componentInstance.callback = (response$: Observable<CustomerReference>) => {
      response$.subscribe(() => this.refreshTable());
    };
  }

  onExportSelected(params: any) {
    let selected: string[];

    selected = params.api ?
               params.api.getSelectedRows().map((s: any) => s.id) :
               params.map((s: any) => s.id);
    if ((!selected || !selected.length) && params.node && params.node.data) {
      selected = [params.node.data.id];
      params.node.setSelected(true);
    }

    this.customerReferenceService.exportGrid('customers', selected).subscribe(() => {}, error => {
      const notification: Notification = {
        context: {
          error,
        },
        id: shortid.generate(),
        message: 'An error occured exporting your data.',
        originator: 'customers',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
    });
  }

  onExportAll() {
    this.customerReferenceService.exportGrid('customers').subscribe(() => {}, error => {
      const notification: Notification = {
        context: {
          error,
        },
        id: shortid.generate(),
        message: 'An error occured exporting your data.',
        originator: 'customers',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
    });
  }

  changeSearch(term?: string): void {
    this.searchChanged.next(term);
  }

  applyPreferences() {
    // Only update or create a default preference until UI for selection is in place
    this.preferencesService.list().pipe(
      map(prefs => {
        const pref = prefs.find(p => p.name === 'customerreference-default');

        return pref ? pref : new Preferences();
      }),
    ).subscribe(preferences => {
      this.preferences = preferences;

      if (preferences.type === 'customerreference' && preferences.blob) {
        this.columnApi.setColumnState(preferences.blob.columnState);
        this.gridApi.setSortModel(preferences.blob.sortState);
      }

      const eventList = [
        'gridColumnsChanged',
        'columnPinned',
        'columnVisible',
        'columnResized',
        'columnMoved'
      ];

      eventList.forEach(event => {
        this.gridApi.addEventListener(event, debounce(() => this.savePreferences(), 300));
      });
    }, error => {
      const notification: Notification = {
        context: {
          error,
        },
        id: shortid.generate(),
        message: 'An error occured saving your preferences.',
        originator: 'customerreferences',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
    });
  }

  savePreferences() {
    const columnData = this.columnApi.getColumnState();
    const preferencesObj = {
      ...this.preferences,
      profile: this.user.id,
      type: 'customerreference',
      name: 'customerreference-default',
      blob: {
        columnState: columnData,
      }
    };

    this.preferencesService.save(preferencesObj).subscribe(() => {}, error => {
      const notification: Notification = {
        context: {
          error,
        },
        id: shortid.generate(),
        message: 'An error occured saving your preferences.',
        originator: 'customerreferences',
        type: NotificationType.Danger,
      };

      this.notificationsService.addNotification(notification);
    });
  }

  getRowHeight() {
    return 48;
  }
}
