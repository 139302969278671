import { Injectable } from '@angular/core';

import { Attachment } from './attachment';
import { AttachmentSerializer } from './attachment.serializer';
import { ResourceService } from '../shared/resource.service';

import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Injectable()
export class AttachmentService extends ResourceService<Attachment> {
  public nextUri!: string;
  public count: any;
  public search = '';
  public allSelected = false;
  progress$: any;
  progress: any;

  constructor(http: HttpClient) {
    super(http, 'attachments/', new AttachmentSerializer());
  }

  getHeaders() {
    const currentUser = JSON.parse(
      localStorage.getItem('currentUser') as string
    );
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Token ${currentUser.token}`,
    };
  }

  // can't create multiple attachments at once at the moment
  // saveMultipleAttachments(attachments: any) {
  //   const currentUser = JSON.parse(
  //     localStorage.getItem('currentUser') as string
  //   );
  //   return this.http.post<any>(`${this.baseUrl}attachments/`, attachments, {
  //     headers: {
  //       // eslint-disable-next-line @typescript-eslint/naming-convention
  //       Authorization: `Token ${currentUser.token}`,
  //     },
  //   });
  // }

  saveAttachment(attachment: any) {
    return this.http.post<any>(`${this.baseUrl}attachments/`, attachment, {
      headers: this.getHeaders(),
    });
  }

  saveMultipleAttachments(attachments: any[], ticketId: string) {
    return forkJoin(
      attachments.map((attachment: any) =>
        this.http.post<any>(
          `${this.baseUrl}attachments/`,
          { ...attachment, tickets: [ticketId] },
          {
            headers: this.getHeaders(),
          }
        )
      )
    );
  }

  updateAttachmentTicketId(attachmentId: string, ticketId: string) {
    return this.http.patch<any>(
      `${this.baseUrl}attachments/${attachmentId}`,
      { tickets: [ticketId] },
      {
        headers: this.getHeaders(),
      }
    );
  }

  updateMultipleAttachments(attachments: any, ticketId: string) {
    return this.http.patch<any>(
      `${this.baseUrl}attachments/`,
      attachments.map((a: any) => ({ ...a, tickets: [ticketId] })),
      {
        headers: this.getHeaders(),
      }
    );
  }

  getMultipleUpdateObservables(attachments: any, ticketId: string) {
    return forkJoin(
      attachments.map((a: any) => ({ ...a, tickets: [ticketId] }))
    );
  }

  generateUUID(): string {
    let d = new Date().getTime();
    if (
      typeof performance !== 'undefined' &&
      typeof performance.now === 'function'
    ) {
      d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }
}
