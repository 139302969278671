import { clone } from 'lodash';
import { Resource } from '../shared/resource';
import { JobReference } from '../references/job-reference/job-reference';
import { DriverReference } from '../references/driver-reference/driver-reference';
import { CustomerReference } from '../references/customer-reference/customer-reference';
import { TextAnnotation, OcrResults } from '../upload/ocr';
import { Attachment } from '../attachments/attachment';

export class TicketMeta extends Resource {
  createdAt!: string;
  createdBy: any;
  lastModified?: string;
  ticket!: string;
  owner: any;
  service: any;
  serviceData!: TextAnnotation[];
  ticketData!: OcrResults;
}

export class Ticket extends Resource {
  createdAt?: string;
  createdBy?: { firstName: string; lastName: string };
  organization?: string;
  ticketDateRaw?: string;
  ticketDate?: string;
  formattedTicketDate?: string;
  ticketNumber?: string;
  ticketOrigin?: string;
  driver?: DriverReference;
  driverName?: string;
  truck?: any;
  truckNumber?: string;
  truckType?: string;
  customer?: CustomerReference;
  customerName?: string;
  job?: JobReference;
  jobName?: any;
  jobCode?: string;
  image?: string;
  weight?: string;
  material?: string;
  carrierName?: string;
  quantity?: string;
  quantityType?: string;
  verified?: boolean;
  invoiceRate?: string;
  total?: string;
  invoiced?: boolean;
  ticketNotes?: string;
  uploadIndex?: number;
  orderNumber?: string;
  origin?: string;
  destination?: string;
  haulRate?: string;
  billable?: boolean;
  rate?: string;
  locationOwner?: string;
  tripReviewedStatus?: 'approved' | 'rejected' | 'pending';
  tripReviewedBy?: { id: string; firstName: string; lastName: string };
  tripReviewedAt?: string;
  checkinKind?: string;
  platformCheckin__kind?: string;
  ticketMetas!: TicketMeta[];
  attachments!: Attachment[];
  // eslint-disable-next-line @typescript-eslint/member-ordering
  latestDecision?: string;
  latestDecisionStatus?: string;
  latestDecider?: string;
  latestDeciderName?: string;
  exported?: boolean;
  latestExportedAt?: string;
  [key: string]: any;

  url(action: string): string {
    return '/tickets/' + this.id + '/' + action;
  }

  filterOptions(options: any): any[] {
    options = clone(options);

    return options;
  }
}

export class ExternalTicket {
  quantity?: string;
  quantityType?: string;
  ticketNumber?: string;
  image?: string;
  contextId?: string;
  ticketDate?: string;
}
