import { OnInit, Component, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
  @Input() searchTerm: any;
  @Input() searchBarType!: string;
  @Output() searchTermChange: EventEmitter<any> = new EventEmitter<any>();
  searchOpen = false;
  placeholder!: string;

  constructor() {}

  ngOnInit() {
    this.placeholder = (this.searchBarType === 'map') ? '' : 'Search';
  }

  openSearchBar(bool: boolean) {
    this.searchOpen = bool;
    if (bool) {
      this.placeholder = 'Search';
      this.searchInput.nativeElement.focus();
    } else {
      this.placeholder = '';
    }
  }

  changeTerm() {
    this.searchTermChange.emit(this.searchTerm);
  }

  clearTerm() {
    this.searchTerm = '';
    this.searchTermChange.emit(this.searchTerm);
  }
}
