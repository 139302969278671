import { clone } from 'lodash';
import { Resource } from '../shared/resource';

export class LocationOwner extends Resource {
  createdAt?: string;
  lastModified?: string;
  organization?: string;
  ownerOrganization?: string;
  name?: string;

  url(action: string): string {
    return '/location_owners/' + this.id + '/' + action;
  }

  filterOptions(options: any): any[] {
    options = clone(options);

    return options;
  }
}
