import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { JobReference } from '../job-reference';
import { JobReferenceService } from '../job-reference.service';
import { Observable } from 'rxjs';
import {
  JobReferenceCreateModalComponent,
  JobReferenceFormModel,
  JobReferenceDialogData
} from '../job-reference-create-modal/job-reference-create-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'job-reference-selector',
  templateUrl: './job-reference-selector.component.html',
  styleUrls: ['./job-reference-selector.component.scss'],
  providers: [JobReferenceService],
})
export class JobReferenceSelectorComponent implements OnInit {
  @Input() visible = true;
  @Input() placeholder = 'Job Name or Job Code';
  @Input() value = '';
  @Input() data!: JobReferenceFormModel;
  @Input() jobReference?: JobReference;
  @Input() hasControls = true;

  @Output() referenceFocused: EventEmitter<boolean> = new EventEmitter();
  @Output() referenceSelected: EventEmitter<JobReference> = new EventEmitter();
  @Output() referenceCreated: EventEmitter<JobReference> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur: EventEmitter<boolean> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('jobReferenceInput', { static: true, read: ViewContainerRef }) jobReferenceInput: any;

  selectedJobReference?: JobReference;
  loading = true;
  scrollDistance = 5;
  scrollUpDistance = 5;
  throttle = 100;
  query = '';
  editing = false;

  references: JobReference[] = [];

  constructor(
    private jobReferenceCreateModal: MatDialog,
    private jobReferenceService: JobReferenceService,
  ) { }

  ngOnInit() {
    this.query = this.value;

    if (this.jobReference) {
      this.selectedJobReference = this.jobReference;
    }

    this.jobReferenceService.list(this.buildQueryString(this.query)).subscribe(references => {
      this.references = references;
      this.loading = false;
    });
  }

  public setSelectedReference(reference: JobReference): void {
    this.selectedJobReference = reference;
    this.value = reference.jobName ? reference.jobName : '';
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  buildQueryString(query = ''): { filters?: string; page_size?: number } {
    if (!query || query === '') {
      return {
        page_size: 10,
      };
    }

    return {
      filters: `(job_name__icontains=${query}) | (job_code__icontains=${query})`,
      page_size: 10,
    };
  }

  onInputFocus(): void {
    this.visible = true;
    this.loading = true;

    this.references = [];

    this.jobReferenceService.list(this.buildQueryString(this.query)).subscribe(references => {
      this.references = references;
      this.loading = false;
    });

    this.referenceFocused.emit(true);
  }

  onInputBlur(): void {
    this.visible = false;
    this.references = [];
    this.onBlur.emit(true);

    if (this.selectedJobReference) {
      if (this.selectedJobReference.jobName && this.selectedJobReference.jobName !== this.value) {
        this.value = this.selectedJobReference.jobName;
      }
    } else {
      this.value = '';
    }
  }

  onReferenceClicked(event: any, jobReference: JobReference): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.referenceSelected.emit(jobReference);
    this.visible = false;

    this.selectedJobReference = jobReference;

    if (jobReference.jobName) {
      this.value = jobReference.jobName;
    }
  }

  onSearchChange(query: string): void {
    this.query = query;

    this.loading = true;
    this.references = [];

    this.jobReferenceService.list(this.buildQueryString(this.query)).subscribe(results => {
      this.references = results;
      this.loading = false;
    });
  }

  onCloseClick(): void {
    this.visible = false;
    this.onClose.emit(true);
    this.references = [];
  }

  onAddClick(): void {
    const data: JobReferenceDialogData = {
      width: '500px',
      data: {
        model: this.data,
        mode: 'create',
      },
    };

    const dialog = this.jobReferenceCreateModal.open(JobReferenceCreateModalComponent, data);

    dialog.componentInstance.callback = (response$: Observable<JobReference>) => {
      response$.subscribe((jobReference) => {
        this.referenceCreated.emit(jobReference);
        this.selectedJobReference = jobReference;

        this.visible = false;
      });
    };
  }

  onScrollDown(): void {
    const newResults = this.jobReferenceService.listNext();
    if (newResults) {
      newResults.subscribe(results => {
        this.references = [...this.references, ...results];
      });
    }
  }

  onScrollUp(): void { }

  onEditReferenceClick() {
    if (!this.selectedJobReference) {
      return;
    }

    this.visible = false;
    this.references = [];

    const data: JobReferenceDialogData = {
      width: '500px',
      data: {
        model: this.selectedJobReference,
        mode: 'edit',
      },
    };

    const dialog = this.jobReferenceCreateModal.open(JobReferenceCreateModalComponent, data);

    dialog.componentInstance.callback = (response$: Observable<JobReference>) => {
      response$.subscribe((jobReference) => {
        this.referenceCreated.emit(jobReference);
        this.selectedJobReference = jobReference;

        this.references.push(jobReference);

        this.visible = false;
      });
    };

    return false;
  }
}
