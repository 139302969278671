import { Resource } from '../shared/resource';

export class Charge extends Resource {
  ownerOrganization!: string;
  ticket!: string;
  report!: string;
  punchcard!: string;
  trip!: string;
  quantity!: string;
  rate!: string;
  total!: string;
  totalTons?: string;
  description!: string;
  parent!: string | null;
  order!: number;
  attachments!: Charge[];
  blob: any;
}
