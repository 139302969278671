import { Component, OnInit } from '@angular/core';
import { Notification, NotificationsService } from '../shared/notification/notifications.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'batch-upload-notification-bar',
  templateUrl: './batch-upload-notification-bar.component.html',
  styleUrls: ['./batch-upload-notification-bar.component.scss'],
  providers: [NotificationsService],
})
export class BatchUploadNotificationBarComponent implements OnInit {
  notifications$!: Observable<Notification[]>;

  constructor(private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.notifications$ = this.notificationsService.getNotifications().pipe(
      map(notifications => notifications.filter(notification => notification.originator === 'uploader')),
    );
  }

  closeNotification(notification: Notification): void {
    this.notificationsService.removeNotification(notification);
  }
}
