import { FilterValues } from './filter';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class FilterValuesSerializer {
  fromJson(json: any): FilterValues {
    json = camelcaseKeysDeep(json);
    const filterValues = new FilterValues();
    if (!json) {
      return filterValues;
    }

    Object.keys(json).forEach(key => {
      filterValues[key] = json[key];
    });

    return filterValues;
  }
};
