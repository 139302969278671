import { Component, AfterViewInit, ViewContainerRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';
import { TicketService } from '../../../tickets/ticket.service';
import { TruckReference } from '../truck-reference';

@Component({
  selector: 'truck-reference-cell',
  templateUrl: './truck-reference-cell.component.html',
  styleUrls: ['./truck-reference-cell.component.scss'],
  providers: [TicketService],
})
export class TruckReferenceCellComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('truckReferenceInput', { static: false, read: ViewContainerRef }) jobReferenceInput: any;
  params!: ICellEditorParams;
  gridApi!: GridApi;
  reference!: TruckReference;
  value = '';


  constructor(
    private ticketService: TicketService,
  ) { }

  agInit(params: ICellEditorParams) {
    this.params = params;

    if (params && params.api) {
      this.gridApi = params.api;
    }
  }

  ngAfterViewInit(): void { }

  getValue() {
    if (this.reference) {
      return this.reference.truckNumber;
    }

    const cell = this.gridApi.getFocusedCell();
    const row = cell ? this.gridApi.getDisplayedRowAtIndex(cell.rowIndex) : null;

    return row && row.data && row.data.truckNumber ? row.data.truckNumber : '';
  }

  isCancelBeforeStart?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  isPopup() {
    return true;
  }

  onReferenceClicked(reference: TruckReference): void {
    const cell = this.gridApi.getFocusedCell();
    const row = cell ? this.gridApi.getDisplayedRowAtIndex(cell.rowIndex) : null;
    if (row) {
      this.ticketService.updateFieldReference<TruckReference>(row.data.id, reference, 'truck').subscribe(ticket => {
        this.reference = reference;
        row.setData(ticket);
        this.gridApi.stopEditing();
      });
    }
  }

  onReferenceClosed(): void {
    this.gridApi.stopEditing();
  }
}
