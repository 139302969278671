import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { uniq } from 'lodash';

import * as Sentry from '@sentry/browser';

import { environment } from '../../environments/environment';
import { User } from '../users/user';
import { UserSerializer } from '../users/user.serializer';
import { Organization } from '../organizations/organization';
import { DataService } from './data.service';

const camelcaseKeysDeep = require('camelcase-keys-deep');
import { parseErrors } from '../shared/api.service';
import { OrganizationService } from '../organizations/organization.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class AuthenticationService {
  public token: string;
  baseUrl = environment.serverUrl;
  ticketReadOnlyAccessUpdated = new Subject();
  ticketReadOnlyAccessUpdated$ = this.ticketReadOnlyAccessUpdated.asObservable();
  private loggedIn = false;
  private ruckit = false;
  private scaleit = false;
  private posEnabled = false;
  private advancedBilling = false;
  private allDriversEnabled = false;
  private sidebar = true;
  private createJobs = false;
  private leasedOrgs = false;
  private favoriteTags = false;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private orgService: OrganizationService
  ) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.updateOrgDetails();
  }

  login(username: string, password: string): Observable<boolean> {
    const params = { username, password };
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/login/', params, { headers }).pipe(
      map(this.extractData),
      catchError((error) => observableThrowError(parseErrors(error))),
      map(this.storeUser.bind(this), this.updateOrgDetails())
    );
  }

  logout(): void {
    // clear token and remove user from local storage to log out
    this.token = '';
    this.loggedIn = false;
    this.dataService.changeData({ authenticated: false, sidebar: false });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('ticketFilters');

    Sentry.configureScope((scope) => {
      scope.setUser({});
    });
  }

  forgot(username: string): Observable<boolean> {
    const params = { username };
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/forgot/', params, { headers }).pipe(
      map((response: any) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }),
      catchError((error) => observableThrowError(parseErrors(error)))
    );
  }

  reset(token: string, uid: string, password: string): Observable<boolean> {
    const params = { token, uid, password };
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/reset/', params, { headers }).pipe(
      map((response: any) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }),
      catchError((error) => observableThrowError(parseErrors(error)))
    );
  }

  getProfile(token: string, uid: string) {
    let params: HttpParams = new HttpParams();
    params = params.set('token', token);
    params = params.set('uid', uid);

    let requestOptions = { };
    requestOptions = params;

    return this.http.get(this.baseUrl + 'auth/reset/', requestOptions).pipe(
      map(this.extractData),
      catchError((error) => observableThrowError(parseErrors(error)))
    );
  }

  isLoggedIn(): boolean {
    const storedUser = localStorage.getItem('currentUser');
    const currentUser = storedUser && JSON.parse(storedUser);
    if (currentUser && currentUser.token) {
      this.loggedIn = true;
    }

    return this.loggedIn;
  }

  user() {
    const storedUser = localStorage.getItem('currentUser');
    const currentUser = storedUser && JSON.parse(storedUser);
    return currentUser;
  }

  isRuckit(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.ruckit) {
      this.ruckit = true;
    }

    return this.ruckit;
  }

  isScaleit(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.posEnabled) {
      this.scaleit = true;
    }

    return this.scaleit;
  }

  hasPOSEnabled(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.posEnabled) {
      this.posEnabled = true;
    }

    return this.posEnabled;
  }

  hasAdvancedBilling(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.advancedBilling) {
      this.advancedBilling = true;
    }

    return this.advancedBilling;
  }

  hasAllDriversEnabled(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.allDriversEnabled) {
      this.allDriversEnabled = true;
    }

    return this.allDriversEnabled;
  }

  canCreateJobs(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if ((currentUser && currentUser.canCreateJobs) ||
      (currentUser && currentUser.organization && currentUser.organization.can_create_jobs)) {
      this.createJobs = true;
    }
    return this.createJobs;
  }

  hasLeasedOrgs(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasLeasedOrgs) {
      this.leasedOrgs = true;
    }

    return this.leasedOrgs;
  }

  hasFavoriteTags(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.favoriteTags && currentUser.favoriteTags.length > 0) {
      this.favoriteTags = true;
    }
    return this.favoriteTags;
  }

  hasReadOnlyAccess(): boolean {
    return !!this.getFeature('hasReadonlyTicketAccess');
  }

  getOrganization(): Organization {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.organization) {
      return currentUser.organization;
    }
    return null;
  }

  updateOrganization(organization: any): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      currentUser.organization = organization;
      this.storeUser(currentUser);
    }
  }

  myFavoriteTags(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.favoriteTags && currentUser.favoriteTags.map((tag: { name: any }) => tag.name);
  }

  getFilterProperty(filter: string | number): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasOwnProperty('filters')) {
      return currentUser.filters[filter];
    } else {
      return false;
    }
  }

  setFilterProperty(filter: string | number, state: any): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && !currentUser.hasOwnProperty('filters')) {
      currentUser['filters'] = {};
    }
    currentUser['filters'][filter] = state;
    this.storeUser(currentUser);
  }

  displaySidebar(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.sidebar === false) {
      this.sidebar = false;
    } else {
      this.sidebar = true;
    }

    return this.sidebar;
  }

  /**
   * This method continuously fetches organization details.
   * If read only ticket access is modified,
   *  then event will be emitted which will be responsible to update the access across the application.
   */
   updateOrgDetails() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.orgService.fetchOrganization().subscribe((organization: any) => {
      if (organization) {
        const hasTicketAccess = currentUser.organization.features && currentUser.organization.features['hasReadonlyTicketAccess'];
        const newAccessValue = organization.features && organization.features['hasReadonlyTicketAccess'];
        if (hasTicketAccess !== newAccessValue) {
          currentUser.organization = organization;
          localStorage.setItem('currentUser', JSON.stringify({
            ...currentUser,
            token: currentUser.token,
            image: currentUser.image,
          }));
          this.ticketReadOnlyAccessUpdated.next();
        }
      }
    });
  }

  enabledFeatures(): string[] {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return currentUser && currentUser.enabledFeatures ? currentUser.enabledFeatures : [];
  }

  getFeature(feature: string) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    try {
      if (currentUser.features && currentUser.features[feature]) {
        return currentUser.features[feature];
      }
      if (currentUser.organization.features && currentUser.organization.features[feature]) {
        return currentUser.organization.features[feature];
      }
    } catch {
      return null;
    }
  }

  private extractData(res: any) {
    const body = res.results;
    if (body) {
      return body.map((user: any) => {
        user = camelcaseKeysDeep(user);
        return (new UserSerializer()).fromJson(user);
      });
    } else if (res) {
      const user = camelcaseKeysDeep(res);
      return (new UserSerializer()).fromJson(user);
    } else {
      return [];
    }
  }

  private storeUser(user: User) {
    if (user.token) {
      // set token property
      this.token = user.token;
      let enabledFeatures: string[] = [];
      if (user.organization && user.organization.enabledFeatures) {
        enabledFeatures = enabledFeatures.concat(user.organization.enabledFeatures);
      }
      if (user.enabledFeatures) {
        enabledFeatures = enabledFeatures.concat(user.enabledFeatures);
      }
      const userInfo = {
        username: user.email,
        name: user.name,
        organization: user.organization,
        product: 'ticket-manager',
        driver: user.isDriver,
        carrier: user.isCarrier,
        ruckit: user.isRuckit,
        id: user.id,
        canCreateJobs: user.organization && user.organization.canCreateJobs,
        posEnabled: user.organization && user.organization.posEnabled,
        advancedBilling: user.organization && user.organization.advBillingEnabled,
        scaleit: user.organization && user.organization.posEnabled,
        allDriversEnabled: user.organization && user.organization.allDriversEnabled,
        hasLeasedOrgs: user.organization && user.organization.hasLeasedOrgs,
        favoriteTags: user.favoriteTags, filters: user['filters'] || {},
        enabledFeatures: uniq(enabledFeatures)
      };

      // store user data and JWT token in local storage to persist user session
      localStorage.setItem('currentUser', JSON.stringify({
        ...userInfo,
        token: user.token,
        image: user.image,
      }));

      // return true to indicate successful login
      this.loggedIn = true;

      // sentry integration
      Sentry.configureScope((scope) => {
        scope.setUser(userInfo);
      });
      return true;
    } else {
      // return false to indicate failed login
      this.loggedIn = true;
      return false;
    }
  }
}
