import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userValue = localStorage.getItem('currentUser');

    if (userValue) {
      const currentUser = JSON.parse(userValue);
      if (currentUser && (!currentUser.driver || currentUser.organization)) {
        // logged in so return true
        return true;
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

    return false;
  }
}
