import { clone } from 'lodash';
import { Resource } from '../shared/resource';

export class Attachment extends Resource {
  name!: string;
  fileFormat!: string;
  fileExtension!: string;
  fileType!: string;
  file!: File | string;
  tickets?: string[];
  checkin?: string;

  url(action: string): string {
    return '/attachments/' + this.id + '/' + action;
  }

  filterOptions(options: any): any[] {
    options = clone(options);

    return options;
  }
}
