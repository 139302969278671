import { Charge } from './charge';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ChargeSerializer {
  fromJson(json: any): Charge {
    json = camelcaseKeysDeep(json);
    const charge = new Charge();
    if (!json) {
      return charge;
    }

    charge.id = json.id;
    charge.ownerOrganization = json.ownerOrganization;
    charge.ticket = json.ticket;
    charge.report = json.report;
    charge.punchcard = json.punchcard;
    charge.trip = json.trip;
    charge.quantity = json.quantity;
    charge.rate = json.rate;
    charge.total = json.total;
    charge.totalTons = json.totalTons;
    charge.description = json.description;
    charge.parent = json.parent;
    charge.order = json.order;
    charge.attachments = json.attachments;
    charge.blob = json.blob;

    return charge;
  }

  toJson(charge: Charge): any {
    const json: { [key: string]: any } = {
      id: charge.id,
      ownerOrganization: charge.ownerOrganization,
      ticket: charge.ticket,
      report: charge.report,
      punchcard: charge.punchcard,
      trip: charge.trip,
      quantity: charge.quantity,
      rate: charge.rate,
      total: charge.total,
      description: charge.description,
      parent: charge.parent,
      order: charge.order,
      attachments: charge.attachments,
      blob: charge.blob
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
