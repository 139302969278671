import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TruckReference } from '../truck-reference';
import { FormBuilder } from '@angular/forms';
import { TruckReferenceSerializer } from '../truck-reference.serializer';
import { TruckReferenceService } from '../truck-reference.service';
import { Observable } from 'rxjs';

type ExcludedFields = (
  'id'
  | 'createdAt'
  | 'classes'
  | 'loading'
  | 'selected'
  | 'filterOptions'
  | 'organization'
);
export type TruckReferenceFormModel = Pick<TruckReference, Exclude<keyof TruckReference, ExcludedFields>>;
export type CreateTruckReferenceCallback = (response$: Observable<TruckReference>) => void;
export type TruckReferenceDialogData = {
  data: {
    model: TruckReferenceFormModel
    mode: string
  }
  width: string
};

@Component({
  selector: 'truck-reference-create-modal',
  templateUrl: './truck-reference-create-modal.component.html',
  styleUrls: ['./truck-reference-create-modal.component.scss'],
  providers: [TruckReferenceSerializer],
})
export class TruckReferenceCreateModalComponent implements OnInit {
  referenceForm = this.formBuilder.group({
    value: [''],
    licensePlate: [''],
    truckCode: [''],
    truckNumber: [''],
    truckType: [''],
  });

  mode = 'create';

  public callback!: CreateTruckReferenceCallback;

  constructor(
    private formBuilder: FormBuilder,
    private truckReferenceService: TruckReferenceService,
    public dialogRef: MatDialogRef<TruckReferenceCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TruckReferenceFormModel,
  ) { }

  ngOnInit() {
    if (this.data && this.data.model) {
      this.setFormFieldData(this.data.model);
    }

    this.mode = this.data && this.data.mode;
  }

  onSubmit() {
    let value: TruckReferenceFormModel = this.referenceForm.value;

    if (this.data && this.data.model) {
      value = { ...this.data.model, ...this.referenceForm.value };
    }

    this.callback(this.truckReferenceService.save(value));

    this.dialogRef.close();
  }

  setFormFieldData(data: TruckReferenceFormModel): void {
    this.referenceForm.patchValue(data);
  }
}
