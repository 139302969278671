<div class="ag-overlay-loading-center no-results">
  <div class="no-results__icon">
    <i class="icon-no-results"></i>
  </div>
  <div class="no-results__header">
    No {{ type | titlecase }}
  </div>
  <div class="no-results__content">
    <span>When you add {{ type | titlecase }} you'll see them here.</span>
  </div>
  <div *ngIf="onClick && !readOnly" class="no-results__footer">
    <button (click)="onClick()" class="btn btn-primary">Create {{ type | titlecase }}</button>
  </div>
</div>
