<form #forgotPassword="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1>{{ 'Forgot Your Password?' | translate }}</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <p>
      <ng-container translate>Enter your Ruckit username below, and we'll send you instructions on how to update your password.</ng-container>
    </p>

    <div class="field-group full-width">
      <label class="required" translate>Username</label>
      <input type="text" name="username" placeholder="{{ 'Username' | translate }}" required [(ngModel)]="model.username" #username="ngModel" />
      <span [ngClass]="{'input-icon fa': true, 'ng-valid': username.valid, 'ng-invalid': username.invalid, 'ng-pristine': username.pristine}"></span>
    </div>
    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <ruckit-button title="{{button.title}}" fullWidth=true (click)="forgotPassword.form.valid && submit(); false" [type]="button.type" [isDisabled]="!forgotPassword.form.valid || loading || button.disabled"></ruckit-button>
  </div>
</form>
