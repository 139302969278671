<div class="app-content">
  <action-bar>
    <div class="left-actions">
      <h1 class="title">Settings</h1>
      <div class="subnav">
        <div class="links">
          <a [routerLink]="['/settings', 'organization']" routerLinkActive="active" translate>Organization</a>
          <a *ngIf="quickbooksVisible" [routerLink]="['/settings', 'integrations']" routerLinkActive="active" translate>Integrations</a>
        </div>
      </div>
    </div>
  </action-bar>

  <div class="settings">
    <ruckit-settings-organization *ngIf="tab === 'organization' && organization" [organization]=organization></ruckit-settings-organization>
    <ruckit-settings-integrations *ngIf="tab === 'integrations' && quickbooksVisible"></ruckit-settings-integrations>
  </div>
</div>
