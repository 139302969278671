import { Observable, of, timer as observableTimer } from 'rxjs';
import { Injectable } from '@angular/core';
import { clone } from 'lodash';

import { environment } from '../../environments/environment';
import { Organization } from './organization';
import { OrganizationSerializer } from './organization.serializer';
import { requestHeaders } from '../shared/api.service';
import { ResourceService } from '../shared/resource.service';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class OrganizationService extends ResourceService<Organization> {
  baseUrl = environment.serverUrl;
  organizationId!: string;
  token!: string;
  public nextUri: any;
  public count = 0;

  constructor(http: HttpClient) {
    super(http, 'organizations/', new OrganizationSerializer());
  }

  saveWithImage(organization: any, logoImage: File) {
    const _organization = decamelizeKeysDeep(clone(organization));

    return Observable.create((observer: { next: (arg0: any) => void; complete: () => void; error: (arg0: any) => void }) => {
      const formData: FormData = new FormData();
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      delete _organization.image;
      delete _organization.billing_contact;
      delete _organization.email;

      Object.keys(_organization).map((key) => {
        const value = (typeof _organization[key] === 'object') ? JSON.stringify(_organization[key]) : _organization[key];
        formData.append(key, value);
      });

      if (logoImage) {
        formData.append('image', logoImage, logoImage.name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      if (_organization.id) {
        const organizationUrl = this.baseUrl + 'organizations/' + _organization.id + '/';
        xhr.open('PUT', organizationUrl, true);
      } else {
        const organizationUrl = this.baseUrl + 'organizations/';
        xhr.open('POST', organizationUrl, true);
      }
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }

  saveOnlyImage(organizationId: string, logoImage: File) {

    return Observable.create((observer: { next: (arg0: any) => void; complete: () => void; error: (arg0: any) => void }) => {
      const formData: FormData = new FormData();
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      formData.append('image', logoImage, logoImage.name);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      if (organizationId) {
        const organizationUrl = this.baseUrl + 'organizations/' + organizationId + '/';
        xhr.open('PUT', organizationUrl, true);
      }
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }

  patchData(organizationId: string, values: any) {
    const _values = decamelizeKeysDeep(clone(values));

    return Observable.create((observer: { next: (arg0: any) => void; complete: () => void; error: (arg0: any) => void }) => {
      const formData: FormData = new FormData();
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      Object.keys(_values).map((key) => {
        const value = _values[key];
        formData.append(key, value);
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      const organizationUrl = this.baseUrl + 'organizations/' + organizationId + '/';
      xhr.open('PATCH', organizationUrl, true);

      requestHeaders(xhr);
      xhr.send(formData);
    });
  }

  fetchOrganization(timer = 30000): Observable<Organization> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.organization) {
      return observableTimer(0, timer).pipe(
        switchMap(() => this.get(currentUser.organization.id)));
    } else {
      return of();
    }
  }
}

