import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReadOnlyTicketsGaurd implements CanActivate {

  constructor(private router: Router) { }

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userValue = localStorage.getItem('currentUser');
    if (userValue) {
      const currentUser = JSON.parse(userValue);
      if (currentUser && (!currentUser.driver || currentUser.organization)) {
        const hasReadOnlyAccess = currentUser.organization.features['hasReadonlyTicketAccess'];
        if (hasReadOnlyAccess) {
          // if user have read only access then navigates to home page
          this.router.navigate([''], { queryParams: { returnUrl: state.url } });
          return false;
        } else {
          return true;
        }
      }
    }
    // if user info not available, navigates to login page
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
