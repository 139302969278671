import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';


import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'ruckit-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  token!: string;
  uid!: string;
  loading = false;
  errors = [];
  data: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  submit() {
    this.loading = true;
    this.authenticationService.reset(this.token, this.uid, this.data.password)
      .subscribe(
        () => {
          this.loading = false;
          this.router.navigate(['/login']);
        },
        (err) => {
          this.errors = err;
          this.loading = false;
        }
      );
  }

  ngOnInit() {
    this.route.queryParams.forEach((params: Params) => {
      this.token = params['token'];
      this.uid = params['uid'];

      if (this.token === 'mock-token') {
        this.loading = false;
        // Build this out
      } else if (this.token && this.uid) {
        this.authenticationService.getProfile(this.token, this.uid)
          .subscribe(
            (result) => {
              this.loading = false;
              this.data = {
                firstName: result.firstName,
                lastName: result.lastName,
                email: result.email
              };
            },
            (err) => {
              this.errors = err;
              this.loading = false;
            }
          );
      }
    });
  }
}
